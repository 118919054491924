import apiCall from '@/utils/api'

const state = {
	parts: [],
    searchParts: [],
	allParts: [],
    partPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    searchPartPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    partLoader: false,
    searchPartLoader: false,
};

const getters = {
	parts: (state) => state.parts,
	allParts: (state) => state.allParts,
    partLoader: (state) => state.partLoader,
    partPagination: (state) => state.partPagination,

    searchParts: (state) => state.searchParts,
	searchPartLoader: (state) => state.searchPartLoader,	
    searchPartPagination: (state) => state.searchPartPagination,
}

const actions = {
	async fetchAllParts({commit}, page) {
		const response = await apiCall({url: `/api/part?type=all`, method: 'GET' });
		commit('setAllParts', response)
        commit('stopLoader', response)
	},
    async fetchSearchParts({commit}, page) {
		const response = await apiCall({url: `/api/part?page=${page}`, method: 'GET' });
		commit('setSearchParts', response)
        commit('stopSearchPartLoader', response)
	},
    async fetchParts({commit}, page) {
		const response = await apiCall({url: `/api/part?page=${page}`, method: 'GET' });
		commit('setParts', response)
        commit('stopLoader', response)
	},
    async filterParts({commit, state},resp){
		commit('setParts', resp)
	},
    async filterSearchParts({commit, state},resp){
		commit('setSearchParts', resp)
	},
	async startPartLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopPartLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
    async startSearchPartLoader({commit, state},resp){
		commit('startSearchPartLoader', resp)
	},
	async stopSearchPartLoader({commit, state},resp){
		commit('stopSearchPartLoader', resp)
	},
};

const mutations = {
	setParts: (state, parts) => {
		state.parts = parts.data
		state.partPagination.current_page = parts.current_page
		state.partPagination.total = parts.total
		state.partPagination.per_page = parts.per_page
	},
	setAllParts: (state, parts) => {
		state.allParts = parts
	},
    startLoader: (state) => state.partLoader = true,
	stopLoader: (state) => state.partLoader = false,

    setSearchParts: (state, parts) => {
		state.searchParts = parts.data
		state.searchPartPagination.current_page = parts.current_page
		state.searchPartPagination.total = parts.total
		state.searchPartPagination.per_page = parts.per_page
	},
    startSearchPartLoader: (state) => state.searchPartLoader = true,
	stopSearchPartLoader: (state) => state.searchPartLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
