import apiCall from '@/utils/api'

const state = {
	students: [],
	searchStudents: [],
    studentPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	searchStudentPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    studentLoader: false
};

const getters = {
    studentLoader: (state) => state.studentLoader,
	searchStudents: (state) => state.searchStudents,
	students: (state) => state.students,
    studentPagination: (state) => state.studentPagination,
	searchStudentPagination: (state) => state.searchStudentPagination,
}

const actions = {
    async fetchStudents({commit}, page) {
		const response = await apiCall({url: `/api/student?page=${page}`, method: 'GET' });
		commit('setStudents', response)
        commit('stopStudentLoader', response)
	},
    async filterStudents({commit, state},resp){
		commit('setStudents', resp)
	},
	async filterSearchStudents({commit, state},resp){
		commit('setSearchStudents', resp)
	},
	async startStudentLoader({commit, state},resp){
		commit('startStudentLoader', resp)
	},
	async stopStudentLoader({commit, state},resp){
		commit('stopStudentLoader', resp)
	},
};

const mutations = {
	setStudents: (state, students) => {
		state.students = students.data
		state.studentPagination.current_page = students.current_page
		state.studentPagination.total = students.total
		state.studentPagination.per_page = students.per_page
	},
	setSearchStudents: (state, searchStudent) => {
		state.searchStudents = searchStudent.data
		state.searchStudentPagination.current_page = searchStudent.current_page
		state.searchStudentPagination.total = searchStudent.total
		state.searchStudentPagination.per_page = searchStudent.per_page
	},
    startStudentLoader: (state) => state.studentLoader = true,
	stopStudentLoader: (state) => state.studentLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
