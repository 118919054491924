import apiCall from '@/utils/api'

const state = {
	partLevels: [],
	allPartLevels: [],
    partLevelPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    partLevelLoader: false
};

const getters = {
	partLevels: (state) => state.partLevels,
	allPartLevels: (state) => state.allPartLevels,
    partLevelLoader: (state) => state.partLevelLoader,
    partLevelPagination: (state) => state.partLevelPagination
}

const actions = {
	async fetchAllPartLevels({commit}, page) {
		const response = await apiCall({url: `/api/partLevel?type=all`, method: 'GET' });
		commit('setAllPartLevels', response)
        commit('stopLoader', response)
	},
    async fetchPartLevels({commit}, page) {
		const response = await apiCall({url: `/api/partLevel?page=${page}`, method: 'GET' });
		commit('setPartLevels', response)
        commit('stopLoader', response)
	},
    async filterPartLevels({commit, state},resp){
		commit('setPartLevels', resp)
	},
	async startPartLevelLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopPartLevelLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setPartLevels: (state, partLevels) => {
		state.partLevels = partLevels.data
		state.partLevelPagination.current_page = partLevels.current_page
		state.partLevelPagination.total = partLevels.total
		state.partLevelPagination.per_page = partLevels.per_page
	},
	setAllPartLevels: (state, partLevels) => {
		state.allPartLevels = partLevels
	},
    startLoader: (state) => state.partLevelLoader = true,
	stopLoader: (state) => state.partLevelLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
