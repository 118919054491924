<template>
    <div>
      <v-dialog
          v-model="logDataModel"
          persistent
          transition="dialog-bottom-transition"
          max-width="1000"
      >
          <v-card class="elevation-1">
            <v-progress-linear
                    v-if="logDataLoader"
                    height="1"
                    indeterminate
                    color="primary"
                    class="mt-3"
                ></v-progress-linear>
              <v-card-title class="background">
                  <v-btn icon v-if="level==1" class="transparent">
                      
                  </v-btn>
                  <v-btn icon @click="goBack()" v-else>
                      <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                     Data Log
                  <v-spacer></v-spacer>
                      <v-btn icon @click="closeLogDataModel">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
              </v-card-title>
              
              <v-card-text>
                  
                  <div v-if="level==1">
                      <div v-if="logs.length == 0" class="mt-5">
                          <v-layout column>
                          <v-flex xs12>
                              <div class="pa-2">
                              <v-layout row wrap>
                                  <v-flex xs12 md1>
                                  <v-container fill-height fluid>
                                      <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                          <v-icon large class="primary--text">
                                          mdi-alert-circle-outline
                                          </v-icon>
                                      </v-col>
                                      </v-row>
                                  </v-container>
                                  </v-flex>
                                  <v-flex xs12 md11>
                                  <v-container fill-height fluid>
                                      <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                          No Log Found
                                      </v-col>
                                      </v-row>
                                  </v-container>
                                  </v-flex>
                              </v-layout>
                              </div>
                          </v-flex>
                          <v-flex xs12> </v-flex>
                          </v-layout>
                      </div>
                      <div v-else>
                          <v-simple-table>
                              <template v-slot:default>
                                  <thead>
                                      <tr>
                                      <th class="text-left">User</th>
                                      <th class="text-left">Action</th>
                                      <th class="text-left">Date</th>
                                      <th class="text-right"> </th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="item in logs" :key="item.id">
                                      <td>
                                          <div
                                          v-if="highLightId == item.id"
                                          class="green--text"
                                          >
                                              <div v-if="item.modder!=null">
                                                  {{ item.modder.first_name }} {{ item.modder.middle_name }} {{ item.modder.last_name }}
                                              </div>
                                              <div v-else>
                                                  System
                                              </div>
                                          </div>
                                          <div v-else>
                                              <div v-if="item.modder!=null">
                                                  {{ item.modder.first_name }} {{ item.modder.middle_name }} {{ item.modder.last_name }}
                                              </div>
                                              <div v-else>
                                                  System
                                              </div>
                                          </div>
                                      </td>
                                      <td>
                                          <div
                                          v-if="highLightId == item.id"
                                          class="green--text"
                                          >
                                          {{ item.action }}
                                          </div>
                                          <div v-else>
                                          {{ item.action }}
                                          </div>
                                      </td>
                                      <td>
                                          <div
                                          v-if="highLightId == item.id"
                                          class="green--text"
                                          >
                                          {{ item.created_at | moment("DD/MM/YYYY - hh:mm a") }}
                                          </div>
                                          <div v-else>
                                          {{ item.created_at | moment("DD/MM/YYYY - hh:mm a") }}
                                          </div>
                                      </td>
                                      <td>
                                          <div align="right">
                                          <v-tooltip top>
                                              <template v-slot:activator="{ on, attrs }">
                                              <v-btn
                                                  icon
                                                  class="grey lighten-3 mr-1 primary--text"
                                                  @click="showLog(item)"
                                                  v-bind="attrs"
                                                  v-on="on"
                                              >
                                                  <v-icon small> mdi-eye </v-icon>
                                              </v-btn>
                                              </template>
                                              <span> View</span>
                                          </v-tooltip>
                                          </div>
                                      </td>
                                      </tr>
                                  </tbody>
                              </template>
                          </v-simple-table>
                          <div align="right">
                              <v-pagination
                                  v-if="length != 0 && length != 1"
                                  :length="length"
                                  total-visible="5"
                                  v-model="logPagination.current_page"
                                  @input="getLogs()"
                                  circle
                              >
                              </v-pagination>
                              </div>
                      </div>
                  </div>
                  <div v-if="level==2">
                      <v-layout column class="mt-3">
                          <v-flex xs12>
                              <div class="green--text" v-if="assignedLog.models.changed!=null"><b>Changed</b></div>
                              <div class="mx-6 mb-5" v-if="assignedLog.models.changed!=null">
                                  <v-layout row wrap>
                                      <template v-for="(item, index) in assignedLog.models.changed">
                                          <v-flex xs12 md6 :key="index" v-if="index!='updated_at' && index!='created_at' && index!='deleted_at' && index!='log_longitude' && index!='log_latitude'">
                                              <v-layout column>
                                                      <v-flex xs12 class="mt-5">
                                                          <v-layout row wrap>
                                                              <v-flex xs12>
                                                                  <b>{{index}}:</b>
                                                              </v-flex>
                                                              <v-flex xs12>
                                                                  {{item}}
                                                              </v-flex>
                                                          </v-layout>
                                                      </v-flex>
                                              </v-layout>
                                          </v-flex>
                                      </template>
                                  </v-layout>                                
                              </div>
                              <div class="blue--text mt-2" v-if="assignedLog.models.new!=null"><b>New</b></div>
                              <div class="mx-6 mb-5" v-if="assignedLog.models.new!=null">
                                  <v-layout row wrap>
                                      <template v-for="(item, index) in assignedLog.models.new">
                                          <v-flex xs12 md6 :key="index" v-if="index!='updated_at' && index!='created_at' && index!='deleted_at'">
                                              <v-layout column>                                                
                                                  <v-flex xs12 class="mt-5">
                                                      <v-layout row wrap>
                                                          <v-flex xs12>
                                                              <b>{{index}}:</b>
                                                          </v-flex>
                                                          <v-flex xs12>
                                                              {{item}}
                                                          </v-flex>
                                                      </v-layout>
                                                  </v-flex>                                                
                                              </v-layout>
                                          </v-flex>
                                      </template>
                                  </v-layout>                                
                              </div>
                              <div class="red--text mt-2" v-if="assignedLog.models.old!=null"><b>Old</b></div>
                              <div class="mx-6 mb-5" v-if="assignedLog.models.old!=null">
                                  <v-layout row wrap>
                                      <template v-for="(item, index) in assignedLog.models.old">
                                          <v-flex xs12 md6 :key="index" v-if="index!='updated_at' && index!='created_at' && index!='deleted_at'">
                                              <v-layout column>
                                                      <v-flex xs12 class="mt-5">
                                                          <v-layout row wrap>
                                                              <v-flex xs12>
                                                                  <b>{{index}}:</b>
                                                              </v-flex>
                                                              <v-flex xs12>
                                                                  {{item}}
                                                              </v-flex>
                                                          </v-layout>
                                                      </v-flex>
                                                  
                                              </v-layout>
                                          </v-flex>
                                      </template>
                                  </v-layout>                                
                              </div>
                          </v-flex>
                      </v-layout>
                  </div>
                  <div v-if="level==3" style="height: 600px">
                      <v-map ref="myMapRef" style="position: absolute; width: 95%; height: 90%; top: 65px; z-index: 2" :zoom=15 :center="[latitude, longitude]" :options="tileOptions">
                          <v-tilelayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></v-tilelayer>
                          <v-marker 
                              :lat-lng="[latitude, longitude]"
                              :icon="icon">
                          </v-marker>
                      </v-map>                        
                  </div>
              </v-card-text>
          </v-card>
      </v-dialog>
    </div>
  </template>
  <script>
  import apiCall from "@/utils/api";
  import { mapGetters, mapActions } from "vuex";
  
  export default {
    name: "logData",
    components: {
        
    },
    data: () => {
      return {
          level: 1,
          logs: [],
          logPagination: {
              search: ' ',
              current_page: 1,
              per_page: 0,
              total: 0,
              visible: 10
          },
          highLightId: null,
          assignedLog: null,
      };
    },
      created() {
          if(this.logDataModel==true){
              this.getLogs()
              this.startLogLoader()
          }
          
      },
    methods: {
      ...mapActions(["closeLogDataModel", "startLogLoader", "stopLogLoader"]),
      getLogs(){
          apiCall({
            url: "/api/log?type=record&page="+this.logPagination.current_page,
            data: this.logData,
            method: "POST",
          })
            .then((resp) => {
              this.logs = resp.data
              this.logPagination.current_page = resp.current_page
              this.logPagination.total = resp.total
              this.logPagination.per_page = resp.per_page
              this.stopLogLoader()
            })
            .catch((error) => {
              this.message = "Account Does not Exist";
              this.loading = false;
              this.snackbar = true;
              this.alertType = "error";
              this.stopLogLoader()
            });
      },
      showLog(log){
          this.assignedLog = log
          this.highLightId = log.id;
          this.level = 2
      },
      goBack(){
          this.level = 1
      }
     
    },
    computed: {
      ...mapGetters(["logData", "logDataModel", "logDataLoader"]),
      length: function () {
        return Math.ceil(
          this.logPagination.total / this.logPagination.per_page
        );
      },
    },
  };
  </script>