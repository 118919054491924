<template>
	<div class="openSales" v-if="$can('sale_view')">
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>

		<v-dialog v-model="printDialog" transition="dialog-bottom-transition" max-width="600">
			<v-card class="elevation-1" max-width="600">
				<v-toolbar flat color="transparent">
					<div class="title primary--text">{{ receiptNo }}</div>
					<v-spacer></v-spacer>
					<v-btn icon @click="printDialog = false">
						<v-icon large>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<iframe
							class="mt-5"
							:src="receipt" id="receiptFrame"
							frameborder="0" style="border:0;"
							width="100%" height="700"
					>
					</iframe>
				</v-card-text>
			</v-card>
		</v-dialog>


		<div class="pa-5">
			<v-card elevation="0" class="mt-5" v-if="view == 'open' || view == 'closed'">
				<v-tabs show-arrows v-model="viewTab">
					<v-tabs-slider color="primary"></v-tabs-slider>
					<v-tab @click="changeCurrentView('open')">
						<div class="text-none primary--text">Open Sales ({{ openSalePagination.total }})</div>
					</v-tab>
					<v-tab @click="changeCurrentView('closed')">
						<div class="text-none primary--text">Closed Sales ({{ closedSalePagination.total }})</div>
					</v-tab>

				</v-tabs>
			</v-card>

			<v-divider class="mt-5"></v-divider>

			<div v-if="view == 'open'">
				<v-layout column>
					<v-card elevation="0" class="mt-2">
						<v-progress-linear v-if="openSaleLoader" height="1" indeterminate color="primary"></v-progress-linear>
						<v-flex xs12 class="mt-10 mb-2">
							<div class="mx-5">
								<v-layout row wrap>
									<v-flex xs12 md6>
										<div v-if="length != 0" align="left" class="mt-1">
											<b class="display-1 primary--text">Total: </b>{{
												openSalePagination.total | formatNumber
											}}
										</div>
									</v-flex>
									<v-flex xs12 md4>
										<div class="pa-1">
											<v-text-field class="text_field background" outlined dense rounded label="Search"
															  append-icon="mdi-undo-variant" @click:append="resetOpenSaleSearch()"
															  v-on:keyup.enter="searchOpenSale" v-model="openSaleSearchTerm"
															  @input="enableOpenSaleSearch()"></v-text-field>
										</div>
									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1" align="right">
											<v-btn class="button mx-1" icon @click="searchOpenSale" :loading="searchOpenSaleLoader"
													 :disabled="openSaleSearchButtonDisabled">
												<v-icon class="secondary--text">mdi-magnify</v-icon>
											</v-btn>
										</div>
									</v-flex>
								</v-layout>
							</div>
						</v-flex>
						<v-divider class="mt-9 mx-5"></v-divider>
						<v-flex xs12 class="mt-5">
							<div v-if="openSales.length == 0">
								<v-card elevation="0">
									<v-layout row wrap>
										<v-flex xs12 md1>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														<v-icon large class="text--text">
															mdi-alert-circle-outline
														</v-icon>
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
										<v-flex xs12 md11>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center"> No Open Sales Found</v-col>
												</v-row>
											</v-container>
										</v-flex>
									</v-layout>
								</v-card>
							</div>
							<div v-else>
								<div class="hidden-sm-and-down">
									<v-card elevation="0" class="pa-2">
										<v-simple-table>
											<template v-slot:default>
												<thead class="header">
												<tr class="accent">
													<th class="text-left text--text">Sale No</th>
													<th class="text-left text--text">Sale Code</th>
													<th class="text-left text--text">Parent/Guardian</th>
													<th class="text-left text--text">Student</th>
													<th class="text-left text--text">Transaction Code(s)</th>
													<th class="text-left text--text">Item(s)</th>
													<th class="text-left text--text">Source</th>
													<th class="text-left text--text">Sold By</th>
													<th class="text-left text--text">Total</th>
													<th class="text-left text--text">Creation Date</th>
													<th class="text-right text--text">Actions</th>
												</tr>
												</thead>
												<tbody>
												<tr v-for="(item, index) in openSales" :key="item.id">
													<td>
														{{ item.sale_no }}
													</td>
													<td>
														{{ item.sale_code }}
													</td>
													<td>
														{{ item.user.first_name }} {{ item.user.middle_name }} {{
															item.user.last_name
														}}
													</td>
													<td>
														<div v-if="item.sale_student!=null">
															{{ item.sale_student.student.first_name }}
															{{ item.sale_student.student.middle_name }} {{
																item.sale_student.student.last_name
															}}
														</div>
														<div v-else>
															N/A
														</div>
													</td>
													<td>
														<div v-if="item.cash_payment!=null">
															{{ item.cash_payment.cash_no }}
														</div>
														<div v-if="item.credit!=null">
															{{ item.credit.credit_no }}
														</div>
														<div v-if="item.mpesa!=null">
															{{ item.mpesa.mpesa_callback_metadata.MpesaReceiptNumber }}
														</div>
														<div v-if="item.sale_wallet_logs.length != 0">
															<template v-for="(sale_wallet_log, index) in item.sale_wallet_logs">
																<div>
																	{{ sale_wallet_log.wallet_log.transaction_number }}
																</div>
															</template>
														</div>
													</td>
													<td>
														{{ item.sale_parts.length }}
													</td>
													<td>
														<div v-if="item.pos==1">
															POS
														</div>
														<div v-else>
															Online
														</div>
													</td>
													<td>
														<div v-if="item.sold_by==null">
															N/A
														</div>
														<div v-else>
															{{ item.sold_by.first_name }} {{ item.sold_by.middle_name }}
															{{ item.sold_by.last_name }}
														</div>
													</td>
													<td>
														{{ item.total }}
													</td>
													<td>
														{{ item.created_at | moment("DD/MM/YYYY - hh:mm a") }}
													</td>
													<td>
														<div align="right">
															<v-tooltip top v-if="$can('sale_view')">
																<template v-slot:activator="{ on, attrs }">
																	<v-btn icon class="button mr-1 universal--text"
																			 @click="showSale(item)">
																		<v-icon small> mdi-eye</v-icon>
																	</v-btn>
																</template>
																<span>View Sale</span>
															</v-tooltip>
															<v-tooltip top v-if="$can('sale_archive')">
																<template v-slot:activator="{ on, attrs }">
																	<v-btn icon class="button mr-1 error--text" @click="deleteSale(item)"
																			 :loading="loading && saleIndex == item.id" v-bind="attrs"
																			 v-on="on">
																		<v-icon small> mdi-delete</v-icon>
																	</v-btn>
																</template>
																<span>delete</span>
															</v-tooltip>
															<v-tooltip top>
																<template v-slot:activator="{ on, attrs }">
																	<v-btn icon class="button mr-1 black--text"
																			 @click="printReceipt(item, index)"
																			 :loading="receiptLoading && saleIndex==index">
																		<v-icon small> mdi-printer</v-icon>
																	</v-btn>
																</template>
																<span>Print Receipt</span>
															</v-tooltip>
															<v-tooltip top v-if="$can('log_view')">
																<template v-slot:activator="{ on, attrs }">
																	<v-btn icon class="button mr-1 purple--text" @click="viewLog(item)"
																			 v-bind="attrs"
																			 v-on="on">
																		<v-icon small> mdi-script-text</v-icon>
																	</v-btn>
																</template>
																<span>Log</span>
															</v-tooltip>
														</div>
													</td>
												</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>
								</div>
								<div class="hidden-md-and-up">
									<v-layout column>
										<template v-for="(sale, index) in openSales">
											<div :key="index">
												<v-flex xs12 class="mb-2">
													<v-card elevation="0" style="border-radius: 25px;" outlined>
														<div class="pa-5">
															<v-layout column>
																<v-flex xs12>
																	<v-layout row wrap>
																		<v-flex xs11>
																			<div class="title title--text ml-3">
																				<b>{{ sale.sale_no }}</b>
																			</div>
																		</v-flex>
																		<v-flex xs1>
																			<v-btn depressed class="red--text" :loading="loading && saleIndex == sale.id
                                        " icon @click="deleteSale(sale)" v-if="$can('sale_archive')">
																				<v-icon> mdi-delete</v-icon>
																			</v-btn>
																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-divider class="mt-2"></v-divider>
																<v-flex xs12 class="mt-1">
																	<v-layout column>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Sale Code</b>
																			</div>
																			<div>
																				{{
																					sale.sale_code
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Parent/Guardian</b>
																			</div>
																			<div>
																				{{
																					sale.user.first_name
																				}} {{ sale.user.middle_name }} {{
																					sale.user.last_name
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Student</b>
																			</div>
																			<div>
																				<div v-if="sale.sale_student!=null">
																					{{
																						sale.sale_student.student.first_name
																					}} {{ sale.sale_student.student.middle_name }} {{
																						sale.sale_student.student.last_name
																					}}
																				</div>
																				<div v-else>
																					N/A
																				</div>

																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Transaction Code(s)</b>
																			</div>
																			<div>
																				<div v-if="sale.cash_payment!=null">
																					{{ sale.cash_payment.cash_no }}
																				</div>
																				<div v-if="sale.credit!=null">
																					{{ sale.credit.credit_no }}
																				</div>
																				<div v-if="sale.mpesa!=null">
																					{{
																						sale.mpesa.mpesa_callback_metadata.MpesaReceiptNumber
																					}}
																				</div>
																				<div v-if="sale.sale_wallet_logs.length != 0">
																					<template
																							v-for="(sale_wallet_log, index) in sale.sale_wallet_logs">
																						<div>
																							{{ sale_wallet_log.wallet_log.transaction_number }}
																						</div>
																					</template>
																				</div>
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Item(s)</b>
																			</div>
																			<div>
																				{{
																					sale.sale_parts.length
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Source</b>
																			</div>
																			<div>
																				<div v-if="sale.pos==1">
																					POS
																				</div>
																				<div v-else>
																					Online
																				</div>
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Sold By</b>
																			</div>
																			<div>
																				<div v-if="sale.sold_by==null">
																					N/A
																				</div>
																				<div v-else>
																					{{ sale.sold_by.first_name }} {{
																						sale.sold_by.middle_name
																					}} {{ sale.sold_by.last_name }}
																				</div>
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Total</b>
																			</div>
																			<div>
																				Kshs {{
																					sale.total
																				}}
																			</div>
																		</v-flex>

																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Creation Date</b>
																			</div>
																			<div>
																				{{
																					sale.created_at | moment("DD/MM/YYYY - hh:mm a")
																				}}
																			</div>
																		</v-flex>

																		<v-flex xs12 class="mt-5">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn rounded depressed class="
                                                  primary
                                                  text-none
                                                  white--text
                                                " block @click="showSale(sale)">View
																							<v-icon right> mdi-eye</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn rounded block depressed
																								 class="black text-none white--text"
																								 @click="printReceipt(sale, index)"
																								 :loading="receiptLoading && saleIndex==index">
																							Print Receipt
																							<v-icon small> mdi-printer</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12 v-if="$can('log_view')">
																					<div class="pa-1">
																						<v-btn rounded block depressed
																								 class="purple text-none white--text"
																								 @click="viewLog(sale)" v-bind="attrs"
																								 v-on="on"> Log
																							<v-icon small> mdi-script-text</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																	</v-layout>
																</v-flex>
															</v-layout>
														</div>
													</v-card>
												</v-flex>
											</div>
										</template>
									</v-layout>
								</div>
							</div>
						</v-flex>
					</v-card>

					<v-layout column class="mt-5">
						<v-flex xs12>
							<div align="center">
								<v-pagination v-if="openSaleLength != 0" :length="openSaleLength" total-visible="10"
												  v-model="openSalePagination.current_page" @input="changeOpenSalePage()" circle>
								</v-pagination>
							</div>
						</v-flex>

					</v-layout>


				</v-layout>
			</div>
			<div v-if="view == 'closed'">
				<v-layout column>
					<v-card elevation="0" class="mt-2">
						<v-progress-linear v-if="closedSaleLoader" height="1" indeterminate
												 color="primary"></v-progress-linear>
						<v-flex xs12 class="mt-10 mb-2">
							<div class="mx-5">
								<v-layout row wrap>
									<v-flex xs12 md6>
										<div v-if="length != 0" align="left" class="mt-1">
											<b class="display-1 primary--text">Total: </b>{{
												closedSalePagination.total | formatNumber
											}}
										</div>
									</v-flex>
									<v-flex xs12 md4>
										<div class="pa-1">
											<v-text-field class="text_field background" outlined dense rounded label="Search"
															  append-icon="mdi-undo-variant" @click:append="resetClosedSaleSearch()"
															  v-on:keyup.enter="searchClosedSale" v-model="closedSaleSearchTerm"
															  @input="enableClosedSaleSearch()"></v-text-field>
										</div>
									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1" align="right">
											<v-btn class="button mx-1" icon @click="searchClosedSale"
													 :loading="searchClosedSaleLoader"
													 :disabled="closedSaleSearchButtonDisabled">
												<v-icon class="secondary--text">mdi-magnify</v-icon>
											</v-btn>
										</div>
									</v-flex>
								</v-layout>
							</div>
						</v-flex>
						<v-divider class="mt-9 mx-5"></v-divider>
						<v-flex xs12 class="mt-5">
							<div v-if="closedSales.length == 0">
								<v-card elevation="0">
									<v-layout row wrap>
										<v-flex xs12 md1>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														<v-icon large class="text--text">
															mdi-alert-circle-outline
														</v-icon>
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
										<v-flex xs12 md11>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center"> No Closed Sales Found</v-col>
												</v-row>
											</v-container>
										</v-flex>
									</v-layout>
								</v-card>
							</div>
							<div v-else>
								<div class="hidden-sm-and-down">
									<v-card elevation="0" class="pa-2">
										<v-simple-table>
											<template v-slot:default>
												<thead class="header">
												<tr class="accent">
													<th class="text-left text--text">Sale No</th>
													<th class="text-left text--text">Sale Code</th>
													<th class="text-left text--text">Parent/Guardian</th>
													<th class="text-left text--text">Student</th>
													<th class="text-left text--text">Tansaction Code(s)</th>
													<th class="text-left text--text">Item(s)</th>
													<th class="text-left text--text">Source</th>
													<th class="text-left text--text">Sold By</th>
													<th class="text-left text--text">Total</th>
													<th class="text-left text--text">Creation Date</th>
													<th class="text-right text--text">Actions</th>
												</tr>
												</thead>
												<tbody>
												<tr v-for="(item, index) in closedSales" :key="item.id">
													<td>
														{{ item.sale_no }}
													</td>
													<td>
														{{ item.sale_code }}
													</td>
													<td>
														{{ item.user.first_name }} {{ item.user.middle_name }} {{
															item.user.last_name
														}}
													</td>
													<td>
														<div v-if="item.sale_student != null">
															{{ item.sale_student.student.first_name }}
															{{ item.sale_student.student.middle_name }} {{
																item.sale_student.student.last_name
															}}
														</div>
														<div v-else>
															N/A
														</div>
													</td>
													<td>
														<div v-if="item.cash_payment!=null">
															{{ item.cash_payment.cash_no }}
														</div>
														<div v-if="item.credit!=null">
															{{ item.credit.credit_no }}
														</div>
														<div v-if="item.mpesa!=null">
															{{ item.mpesa.mpesa_callback_metadata.MpesaReceiptNumber }}
														</div>
														<div v-if="item.sale_wallet_logs.length != 0">
															<template v-for="(sale_wallet_log, index) in item.sale_wallet_logs">
																<div>
																	{{ sale_wallet_log.wallet_log.transaction_number }}
																</div>
															</template>
														</div>
													</td>
													<td>
														{{ item.sale_parts.length }}
													</td>
													<td>
														<div v-if="item.pos==1">
															POS
														</div>
														<div v-else>
															Online
														</div>
													</td>
													<td>
														<div v-if="item.sold_by==null">
															N/A
														</div>
														<div v-else>
															{{ item.sold_by.first_name }} {{ item.sold_by.middle_name }}
															{{ item.sold_by.last_name }}
														</div>
													</td>
													<td>
														{{ item.total }}
													</td>
													<td>
														{{ item.created_at | moment("DD/MM/YYYY - hh:mm a") }}
													</td>
													<td>
														<div align="right">
															<v-tooltip top v-if="$can('sale_view')">
																<template v-slot:activator="{ on, attrs }">
																	<v-btn icon class="button mr-1 universal--text"
																			 @click="showSale(item)">
																		<v-icon small> mdi-eye</v-icon>
																	</v-btn>
																</template>
																<span>View Sale</span>
															</v-tooltip>
															<v-tooltip top v-if="$can('sale_archive')">
																<template v-slot:activator="{ on, attrs }">
																	<v-btn icon class="button mr-1 error--text" @click="deleteSale(item)"
																			 :loading="loading && saleIndex == item.id" v-bind="attrs"
																			 v-on="on">
																		<v-icon small> mdi-delete</v-icon>
																	</v-btn>
																</template>
																<span>delete</span>
															</v-tooltip>
															<v-tooltip top>
																<template v-slot:activator="{ on, attrs }">
																	<v-btn icon class="button mr-1 black--text"
																			 @click="printReceipt(item, index)"
																			 :loading="receiptLoading && saleIndex==index">
																		<v-icon small> mdi-printer</v-icon>
																	</v-btn>
																</template>
																<span>Print Receipt</span>
															</v-tooltip>
															<v-tooltip top v-if="$can('log_view')">
																<template v-slot:activator="{ on, attrs }">
																	<v-btn icon class="button mr-1 purple--text" @click="viewLog(item)"
																			 v-bind="attrs"
																			 v-on="on">
																		<v-icon small> mdi-script-text</v-icon>
																	</v-btn>
																</template>
																<span>Log</span>
															</v-tooltip>
														</div>
													</td>
												</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>
								</div>
								<div class="hidden-md-and-up">
									<v-layout column>
										<template v-for="(sale, index) in closedSales">
											<div :key="index">
												<v-flex xs12 class="mb-2">
													<v-card elevation="0" style="border-radius: 25px;" outlined>
														<div class="pa-5">
															<v-layout column>
																<v-flex xs12>
																	<v-layout row wrap>
																		<v-flex xs11>
																			<div class="title title--text ml-3">
																				<b>{{ sale.sale_no }}</b>
																			</div>
																		</v-flex>
																		<v-flex xs1>
																			<v-btn depressed class="red--text" :loading="loading && saleIndex == sale.id
                                        " icon @click="deleteSale(sale)" v-if="$can('sale_archive')">
																				<v-icon> mdi-delete</v-icon>
																			</v-btn>
																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-divider class="mt-2"></v-divider>
																<v-flex xs12 class="mt-1">
																	<v-layout column>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Sale Code</b>
																			</div>
																			<div>
																				{{
																					sale.sale_code
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Parent/Guardian</b>
																			</div>
																			<div>
																				{{
																					sale.user.first_name
																				}} {{ sale.user.middle_name }} {{
																					sale.user.last_name
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Student</b>
																			</div>
																			<div>
																				<div v-if="sale.sale_student != null">
																					{{
																						sale.sale_student.student.first_name
																					}} {{ sale.sale_student.student.middle_name }} {{
																						sale.sale_student.student.last_name
																					}}
																				</div>
																				<div v-else>
																					N/A
																				</div>
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Transaction Code(s)</b>
																			</div>
																			<div>
																				<div v-if="sale.cash_payment!=null">
																					{{ sale.cash_payment.cash_no }}
																				</div>
																				<div v-if="sale.credit!=null">
																					{{ sale.credit.credit_no }}
																				</div>
																				<div v-if="sale.mpesa!=null">
																					{{
																						sale.mpesa.mpesa_callback_metadata.MpesaReceiptNumber
																					}}
																				</div>
																				<div v-if="sale.sale_wallet_logs.length != 0">
																					<template
																							v-for="(sale_wallet_log, index) in sale.sale_wallet_logs">
																						<div>
																							{{ sale_wallet_log.wallet_log.transaction_number }}
																						</div>
																					</template>
																				</div>
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Item(s)</b>
																			</div>
																			<div>
																				{{
																					sale.sale_parts.length
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Source</b>
																			</div>
																			<div>
																				<div v-if="sale.pos==1">
																					POS
																				</div>
																				<div v-else>
																					Online
																				</div>
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Sold By</b>
																			</div>
																			<div>
																				<div v-if="sale.sold_by==null">
																					N/A
																				</div>
																				<div v-else>
																					{{ sale.sold_by.first_name }} {{
																						sale.sold_by.middle_name
																					}} {{ sale.sold_by.last_name }}
																				</div>
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Total</b>
																			</div>
																			<div>
																				Kshs {{
																					sale.total
																				}}
																			</div>
																		</v-flex>

																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Creation Date</b>
																			</div>
																			<div>
																				{{
																					sale.created_at | moment("DD/MM/YYYY - hh:mm a")
																				}}
																			</div>
																		</v-flex>

																		<v-flex xs12 class="mt-5">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn rounded depressed class="
                                                  primary
                                                  text-none
                                                  white--text
                                                " block @click="showSale(sale)">View
																							<v-icon right> mdi-eye</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn rounded block depressed
																								 class="black text-none white--text"
																								 @click="printReceipt(sale, index)"
																								 :loading="receiptLoading && saleIndex==index">
																							Print Receipt
																							<v-icon small> mdi-printer</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12 v-if="$can('log_view')">
																					<div class="pa-1">
																						<v-btn rounded block depressed
																								 class="purple text-none white--text"
																								 @click="viewLog(sale)" v-bind="attrs"
																								 v-on="on"> Log
																							<v-icon small> mdi-script-text</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																	</v-layout>
																</v-flex>
															</v-layout>
														</div>
													</v-card>
												</v-flex>
											</div>
										</template>
									</v-layout>
								</div>
							</div>
						</v-flex>
					</v-card>
					<v-layout column class="mt-5">
						<v-flex xs12>
							<div align="center">
								<v-pagination v-if="closedSaleLength != 0" :length="closedSaleLength" total-visible="10"
												  v-model="closedSalePagination.current_page" @input="changeClosedSalePage()"
												  circle>
								</v-pagination>
							</div>
						</v-flex>
					</v-layout>
				</v-layout>
			</div>
			<div v-if="view == 'show'">
				<v-card outlined class="mt-2">
					<v-card-title class="accent text--text">
						{{ assignedSale.sale_no }} - {{ assignedSale.user.first_name }} {{ assignedSale.user.middle_name }} {{
							assignedSale.user.last_name
						}}
						<v-spacer></v-spacer>
						<v-btn v-if="prevView=='open'" icon @click="changeView('open')">
							<v-icon class="text--text"> mdi-close</v-icon>
						</v-btn>
						<v-btn v-if="prevView=='closed'" icon @click="changeView('closed')">
							<v-icon class="text--text"> mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text>
						<div v-if="refundView == 'show' && this.assignedSalePartStock" class="">
							<v-card outlined class="mt-5">
								<v-card-title class="accent text--text">
									<v-spacer></v-spacer>
									<div>Request Refund</div>
									<v-spacer></v-spacer>
									<v-btn icon @click="changeRefundView('default')">
										<v-icon> mdi-close</v-icon>
									</v-btn>
								</v-card-title>
								<v-card-text>
									<v-flex class="mt-5">
										<v-card-text>
											<v-form v-model="valid" ref="refundForm" lazy-validation>
												<v-layout wrap class="mt-5 d-flex">
													<v-flex xs12 md6>
														<div class="pa-3 pb-0">
															<div>
																Quantity<span class="error--text">*</span>
															</div>
															<v-text-field class="text_field background my-2" outlined
																			  dense v-model="newSalePartStock.quantity"
																			  :rules="inputRules">
															</v-text-field>
														</div>
													</v-flex>
													<v-flex xs12 md6>
														<div class="pa-3 pb-0">
															<div>
																Refund reason<span class="error--text">*</span>
															</div>
															<v-text-field class="text_field background my-2" outlined
																			  dense v-model="newSalePartStock.reason" :rules="inputRules">
															</v-text-field>
														</div>
													</v-flex>
													<v-flex xs12 md4>
														<div class="pa-3">
															<v-btn
																	depressed block
																	class="secondary text-none white--text"
																	@click="initiateRefund"
																	:disabled="!valid"
																	:loading="stockLoading && refundPartStockIndex == assignedSalePartStock.id">
																Request Refund
																<v-icon right>
																	mdi-cash-refund
																</v-icon>
															</v-btn>
														</div>
													</v-flex>

												</v-layout>
											</v-form>
										</v-card-text>
									</v-flex>
								</v-card-text>
							</v-card>
						</div>
						<div v-else class="pa-3">
							<v-tabs show-arrows v-model="saleTab">
								<v-tabs-slider color="primary"></v-tabs-slider>

								<v-tab @click="activeTab = 'details'">
									<div class="text-none primary--text">Details</div>
								</v-tab>
								<v-tab @click="activeTab = 'saleItems'">
									<div class="text-none primary--text">
										Parts ({{ assignedSale.sale_parts.length }})
									</div>
								</v-tab>
							</v-tabs>
							<div v-if="activeTab == 'details'" class="mx-3 mt-7">
								<v-layout row wrap>
									<v-flex xs12 md6>
										<v-layout column>

											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs11>
														<div><b>Parent/Guardian Details:</b></div>
													</v-flex>
													<v-flex xs1>

													</v-flex>
												</v-layout>

											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Name:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														{{ assignedSale.user.first_name }} {{ assignedSale.user.middle_name }} {{
															assignedSale.user.last_name
														}}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Email:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														{{ assignedSale.user.email }}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Phone #:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														{{ assignedSale.user.phone }}
													</v-flex>
												</v-layout>
											</v-flex>

										</v-layout>

										<v-layout column class="mt-10" v-if="assignedSale.guest_sale != null">
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs11>
														<div><b>Narration:</b></div>
													</v-flex>
													<v-flex xs1>

													</v-flex>
												</v-layout>

											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12>
														<div>{{ assignedSale.guest_sale.narration }}</div>
													</v-flex>
												</v-layout>

											</v-flex>
										</v-layout>
										<v-layout column class="mt-10" v-if="assignedSale.credit != null">
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs11>
														<div><b>Narration:</b></div>
													</v-flex>
													<v-flex xs1>

													</v-flex>
												</v-layout>

											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12>
														<div>{{ assignedSale.credit.narration }}</div>
													</v-flex>
												</v-layout>

											</v-flex>
										</v-layout>
										<v-layout column class="mt-10" v-if="assignedSale.sale_student != null">
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs11>
														<div><b>Student Details:</b></div>
													</v-flex>
													<v-flex xs1>

													</v-flex>
												</v-layout>

											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Name:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														{{ assignedSale.sale_student.student.first_name }} {{
															assignedSale.sale_student.student.middle_name
														}} {{
															assignedSale.sale_student.student.last_name
														}}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Email:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														<div v-if="assignedSale.sale_student.student.email != null">
															{{ assignedSale.sale_student.student.email }}
														</div>
														<div v-else>
															N/A
														</div>
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Phone #:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														<div v-if="assignedSale.sale_student.student.phone != null">
															{{ assignedSale.sale_student.student.phone }}
														</div>
														<div v-else>
															N/A
														</div>
													</v-flex>
												</v-layout>
											</v-flex>

										</v-layout>

									</v-flex>
									<v-flex xs12 md6>
										<v-layout column>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs11>
														<div><b>Sale Details</b></div>
													</v-flex>
													<v-flex xs1>

													</v-flex>
												</v-layout>

											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Sale Code:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														{{
															assignedSale.sale_code
														}}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Sale No:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														{{
															assignedSale.sale_no
														}}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Status:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														{{
															assignedSale.status.name
														}}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Total (Kshs):</b></div>
													</v-flex>
													<v-flex xs12 md10>
														<div align="left">
															{{
																assignedSale.total
															}}
														</div>
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Creation Date:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														{{
															assignedSale.created_at
																	| moment("DD/MM/YYYY - hh:mm a")
														}}
													</v-flex>
												</v-layout>
											</v-flex>

										</v-layout>

										<v-layout column class="mt-10">
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs11>
														<div><b>Payment Details</b></div>
													</v-flex>
													<v-flex xs1>

													</v-flex>
												</v-layout>

											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Ref Code:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														<div v-if="assignedSale.cash_payment!=null">
															{{ assignedSale.cash_payment.cash_no }}
														</div>
														<div v-if="assignedSale.credit!=null">
															{{ assignedSale.credit.credit_no }}
														</div>
														<div v-if="assignedSale.mpesa!=null">
															{{
																assignedSale.mpesa.mpesa_callback_metadata.MpesaReceiptNumber
															}}
														</div>
														<div v-if="assignedSale.sale_wallet_logs.length != 0">
															<template
																	v-for="(sale_wallet_log, index) in assignedSale.sale_wallet_logs">
																<div>
																	{{ sale_wallet_log.wallet_log.transaction_number }}
																</div>
															</template>
														</div>

													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Amount:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														<div v-if="assignedSale.cash_payment!=null">
															{{ assignedSale.cash_payment.total }}
														</div>
														<div v-if="assignedSale.credit!=null">
															Credit
														</div>
														<div v-if="assignedSale.mpesa!=null">
															{{
																assignedSale.mpesa.mpesa_callback_metadata.Amount
															}}
														</div>
														<div v-if="assignedSale.sale_wallet_logs.length != 0">
															<template
																	v-for="(sale_wallet_log, index) in assignedSale.sale_wallet_logs">
																<div>
																	{{ sale_wallet_log.wallet_log.transaction_number }} -
																	{{ sale_wallet_log.wallet_log.amount }}
																</div>
															</template>
														</div>

													</v-flex>
												</v-layout>
											</v-flex>
										</v-layout>
									</v-flex>
								</v-layout>
							</div>
							<div v-if="activeTab == 'saleItems'" class="mt-2">
								<v-layout column>
									<template v-for="(sale_part, index) in assignedSale.sale_parts">
										<v-flex xs12 :key="index">
											<div class="my-5">
												<v-card elevation="0">
													<v-layout row wrap>
														<v-flex xs12 md4>
															<v-img v-if="sale_part.part.image == null" class="background" contain
																	 style="border-radius: 25px;" max-height="180"
																	 src="img/book-silhouette-vector-14.png">

															</v-img>
															<v-img max-height="180" contain v-else
																	 :src="path + '/storage/part_pictures/' + sale_part.part.image"
																	 style="border-radius: 25px;" class="background">

															</v-img>
														</v-flex>
														<v-flex xs12 md8>
															<div class="mx-10">
																<v-layout column>
																	<v-flex xs12 class="mt-1">
																		<div class="ml-2 primary--text">
																			{{ sale_part.part.name }}
																		</div>
																	</v-flex>

																	<v-flex xs12>
																		<div class="ml-5 mt-5">
																			<v-layout row wrap>
																				<v-flex xs3>
																					<b>Serial No:</b>
																				</v-flex>
																				<v-flex xs9>
																					{{ sale_part.part.serial_no }}
																				</v-flex>
																			</v-layout>
																		</div>
																	</v-flex>
																	<v-flex xs12>
																		<div class="ml-5 mt-5">
																			<v-layout row wrap>
																				<v-flex xs3>
																					<b>Quantity Purchased:</b>
																				</v-flex>
																				<v-flex xs9>
																					{{ sale_part.quantity }}
																				</v-flex>
																			</v-layout>
																		</div>
																	</v-flex>
																	<v-flex xs12>
																		<div class="ml-5 mt-5">
																			<v-layout row wrap>
																				<v-flex xs3>
																					<b>Unit Price(Kshs):</b>
																				</v-flex>
																				<v-flex xs9>
																					{{ sale_part.unit_price }}
																				</v-flex>
																			</v-layout>
																		</div>
																	</v-flex>
																	<v-flex xs12>
																		<div class="ml-5 mt-5">
																			<v-layout row wrap>
																				<v-flex xs3>
																					<b>Sub Total:</b>
																				</v-flex>
																				<v-flex xs9>
																					{{ sale_part.sub_total }}
																				</v-flex>
																			</v-layout>
																		</div>
																	</v-flex>
																</v-layout>


																<v-layout column class="mt-10">
																	<v-flex xs12 class="mt-1">
																		<div class="ml-2 primary--text mb-3">
																			<b>Issued Stock</b>
																		</div>
																	</v-flex>

																	<v-flex xs12>
																		<div class="ml-5 mt-5">
																			<v-layout column>
																				<template
																						v-for="(sale_part_stock, index) in sale_part.sale_part_stocks">
																					<v-flex xs12 class="mb-16">
																						<v-layout row wrap>
																							<v-flex xs1>
																								{{ index + 1 }}.
																							</v-flex>
																							<v-flex xs11>
																								<v-layout row wrap>
																									<v-flex xs12 md4>
																										<b>Stock No:</b>
																									</v-flex>
																									<v-flex xs12 md8>
																										{{ sale_part_stock.stock.stock_no }}
																									</v-flex>
																								</v-layout>
																								<v-layout row wrap class="mt-2">
																									<v-flex xs12 md4>
																										<b>Invoice No:</b>
																									</v-flex>
																									<v-flex xs12 md8>
																										{{ sale_part_stock.stock.stock_no }}
																									</v-flex>
																								</v-layout>
																								<v-layout row wrap class="mt-2">
																									<v-flex xs12 md4>
																										<b>Supplier:</b>
																									</v-flex>
																									<v-flex xs12 md8>
																										{{ sale_part_stock.stock.supplier.name }}
																									</v-flex>
																								</v-layout>
																								<v-layout row wrap class="mt-2">
																									<v-flex xs12 md4>
																										<b>Selling Price:</b>
																									</v-flex>
																									<v-flex xs12 md8>
																										{{ sale_part_stock.stock.selling_price }}
																									</v-flex>
																								</v-layout>
																								<v-layout row wrap class="mt-2">
																									<v-flex xs12 md4>
																										<b>Quantity:</b>
																									</v-flex>
																									<v-flex xs12 md8>
																										{{ sale_part_stock.quantity }}
																									</v-flex>
																								</v-layout>
																								<v-layout row wrap class="mt-2">
																									<v-flex xs12 md4>
																										<b>Status:</b>
																									</v-flex>
																									<v-flex xs12 md8>
																										<div v-if="sale_part_stock.status==0">
																											Pending Issue
																										</div>
																										<div v-if="sale_part_stock.status==1">
																											Issued
																										</div>
																										<div v-if="sale_part_stock.status==2">
																											Returned & Refunded
																										</div>
																									</v-flex>
																								</v-layout>
																								<v-layout row wrap class="mt-2"
																											 v-if="sale_part_stock.issuer_id != null">
																									<v-flex xs12 md4>
																										<b>Issued By:</b>
																									</v-flex>
																									<v-flex xs12 md8>
																										{{ sale_part_stock.issuer.first_name }}
																										{{ sale_part_stock.issuer.middle_name }}
																										{{ sale_part_stock.issuer.last_name }}
																									</v-flex>
																								</v-layout>
																								<v-layout row wrap class="mt-2"
																											 v-if="assignedSale.sold_by!= null && sale_part_stock.issuer_id == null">
																									<v-flex xs12 md4>
																										<b>Issued By:</b>
																									</v-flex>
																									<v-flex xs12 md8>
																										{{ assignedSale.sold_by.first_name }}
																										{{ assignedSale.sold_by.middle_name }}
																										{{ assignedSale.sold_by.last_name }}
																									</v-flex>
																								</v-layout>
																								<v-layout v-if="sale_part_stock.refund==1" row
																											 wrap class="mt-2">
																									<v-flex xs12 md4>
																										<b>Refund Status:</b>
																									</v-flex>
																									<v-flex xs12 md8>
																										<div v-if="sale_part_stock.status==2"
																											  class="green--text">
																											Approved
																										</div>
																										<div v-if="sale_part_stock.status==3"
																											  class="red--text">
																											Rejected
																										</div>
																										<div v-if="sale_part_stock.status==1 && sale_part_stock.refund==1 && sale_part_stock.approver_id != null"
																											  class="red--text">
																											Rejected
																										</div>
																										<div v-if="sale_part_stock.status==1 && sale_part_stock.refund==1 && sale_part_stock.approver_id == null"
																											  class="green--text">
																											Pending
																										</div>
																									</v-flex>
																								</v-layout>
																								<v-layout row wrap class="mt-5">
																									<v-flex xs6>
																										<div class="mx-1">
																											<v-btn
																													v-if="$can('sale_issue_stock') && (sale_part_stock.status==0)"
																													depressed block
																													class="primary text-none white--text"
																													@click="issueSalePartStock(sale_part_stock)"
																													:loading="stockLoading && salePartStockIndex == sale_part_stock.id">
																												Issue
																												<v-icon right>
																													mdi-hand-coin
																												</v-icon>
																											</v-btn>
																											<v-btn
																													v-if="$can('sale_issue_stock') && (sale_part_stock.status==1 || sale_part_stock.status==2 || sale_part_stock.status==3)"
																													depressed block disabled
																													class="primary text-none white--text">
																												Issue
																												<v-icon right>
																													mdi-hand-coin
																												</v-icon>
																											</v-btn>
																										</div>
																									</v-flex>
																									<v-flex xs6>
																										<div class="mx-1">
																											<v-btn
																													v-if="$can('sale_initiate_refund') && (sale_part_stock.status==1) && sale_part_stock.refund==0"
																													depressed block
																													class="secondary text-none white--text"
																													@click="showRequestRefund(sale_part_stock)">
																												Request Refund
																												<v-icon right>
																													mdi-cash-refund
																												</v-icon>
																											</v-btn>
																											<v-btn
																													v-if="$can('sale_initiate_refund') && (sale_part_stock.refund==1 || sale_part_stock.status==0)"
																													depressed block disabled
																													class="primary text-none white--text">
																												Request Refund
																												<v-icon right>
																													mdi-cash-refund
																												</v-icon>
																											</v-btn>
																										</div>
																									</v-flex>
																								</v-layout>
																							</v-flex>
																						</v-layout>
																					</v-flex>
																				</template>
																			</v-layout>
																		</div>
																	</v-flex>
																</v-layout>
															</div>

														</v-flex>
													</v-layout>
												</v-card>

												<v-divider class="mt-10"></v-divider>
											</div>
										</v-flex>
									</template>
								</v-layout>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</div>
		</div>
		<logData v-if="logDataModel" v-model="logDataModel"/>
	</div>
</template>
<style>
.ck-editor__editable {
	min-height: 200px;
}
</style>
<script>
import axios from "axios"
import apiCall from "@/utils/api";
import {mapActions, mapGetters} from "vuex";
import logData from "@/views/accessControl/logData";
import Vue from "vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0");
});

Vue.use(require("vue-moment"));

export default {
	components: {
		logData,
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,

			view: "open",
			refundView: "default",
			assignedSalePartStock: null,
			prevView: "open",
			loading: false,
			activateLoading: false,
			deactivateLoading: false,
			broadcastLoading: false,
			stockLoading: false,

			printDialog: false,
			receipt: null,
			receiptLoading: false,
			receiptNo: null,

			saleView: "default",

			activeTab: 'details',
			saleTab: null,

			editor: ClassicEditor,
			editorConfig: {},

			menu1: false,
			menu2: false,
			allowedStep: (m) => m % 15 === 0,
			valid: true,
			startDatePicker: false,
			endDatePicker: false,
			message: "",
			color: "",
			delete: false,
			activate: false,
			confirm: false,
			saleIndex: null,
			salePartStockIndex: null,
			refundPartStockIndex: null,
			snackbar: false,
			inputRules: [(v) => !!v || "Input is required"],

			coverImageDialog: false,
			imageDialog: false,
			imageLoading: false,
			fileRules: [(v) => !!v || "Kindly selecta a File"],
			imageFile: null,
			imageValid: true,

			openSaleSearchTerm: null,
			closedSaleSearchTerm: null,
			searchOpenSaleLoader: false,
			searchClosedSaleLoader: false,
			openSaleSearchButtonDisabled: false,
			closedSaleSearchButtonDisabled: false,
			activeSaleOwnerTab: 'organisation',
			filteredOpenSales: false,
			filteredClosedSales: false,

			viewTab: null,
			newSale: {
				id: null,
				sale_type_id: null,
				title: null,
				brief: null,
				description: null,
				start_date: null,
				start_time: null,
				end_date: null,
				end_time: null,
				entry_cost: null,
				location: null,
				longitude: null,
				latitude: null,
				interests: [],
				sectors: [],
				sdgs: [],
				institutions: [],
				organisations: []
			},
			newSalePartStock: {
				id: null,
				reason: "",
				quantity: null,
			},
			assignedSale: null,
			assignedResponse: null,
			formData: null,
			months: [
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
			],
			monthNames: [
				{name: "January"},
				{name: "February"},
				{name: "March"},
				{name: "April"},
				{name: "May"},
				{name: "June"},
				{name: "July"},
				{name: "August"},
				{name: "September"},
				{name: "October"},
				{name: "November"},
				{name: "December"},
			],

			logData: {
				message: null,
				model: null,
			},
		};
	},
	created() {
		if (this.$can("sale_view")) {
			this.startOpenSaleLoader();
			this.startClosedSaleLoader();
			this.fetchOpenSales(this.openSalePagination.current_page);
			this.fetchClosedSales(this.closedSalePagination.current_page);
			// this.getFormData();
		}
	},
	methods: {
		...mapActions([
			"fetchOpenSales",
			"fetchClosedSales",
			"startOpenSaleLoader",
			"startClosedSaleLoader",
			"stopOpenSaleLoader",
			"stopClosedSaleLoader",
			"filterOpenSales",
			"filterClosedSales",

			"filterLogData",
			"openLogDataModel",
		]),

		printReceipt(sale, index) {
			this.receiptNo = sale.sale_no
			this.saleIndex = index;
			this.receiptLoading = true
			if (sale.cash_payment != null) {
				axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token');
				axios({
					url: this.path + "/api/pos?type=cashReceipt&sale_id=" + sale.id,
					method: "GET",
					responseType: "blob",
				})
						.then((resp) => {
							this.receiptLoading = false
							this.downloadFile(resp, 'Receipt');
						})
						.catch((error) => {
							this.receiptLoading = false
						});
			} else if (sale.credit != null) {
				axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token');
				axios({
					url: this.path + "/api/pos?type=creditReceipt&sale_id=" + sale.id,
					method: "GET",
					responseType: "blob",
				})
						.then((resp) => {
							this.receiptLoading = false
							this.downloadFile(resp, 'Receipt');
						})
						.catch((error) => {
							this.receiptLoading = false
						});
			} else if (sale.mpesa != null) {
				axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token');
				axios({
					url: this.path + "/api/pos?type=mpesaReceipt&sale_id=" + sale.id,
					method: "GET",
					responseType: "blob",
				})
						.then((resp) => {
							this.receiptLoading = false
							this.downloadFile(resp, 'Receipt');
						})
						.catch((error) => {
							this.receiptLoading = false
						});
			} else {
				axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token');
				axios({
					url: this.path + "/api/pos?type=walletReceipt&sale_id=" + sale.id,
					method: "GET",
					responseType: "blob",
				})
						.then((resp) => {
							this.receiptLoading = false
							this.downloadFile(resp, 'Receipt');
						})
						.catch((error) => {
							this.receiptLoading = false
						});
			}
		},
		downloadFile(response, filename) {
			this.printDialog = true
			// It is necessary to create a new blob object with mime-type explicitly set
			// otherwise only Chrome works like it should
			var newBlob = new Blob([response.data], {type: "application/pdf"});

			// IE doesn't allow using a blob object directly as link href
			// instead it is necessary to use msSaveOrOpenBlob
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}

			// For other browsers:
			// Create a link pointing to the ObjectURL containing the blob.

			this.receipt = URL.createObjectURL(newBlob);
			// this.receiptIFrame = true
			// setTimeout(function () {
			//     let objFra = document.getElementById('receiptFrame');
			//     objFra.contentWindow.focus();
			//     objFra.contentWindow.print();
			// }, 100);

			// var link = document.createElement("a");
			// link.href = data;
			// link.download = filename + ".pdf";
			// link.click();
			// setTimeout(function () {
			//     // For Firefox it is necessary to delay revoking the ObjectURL
			//     window.URL.revokeObjectURL(data);
			// }, 100);
		},


		changeCurrentView(view) {
			this.prevView = view
			this.changeView(view)
		},
		enableOpenSaleSearch() {
			this.openSaleSearchButtonDisabled = false;
		},
		enableClosedSaleSearch() {
			this.closedSaleSearchButtonDisabled = false;
		},
		closeStartDate() {
			this.startDatePicker = false;
		},
		closeEndDate() {
			this.endDatePicker = false;
		},
		removeInstitution(item) {
			this.newSale.institutions.splice(this.newSale.institutions.indexOf(item), 1)
		},
		removeOrganisation(item) {
			this.newSale.organisations.splice(this.newSale.organisations.indexOf(item), 1)
		},

		changeSaleOwnerTab(tab) {
			this.newSale.institutions = [],
					this.newSale.organisations = [],

					this.activeSaleOwnerTab = tab
		},
		resetOpenSaleSearch() {
			this.openSaleSearchTerm = null;
			this.filteredClosedSales = true;
			this.startOpenSaleLoader();
			this.fetchOpenSales(1);
		},
		resetClosedSaleSearch() {
			this.closedSaleSearchTerm = null;
			this.filteredClosedSales = true;
			this.stopClosedSaleLoader();
			this.fetchClosedSales(1);
		},
		showSale(sale) {
			this.assignedSale = sale;
			this.changeView("show");
		},

		uploadImage() {
			if (this.$refs.imageForm.validate()) {
				this.imageLoading = true;
				let formData = new FormData();

				// files

				formData.append("files", this.imageFile, this.imageFile.name);
				formData.append("id", this.assignedSale.id);

				apiCall({
					url: "/api/sales?type=logo",
					data: formData,
					method: "POST",
				})
						.then((resp) => {
							this.snackbar = true;
							this.message = "Logo Uploaded Successfully";
							this.color = "success";
							this.fetchOpenSales(this.openSalePagination.current_page);
							this.imageLoading = false;
							this.imageDialog = false;
							this.imageFile = null;
							this.assignedSale.logo = resp.logo;
							// this.changeView('default')
						})
						.catch((error) => {
							this.imageLoading = false;
						});
			}
		},
		uploadCoverImage() {
			if (this.$refs.imageForm.validate()) {
				this.imageLoading = true;
				let formData = new FormData();

				// files
				formData.append("files", this.imageFile, this.imageFile.name);
				formData.append("id", this.assignedSale.id);

				apiCall({
					url: "/api/sales?type=coverPhoto",
					data: formData,
					method: "POST",
				})
						.then((resp) => {
							this.snackbar = true;
							this.message = "Cover Image Uploaded Successfully";
							this.color = "success";
							this.fetchClosedSales(this.closedSalePagination.current_page);
							this.imageLoading = false;
							this.coverImageDialog = false;
							this.imageFile = null;
							this.assignedSale.cover_photo = resp.cover_photo;
							// this.changeView('default')
						})
						.catch((error) => {
							this.imageLoading = false;
						});
			}
		},

		editSale(sale) {
			this.newSale.id = sale.id;

			this.changeView("create");
		},

		save() {
			if (this.$refs.form.validate()) {
				if (this.newSale.id != null) {
					confirm("Are You Sure You Want to Update Sale") &&
					(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/sale/" + this.newSale.id,
							data: this.newSale,
							method: "PUT",
						})
								.then((resp) => {
									this.message = "Sale Updated Successfully";
									this.color = "orange";
									this.loading = false;
									this.snackbar = true;
									this.confirm = false;
									this.changeView("open");
									this.fetchOpenSales(1);
								})
								.catch((error) => {
									this.message = "An Error Occurred";
									this.color = "error";
									this.loading = false;
									this.snackbar = true;
								});
					}
				} else {
					confirm("Are You Sure You Want to Create Sale?") &&
					(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/sale",
							data: this.newSale,
							method: "POST",
						})
								.then((resp) => {
									this.message = "Sale Added Successfully";
									this.color = "success";
									this.loading = false;
									this.snackbar = true;
									this.changeView("open");
									this.fetchOpenSales(1);
								})
								.catch((error) => {
									this.message = "An Error Occurred";
									this.color = "error";
									this.loading = false;
									this.snackbar = true;
									this.fetchOpenSales(1);
								});
					}
				}
			}
		},
		searchOpenSale() {
			if (this.openSaleSearchTerm == null) {
				this.openSaleSearchButtonDisabled = true;
			} else {
				this.searchOpenSaleLoader = true;
				this.openSaleSearchButtonDisabled = false;
				this.stopOpenSaleLoader();
				apiCall({
					url: "/api/sales?type=open&typeB=search&search=" + this.openSaleSearchTerm,
					method: "GET",
				})
						.then((resp) => {
							this.filterOpenSales(resp);
							this.searchOpenSaleLoader = false;
							this.stopOpenSaleLoader();
							this.filteredOpenSales = true;
						})
						.catch((error) => {
							console.log(error.response);
							this.searchOpenSaleLoader = false;
							this.stopOpenSaleLoader();
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
						});
			}
		},
		searchClosedSale() {
			if (this.closedSaleSearchTerm == null) {
				this.closedSaleSearchButtonDisabled = true;
			} else {
				this.searchClosedSaleLoader = true;
				this.closedSaleSearchButtonDisabled = false;
				this.stopClosedSaleLoader();
				apiCall({
					url: "/api/sales?type=closed&typeB=search&search=" + this.closedSaleSearchTerm,
					method: "GET",
				})
						.then((resp) => {
							this.filterClosedSales(resp);
							this.searchClosedSaleLoader = false;
							this.stopClosedSaleLoader();
							this.filteredOpenSales = true;
						})
						.catch((error) => {
							this.searchClosedSaleLoader = false;
							this.stopClosedSaleLoader();
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							console.log(error);
						});
			}
		},
		changeRefundView(view) {
			this.refundView = view;
		},
		changeView(view) {
			if (view == "open") {
				this.newSale.id = null;
				this.newSale.sale_type_id = null
				this.newSale.title = null
				this.newSale.brief = null
				this.newSale.description = null
				this.newSale.start_date = null
				this.newSale.start_time = null
				this.newSale.end_date = null
				this.newSale.end_time = null
				this.newSale.entry_cost = null
				this.newSale.location = null
				this.newSale.longitude = null
				this.newSale.latitude = null
				this.newSale.interests = []
				this.newSale.sectors = []
				this.newSale.sdgs = []
				this.newSale.institutions = []
				this.newSale.organisations = []
			} else if (view == "show") {

			}
			this.view = view;
		},
		changeOpenSalePage() {
			this.startOpenSaleLoader();
			if (this.filteredOpenSales == true) {
				this.openSaleSearchButtonDisabled = false;
				apiCall({
					url:
							"/api/sales?type=open&page=" +
							this.openSalePagination.current_page +
							"&typeB=search&search=" +
							this.openSaleSearchTerm,
					method: "GET",
				})
						.then((resp) => {
							this.filterOpenSales(resp);
							this.stopOpenSaleLoader();
						})
						.catch((error) => {
							this.stopOpenSaleLoader();
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							console.log(error);
						});
			} else {
				this.fetchOpenSales(this.openSalePagination.current_page);
			}
		},
		changeClosedSalePage() {
			this.startClosedSaleLoader();
			if (this.filteredClosedSales == true) {
				this.closedSaleSearchButtonDisabled = false;
				apiCall({
					url:
							"/api/sales?type=closed&page=" +
							this.closedSalePagination.current_page +
							"&typeB=search&search=" +
							this.closedSaleSearchTerm,
					method: "GET",
				})
						.then((resp) => {
							this.filterClosedSales(resp);
							this.stopClosedSaleLoader();
						})
						.catch((error) => {
							this.stopClosedSaleLoader();
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							console.log(error);
						});
			} else {
				this.fetchClosedSales(this.closedSalePagination.current_page);
			}
		},
		deleteSale(item) {
			confirm("Are You Sure You Want to Delete Sale?") && (this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.saleIndex = item.id;
				apiCall({url: "/api/sales/" + item.id, method: "DELETE"})
						.then((resp) => {
							this.message = "Sale Deleted Succesfully";
							this.color = "success";
							this.snackbar = true;
							this.loading = false;
							this.delete = false;
							this.saleIndex = null;
							this.fetchOpenSales(this.openSalePagination.current_page);
						})
						.catch((error) => {
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							this.loading = false;
							this.delete = false;
							this.saleIndex = null;
							console.log(error.response);
						});
			}
		},
		activateSale(item) {
			confirm("Are You Sure You Want to Activate Sale?") &&
			(this.activate = true);
			if (this.activate) {
				this.activateLoading = true;
				this.saleIndex = item.id;
				this.newSale.id = item.id;
				apiCall({
					url: "/api/sales?type=activate",
					data: this.newSale,
					method: "POST",
				})
						.then((resp) => {
							this.message = "Sale Activated Succesfully";
							this.color = "success";
							this.snackbar = true;
							this.activateLoading = false;
							this.activate = false;
							this.saleIndex = null;
							this.fetchOpenSales(this.openSalePagination.current_page);
						})
						.catch((error) => {
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							this.activateLoading = false;
							this.activate = false;
							this.saleIndex = null;
							console.log(error.response);
						});
			}
		},
		deactivateSale(item) {
			confirm("Are You Sure You Want to Deactivate Sale?") &&
			(this.activate = true);
			if (this.activate) {
				this.deactivateLoading = true;
				this.saleIndex = item.id;
				this.newSale.id = item.id;
				apiCall({
					url: "/api/sales?type=deactivate",
					data: this.newSale,
					method: "POST",
				})
						.then((resp) => {
							this.message = "Sale Account Deactivated Succesfully";
							this.color = "success";
							this.snackbar = true;
							this.deactivateLoading = false;
							this.activate = false;
							this.saleIndex = null;
							this.assignedSale = resp
							this.fetchOpenSales(this.openSalePagination.current_page);
						})
						.catch((error) => {
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							this.deactivateLoading = false;
							this.activate = false;
							this.saleIndex = null;
							console.log(error);
						});
			}
		},
		issueSalePartStock(item) {
			confirm("Are You Sure You Want to Issue Stock Item?") &&
			(this.activate = true);
			if (this.activate) {
				this.stockLoading = true;
				this.salePartStockIndex = item.id;
				this.newSalePartStock.id = item.id;
				apiCall({
					url: "/api/sale-part-stocks?type=issue",
					data: this.newSalePartStock,
					method: "POST",
				})
						.then((resp) => {
							this.message = "Stock Item Issued Succesfully";
							this.color = "success";
							this.snackbar = true;
							this.stockLoading = false;
							this.activate = false;
							this.salePartStockIndex = null;
							this.assignedSale = resp
							this.fetchOpenSales(this.openSalePagination.current_page);
						})
						.catch((error) => {
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							this.stockLoading = false;
							this.activate = false;
							this.salePartStockIndex = null;
							console.log(error.response);
						});
			}
		},

		showRequestRefund(salePartStock) {
			this.assignedSalePartStock = salePartStock;
			this.newSalePartStock.quantity = salePartStock.quantity;
			this.changeRefundView("show");

		},
		initiateRefund() {
			confirm("Are You Sure You Want to Request a Refund on this Stock Item?") &&
			(this.activate = true);

			if (this.activate && this.assignedSalePartStock) {
				this.stockLoading = true;
				this.refundPartStockIndex = this.assignedSalePartStock.id;
				this.newSalePartStock.id = this.assignedSalePartStock.id;
				apiCall({
					url: `/api/sale-part-stocks/${this.assignedSalePartStock.id}/refund?type=requestRefund`,
					data: this.newSalePartStock,
					method: "POST",
				})
						.then((resp) => {
							this.message = "Stock Item Refunded Successfully";
							this.color = "success";
							this.snackbar = true;
							this.stockLoading = false;
							this.activate = false;
							this.refundPartStockIndex = null;
							this.assignedSale = resp
							this.changeRefundView('default');
							this.fetchOpenSales(this.openSalePagination.current_page);
						})
						.catch((error) => {
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							this.stockLoading = false;
							this.activate = false;
							this.refundPartStockIndex = null;
							console.log(error.response);
						});
			}
		},

		RequestSalePartStockRefund(item) {
			confirm("Are You Sure You Want to Request a Refund on this Stock Item?") &&
			(this.activate = true);

			if (this.activate) {
				this.stockLoading = true;
				this.refundPartStockIndex = item.id;
				this.newSalePartStock.id = item.id;
				apiCall({
					url: `/api/sale-part-stocks/${item.id}/refund?type=requestRefund`,
					data: this.newSalePartStock,
					method: "POST",
				})
						.then((resp) => {
							this.message = "Stock Item Refunded Succesfully";
							this.color = "success";
							this.snackbar = true;
							this.stockLoading = false;
							this.activate = false;
							this.refundPartStockIndex = null;
							this.assignedSale = resp
							this.fetchOpenSales(this.openSalePagination.current_page);
						})
						.catch((error) => {
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							this.stockLoading = false;
							this.activate = false;
							this.refundPartStockIndex = null;
							console.log(error.response);
						});
			}
		},

		getFormData() {
			apiCall({
				url: "/api/sales?type=formData",
				method: "GET",
			})
					.then((resp) => {
						this.formData = resp;
					})
					.catch((error) => {
						this.message = "An Error Occurred; Couldn't Get Form Data";
						this.color = "error";
					});
		},
		viewLog(data) {
			this.logData.message = data.id;
			this.logData.model = "App\\Models\\Sale"
			this.filterLogData(this.logData);
			this.openLogDataModel();
		},
	},
	computed: {
		...mapGetters([
			"openSales", "openSalePagination", "openSaleLoader",
			"closedSales", "closedSalePagination", "closedSaleLoader",
			"logDataModel"
		]),
		formatStartDate() {
			if (this.newSale.start_date != null) {
				const d = new Date(this.newSale.start_date);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		formatEndDate() {
			if (this.newSale.end_date != null) {
				const d = new Date(this.newSale.end_date);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		dateToday() {
			var today = new Date();

			var timestamp =
					today.getFullYear() +
					"-" +
					this.months[today.getMonth()] +
					"-" +
					("0" + today.getDate()).slice(-2);
			return timestamp;
		},

		openSaleLength: function () {
			return Math.ceil(
					this.openSalePagination.total / this.openSalePagination.per_page
			);
		},
		closedSaleLength: function () {
			return Math.ceil(
					this.closedSalePagination.total / this.closedSalePagination.per_page
			);
		},
	},
};
</script>
  