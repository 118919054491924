<template>
	<div class="reports" v-if="$can('report_view')">
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<div class="pa-5">
			<v-layout column>
				<v-flex xs12>
					<div class="title">Wallet Reports</div>
				</v-flex>
				<div v-if="view == 'default'">
					<v-flex xs12 class="mt-5 mb-2">
						<v-card elevation="0" class="header">
							<v-layout column class="pa-5">
								<v-flex xs12 class="mt-1">
									<v-layout row wrap class="mt-2">
										<v-flex xs12 md4>
											<div class="pa-1">
												<div>Parent/Guardian</div>
												<v-select
														:items="formData.users"
														v-model="reportData.user_id"
														class="text_field background my-2"
														item-value="id"
														item-text="first_name"
														outlined
														dense
														:rules="inputRules"
												>
													<template v-slot:selection="{ item }">
														{{ getText(item) }}
													</template>
													<template v-slot:item="{ item }">
														{{ getText(item) }}
													</template>
												</v-select>
											</div>
										</v-flex>
										<v-flex xs12 md4>
											<div class="pa-1">
												<div>Start</div>
												<v-menu :close-on-content-click="false" max-width="290"
														  transition="scale-transition"
														  v-model="fromDatePicker" offset-y>
													<template v-slot:activator="{ on, attrs }">
														<v-text-field outlined dense :value="formatFromDate" slot="activator" readonly
																		  v-bind="attrs"
																		  v-on="on" class="text_field background my-2"
																		  :rules="inputRules"></v-text-field>
													</template>
													<v-date-picker v-model="reportData.start_date"
																		:max="reportData.end_date"
																		@change="closeFromDate"></v-date-picker>
												</v-menu>
											</div>
										</v-flex>
										<v-flex xs12 md4>
											<div class="pa-1">
												<div>End</div>
												<v-menu :close-on-content-click="false" max-width="290"
														  transition="scale-transition"
														  v-model="toDatePicker" offset-y>
													<template v-slot:activator="{ on, attrs }">
														<v-text-field outlined dense :value="formatToDate" slot="activator" readonly
																		  v-bind="attrs"
																		  v-on="on" class="text_field background my-2"
																		  :rules="inputRules"></v-text-field>
													</template>
													<v-date-picker v-model="reportData.end_date" :min="reportData.start_date"
																		:max="dateToday" @change="closeToDate"></v-date-picker>
												</v-menu>
											</div>
										</v-flex>
										<v-flex xs12>
											<div class="pa-1">
												<div align="right">
													<v-btn depressed class="white--text universal text-none mt-2"
															 :loading="loading" @click="generate()">
														Show Data
														<v-icon right>mdi-cogs</v-icon>
													</v-btn>
												</div>
											</div>
										</v-flex>
									</v-layout>
								</v-flex>
							</v-layout>

						</v-card>
					</v-flex>
					<v-flex class="my-5">
						<v-layout row wrap>
							<v-flex xs12>
								<div align="right" class="mr-5">
									<v-btn depressed class="white--text pdf text-none mx-1" :loading="pdfLoading"
											 @click="pdfDownload()">
										Download PDF
										<v-icon right>mdi-file-pdf-box</v-icon>
									</v-btn>
									<v-btn depressed class="white--text excel text-none mx-1"
											 :loading="csvLoading" @click="csvDownload()">
										Download CSV
										<v-icon right>mdi-microsoft-excel</v-icon>
									</v-btn>
								</div>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex xs12 class="mt-5">
						<div v-if="reports.length == 0">
							<v-card elevation="0">
								<v-layout row wrap>
									<v-flex xs12 md1>
										<v-container fill-height fluid>
											<v-row align="center" justify="center">
												<v-col class="text-center">
													<v-icon large class="primary--text">
														mdi-alert-circle-outline
													</v-icon>
												</v-col>
											</v-row>
										</v-container>
									</v-flex>
									<v-flex xs12 md11>
										<v-container fill-height fluid>
											<v-row align="center" justify="center">
												<v-col class="text-center">
													No Wallet(s) Found
												</v-col>
											</v-row>
										</v-container>
									</v-flex>
								</v-layout>
							</v-card>
						</div>
						<div v-else>
							<div class="hidden-sm-and-down mt-3">
								<v-progress-linear v-if="loading" height="1" indeterminate color="primary">
								</v-progress-linear>
								<v-simple-table>
									<template v-slot:default>
										<thead class="header">
										<tr class="accent">
											<th class="text-left text--text">Parent/Guardian</th>
											<th class="text-right text--text">Balance</th>
											<th class="text-left text--text">Last Transaction Date</th>
											<th class="text-left text--text">Transaction No.</th>
											<th class="text-left text--text">Transaction Code</th>
											<th class="text-left text--text">Transaction Source</th>
											<th class="text-left text--text">Transaction Type</th>
										</tr>
										</thead>
										<tbody>
										<tr v-for="item in reports" :key="item.id">
											<td>
												{{ item.user.first_name }} {{ item.user.middle_name }} {{ item.user.last_name }}
											</td>
											<td>
												<div align="right">
													{{ item.amount }}
												</div>
											</td>
											<td>
												{{ item.latest_wallet_log.created_at | moment("DD/MM/YYYY - hh:mm a") }}
											</td>
											<td> {{ item.latest_wallet_log.transaction_number }}</td>
											<td> {{ item.latest_wallet_log.transaction_code }}</td>
											<td> {{ item.latest_wallet_log.wallet_transaction_source.name }}</td>
											<td> {{ item.latest_wallet_log.wallet_transaction_type.name }}</td>
										</tr>
										</tbody>
									</template>
								</v-simple-table>

							</div>
							<div class="hidden-md-and-up">
								<v-layout column>
									<template v-for="(report, index) in reports">
										<div :key="index">
											<v-flex xs12 class="mb-2">
												<v-card elevation="0" style="border-radius: 25px;" outlined>
													<div class="pa-5">
														<v-layout column>
															<v-flex xs12>
																<div class="title primary--text">
																	<b>{{ report.user.first_name }} {{ report.user.middle_name }}
																		{{ report.user.last_name }}</b>
																</div>
															</v-flex>
															<v-divider class="mt-2"></v-divider>
															<v-flex xs12 class="mt-1">
																<v-layout column>
																	<v-flex xs12 class="mt-5">
																		<v-layout row wrap>
																			<v-flex xs12 md4>
																				<b>Parent/Guardian</b>
																			</v-flex>
																			<v-flex xs12 md8>
																				{{ report.user.first_name }} {{ report.user.middle_name }}
																				{{ report.user.last_name }}
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-flex xs12 class="mt-5">
																		<v-layout row wrap>
																			<v-flex xs12 md4>
																				<b>Balance</b>
																			</v-flex>
																			<v-flex xs12 md8>
																				<div align="left">
																					{{ report.amount | formatNumber }}
																				</div>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-flex xs12 class="mt-5">
																		<v-layout row wrap>
																			<v-flex xs12 md4>
																				<b>Last Transaction Date</b>
																			</v-flex>
																			<v-flex xs12 md8>
																				{{
																					report.latest_wallet_log.created_at | moment("DD/MM/YYYY - hh:mm a")
																				}}
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-flex xs12 class="mt-5">
																		<v-layout row wrap>
																			<v-flex xs12 md4>
																				<b>Transaction No.</b>
																			</v-flex>
																			<v-flex xs12 md8>
																				{{ report.latest_wallet_log.transaction_number }}
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-flex xs12 class="mt-5">
																		<v-layout row wrap>
																			<v-flex xs12 md4>
																				<b>Transaction Code</b>
																			</v-flex>
																			<v-flex xs12 md8>
																				{{ report.latest_wallet_log.transaction_code }}
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-flex xs12 class="mt-5">
																		<v-layout row wrap>
																			<v-flex xs12 md4>
																				<b>Transaction Source</b>
																			</v-flex>
																			<v-flex xs12 md8>
																				{{
																					report.latest_wallet_log.wallet_transaction_source.name
																				}}
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-flex xs12 class="mt-5">
																		<v-layout row wrap>
																			<v-flex xs12 md4>
																				<b>Transaction Type</b>
																			</v-flex>
																			<v-flex xs12 md8>
																				{{ report.latest_wallet_log.wallet_transaction_type.name }}
																			</v-flex>
																		</v-layout>
																	</v-flex>
																</v-layout>
															</v-flex>
														</v-layout>
													</div>
												</v-card>
											</v-flex>
										</div>
									</template>
								</v-layout>
							</div>
						</div>
					</v-flex>
					<v-flex xs12 class="mt-10">
						<v-layout row wrap>
							<v-flex xs12 md9>
								<div align="left">
									<v-pagination v-if="length != 0 && length != 1" :length="length" total-visible="10"
													  v-model="reportPagination.current_page" @input="changePage()" circle>
									</v-pagination>
								</div>
							</v-flex>
						</v-layout>
					</v-flex>
				</div>
			</v-layout>
		</div>
	</div>
</template>
<script>
import Vue from 'vue'
import axios from "axios"
import apiCall from "@/utils/api";
import {mapActions, mapGetters} from "vuex";

var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0");
});

export default {
	components: {},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,
			view: "default",
			loading: false,
			pdfLoading: false,
			csvLoading: false,
			valid: true,
			message: "",
			color: "",
			delete: false,
			confirm: false,
			snackbar: false,

			fromDatePicker: false,
			toDatePicker: false,

			min: 0,
			max: 30,

			formData: null,
			reports: [],
			reportPagination: {
				search: ' ',
				current_page: 1,
				per_page: 0,
				total: 0,
				visible: 10
			},

			reportData: {
				id: null,
				user_id: null,
				student_id: null,
				part_id: null,
				supplier_id: null,
				category_id: null,
				sub_category_id: null,
				level_id: null,
				sub_level_id: null,
				learning_area_id: null,
				start_date: null,
				end_date: null,
				wallet_transaction_type_id: null,
				wallet_transaction_source_id: null
			},
			monthNames: [
				{name: "January"},
				{name: "February"},
				{name: "March"},
				{name: "April"},
				{name: "May"},
				{name: "June"},
				{name: "July"},
				{name: "August"},
				{name: "September"},
				{name: "October"},
				{name: "November"},
				{name: "December"},
			],
			months: [
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
			],

			subCategories: [],
			subLevels: [],
		};
	},
	watch: {
		toDatePicker(val) {
			val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
		}
	},
	created() {
		if (this.$can("report_view")) {
			this.getFormData();
			this.reportData.year = this.currentYear
		}
	},
	mounted() {
		this.generate()
	},
	methods: {
		...mapActions([]),

		getText(item) {
			return (
					item.first_name + " " + item.middle_name + " " + item.last_name
			);
		},
		populateSubCategories() {
			this.subCategories = []
			for (var x = 0; x < this.formData.categories.length; x++) {
				if (this.formData.categories[x].id == this.reportData.category_id) {
					this.subCategories = this.formData.categories[x].sub_categories
				}
			}
		},
		populateSubLevels() {
			this.subLevels = []
			for (var x = 0; x < this.formData.levels.length; x++) {
				if (this.formData.levels[x].id == this.reportData.level_id) {
					this.subLevels = this.formData.levels[x].sub_levels
				}
			}
		},
		resetSearch() {
			this.searchTerm = null;
			this.filteredInstitutions = true;
			this.startInstitutionLoader();
			this.fetchInstitutions(1);
		},

		generate() {
			this.loading = true;
			apiCall({
				url: "/api/reports?type=wallet",
				data: this.reportData,
				method: "POST",
			})
					.then((resp) => {
						this.reports = resp.data
						this.reportPagination.current_page = resp.current_page
						this.reportPagination.total = resp.total
						this.reportPagination.per_page = resp.per_page

						this.loading = false;
					})
					.catch((error) => {
						console.log(error.response);
						this.loading = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
		},
		csvDownload() {
			this.csvLoading = true;
			this.reportData.disabilities = this.disabilities
			apiCall({
				url: "/api/reports?type=walletCSV",
				data: this.reportData,
				method: "POST",
			})
					.then((resp) => {
						this.csvLoading = false;
						this.downloadFile(resp);
					})
					.catch((error) => {
						console.log(error.response);
						this.csvLoading = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
		},
		pdfDownload() {
			this.pdfLoading = true;

			axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token');
			axios({
				url: this.path + "/api/reports?type=walletPDF",
				data: this.reportData,
				method: "POST",
				responseType: "blob",
			})
					.then((resp) => {
						this.downloadPDFFile(resp, 'Wallets');
						this.pdfLoading = false;
					})
					.catch((error) => {
						this.pdfLoading = false;
					});
		},
		downloadFile(response, filename) {
			// It is necessary to create a new blob object with mime-type explicitly set
			// otherwise only Chrome works like it should
			var newBlob = new Blob([response], {type: "application/csv"});

			// IE doesn't allow using a blob object directly as link href
			// instead it is necessary to use msSaveOrOpenBlob
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}

			// For other browsers:
			// Create a link pointing to the ObjectURL containing the blob.
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement("a");
			link.href = data;
			link.download = 'Wallets ' + this.dateToday + ".csv";
			link.click();
			setTimeout(function () {
				// For Firefox it is necessary to delay revoking the ObjectURL
				window.URL.revokeObjectURL(data);
			}, 100);
		},
		downloadPDFFile(response, filename) {
			// It is necessary to create a new blob object with mime-type explicitly set
			// otherwise only Chrome works like it should
			console.log('response.data', response.data)
			var newBlob = new Blob([response.data], {type: "application/pdf"});

			// IE doesn't allow using a blob object directly as link href
			// instead it is necessary to use msSaveOrOpenBlob
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}

			// For other browsers:
			// Create a link pointing to the ObjectURL containing the blob.
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement("a");
			link.href = data;
			link.download = filename + ' ' + this.dateToday + ".pdf";
			link.click();
			setTimeout(function () {
				// For Firefox it is necessary to delay revoking the ObjectURL
				window.URL.revokeObjectURL(data);
			}, 100);
		},
		changePage() {
			this.loading = true;
			apiCall({
				url: "/api/reports?type=wallet&page=" + this.reportPagination.current_page,
				data: this.reportData,
				method: "POST",
			})
					.then((resp) => {
						this.reports = resp.data
						this.reportPagination.current_page = resp.current_page
						this.reportPagination.total = resp.total
						this.reportPagination.per_page = resp.per_page

						this.loading = false;
					})
					.catch((error) => {
						console.log(error.response);
						this.loading = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
		},
		getFormData() {
			apiCall({
				url: "/api/reports?type=formData",
				method: "GET",
			})
					.then((resp) => {
						this.formData = resp;
					})
					.catch((error) => {
						this.message = "anErrorOccurredCouldntGetFormDataMsg";
						this.color = "error";
					});
		},

		closeFromDate() {
			this.fromDatePicker = false;
		},
		closeToDate() {
			this.toDatePicker = false;
		},
		closeYearDate(date) {
			this.$refs.yearMenu.save(date);
			this.$refs.picker.activePicker = 'YEAR'
			this.toDatePicker = false;
		},

	},
	computed: {
		...mapGetters([]),
		length: function () {
			return Math.ceil(
					this.reportPagination.total / this.reportPagination.per_page
			);
		},
		currentYear() {
			var today = new Date();

			var timestamp = today.getFullYear()

			return timestamp;
		},
		dateToday() {
			var today = new Date();

			var timestamp =
					today.getFullYear() +
					"-" +
					this.months[today.getMonth()] +
					"-" +
					("0" + today.getDate()).slice(-2);
			return timestamp;
		},
		formatFromDate() {
			if (this.reportData.start_date != null) {
				const d = new Date(this.reportData.start_date);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		formatToDate() {
			if (this.reportData.end_date != null) {
				const d = new Date(this.reportData.end_date);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		formatYear() {
			if (this.newResource.year != null) {
				const d = new Date(this.newResource.year);
				const year = d.getFullYear();

				this.newResource.year = year

				return year;
			}
		},
	},
};
</script>
