import apiCall from '@/utils/api'

const state = {
	walletLogs: [],
	allWalletLogs: [],
    walletLogPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    walletLogLoader: false
};

const getters = {
	walletLogs: (state) => state.walletLogs,
	allWalletLogs: (state) => state.allWalletLogs,
    walletLogLoader: (state) => state.walletLogLoader,
    walletLogPagination: (state) => state.walletLogPagination
}

const actions = {
	async fetchAllWalletLogs({commit}, page) {
		const response = await apiCall({url: `/api/wallet-log?type=all`, method: 'GET' });
		commit('setAllWalletLogs', response)
        commit('stopLoader', response)
	},
    async fetchWalletLogs({commit}, page) {
		const response = await apiCall({url: `/api/wallet-log?type=individual&page=${page}`, method: 'GET' });
		commit('setWalletLogs', response)
        commit('stopLoader', response)
	},
    async filterWalletLogs({commit, state},resp){
		commit('setWalletLogs', resp)
	},
	async startWalletLogLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopWalletLogLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setWalletLogs: (state, walletLogs) => {
		state.walletLogs = walletLogs.data
		state.walletLogPagination.current_page = walletLogs.current_page
		state.walletLogPagination.total = walletLogs.total
		state.walletLogPagination.per_page = walletLogs.per_page
	},
	setAllWalletLogs: (state, walletLogs) => {
		state.allWalletLogs = walletLogs
	},
    startLoader: (state) => state.walletLogLoader = true,
	stopLoader: (state) => state.walletLogLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
