<template>
  <div class="welcome">
    <v-layout column>
      <v-flex xs12>
        <v-img
            dark
            height="650"
            src="img/home.jpg"
          >
            <v-row
              align="center"
              justify="center"
              class="mt-16"
            >
              <v-col
                class="text-center mt-16"
                cols="12"
              >
                <div class="display-4 font-weight-black mt-16 mb-4 hidden-sm-and-down">
                  Strathmore School
                </div>
                <h4 class="display-2 secondary--text hidden-sm-and-down">
                  Bookshop
                </h4>
                <div class="display-2 font-weight-black mt-16 mb-4 hidden-md-and-up">
                  Strathmore School
                </div>
                <h4 class="title secondary--text hidden-md-and-up">
                  Bookshop
                </h4>
              </v-col>
            </v-row>
          </v-img>
      </v-flex>
      <v-flex xs12 class="accent">
        <div align="center" class="my-5 display-1 font-weight-black white--text">
          <!-- <v-icon class="mb-4 white--text" large>mdi-format-quote-open</v-icon><i class="white--text"> -->
            Thank you for Supporting the Bursary Fund!
          <!-- </i><v-icon class="mb-4 white--text" large>mdi-format-quote-close</v-icon> -->
        </div>
      </v-flex>
      <v-flex xs12 class="my-16">
        <v-container class="my-16">
          <v-layout row wrap>
            <v-flex xs12 md4>
              <v-card elevation="0" class="my-16 pa-16 mx-4" style="border-radius: 25px;" outlined>
                <v-layout column>
                  <v-flex xs12>
                    <div align="center">
                      <v-icon size="150px" class="primary--text">mdi-web</v-icon>
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <div align="center" class="display-1 mt-5">
                      Shop Online
                    </div>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 md4>
              <v-card elevation="0" class="my-16 pa-16 mx-4" style="border-radius: 25px;" outlined>
                <v-layout column>
                  <v-flex xs12>
                    <div align="center">
                      <v-icon size="150px" class="accent--text">mdi-cellphone-sound</v-icon>
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <div align="center" class="display-1 mt-5">
                      Mobile Payment
                    </div>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 md4>
              <v-card elevation="0" class="my-16 pa-16 mx-4" style="border-radius: 25px;" outlined>
                <v-layout column>
                  <v-flex xs12>
                    <div align="center">
                      <v-icon size="150px" class="secondary--text">mdi-package-check</v-icon>
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <div align="center" class="display-1 mt-5">
                      In-Store Pickup
                    </div>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>

          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <AuthPopUp v-model="loginCardState" />
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import AuthPopUp from "@/components/auth/AuthPopUp";
import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";
import VClamp from "vue-clamp";

export default {
  components: {
    AuthPopUp,
    VClamp,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      drawer: false,
      showMap: true,

    };
  },
  watch: {
    darkState() {
      
    },
  },
  mounted() {
    
  },
  created() {
    window.scrollTo(0, 0);
    
  },
  methods: {
    ...mapActions(["changeLoginCardState"]),
  },
  computed: {
    ...mapGetters(["loginCardState", "darkState", "isAuthenticated"]),
  },
};
</script>
