import apiCall from '@/utils/api'

const state = {
	permissions: [],
    permissionPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    permissionLoader: false
};

const getters = {
    permissionLoader: (state) => state.permissionLoader,
	permissions: (state) => state.permissions,
    permissionPagination: (state) => state.permissionPagination
}

const actions = {
    async fetchPermissions({commit}, page) {
		const response = await apiCall({url: `/api/permission?page=${page}`, method: 'GET' });
		commit('setPermissions', response)
        commit('stopLoader', response)
	},
    async filterPermissions({commit, state},resp){
		commit('setPermissions', resp)
	},
	async startpermissionLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stoppermissionLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setPermissions: (state, permissions) => {
		state.permissions = permissions.data
		state.permissionPagination.current_page = permissions.current_page
		state.permissionPagination.total = permissions.total
		state.permissionPagination.per_page = permissions.per_page
	},
    startLoader: (state) => state.permissionLoader = true,
	stopLoader: (state) => state.permissionLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
