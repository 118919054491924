import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        //dark: true,
        themes: {
            light: {
                primary: '#28166F',
                secondary: '#DA251D',
                accent: '#EBA750',
                error: '#b71c1c',
                background: "#F7F7F7",
                header: "#fff",
                button: "#EEEEEE",
                universal: '#1e85f1',
                nav: '#1e85f1',
                excel: '#1D6F42',
                pdf: "#f40f02",
                text: '#28166F',
                tableHeader: '#DA251D',
                
            },
            dark: {
                primary: '#28166F',
                secondary: '#DA251D',
                accent: '#EBA750',
                error: '#b71c1c',
                background: "#121212",
                header: "#272727",
                button: "292929",
                universal: '#1e85f1',
                nav: '#0f1e3d',
                excel: '#1D6F42',
                pdf: "f40f02",
                text: '#DA251D',
                tableHeader: '#121212',
            },
        },
    },
});
