import apiCall from '@/utils/api'

const state = {
	systemModels: [],
    logData: {
		message: null,
		model: null,
	},
    logDataModel: false,
    logDataLoader: false
};

const getters = {
    logDataLoader: (state) => state.logDataLoader,
    logDataModel: (state) => state.logDataModel,
    logData: (state) => state.logData,
	systemModels: (state) => state.systemModels,
}

const actions = {
    async filterLogData({commit},resp){
		commit('setLogData', resp)
	},
	async filterModels({commit},resp){
		commit('setModels', resp)
	},
	async startLogDataLoader({commit},resp){
		commit('startLoader', resp)
	},
	async stopLogDataLoader({commit},resp){
		commit('stopLoader', resp)
	},
    async openLogDataModel({commit},resp){
		commit('openModel', resp)
	},
	async closeLogDataModel({commit},resp){
		commit('closeModel', resp)
	},
};

const mutations = {
	setLogData: (state, logDatas) => {
		state.logData.message = logDatas.message
		state.logData.model = logDatas.model
	},
	setModels: (state, models) => state.systemModels = models,
    startLoader: (state) => state.logDataLoader = true,
	stopLoader: (state) => state.logDataLoader = false,
    openModel: (state) => state.logDataModel = true,
	closeModel: (state) => state.logDataModel = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
