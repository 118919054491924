<template>
  <div class="welcome">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-progress-linear v-if="searchPartLoader" height="1" indeterminate color="primary">
    </v-progress-linear>

    <div class="pa-5 mt-10">
      <v-layout row wrap>
        <v-flex xs12 md9>
          <div class="pa-3" style="
                margin: 1px, 1px;
                padding: 1px;
                width: auto;
                height: 84vh;
                overflow-x: hidden;
                overflow-y: scroll;
                text-align: justify;
              ">
            <v-layout column>
              <div v-if="searchParts.length != 0">
                <template v-for="(part, index) in searchParts">
                  <div :key="index">
                    <v-flex xs12 :key="index">

                      <v-card elevation="0" class="mb-2" style="border-radius: 25px;" outlined>
                        <v-card-text>
                          <v-layout row wrap>
                            <v-flex xs12 md6>
                              <v-img v-if="part.image == null" class="background" contain style="border-radius: 25px;"
                                max-height="180" src="img/book-silhouette-vector-14.png">

                              </v-img>
                              <v-img max-height="180" contain v-else :src="path + '/storage/part_pictures/' + part.image"
                                style="border-radius: 25px;" class="background">

                              </v-img>
                            </v-flex>
                            <!-- <v-flex xs1>
                              <v-divider vertical></v-divider>
                            </v-flex> -->
                            <v-flex xs12 md6>
                              <v-layout column>
                                <v-flex xs12 class="mt-5">
                                  <div class="title primary--text ml-5">
                                    <v-layout row wrap>
                                      <v-flex xs9>
                                        <div class="ml-3">
                                          {{ part.name }}
                                        </div>
                                      </v-flex>
                                      <v-flex xs3>
                                        <div align="right" class="mr-8 green--text">
                                          Kshs {{ part.price }}
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </div>
                                </v-flex>
                                <v-divider class="mt-3 mr-5 ml-5"></v-divider>
                                <v-flex xs12>
                                  <div class="pa-8">
                                    <v-layout row wrap>
                                      <v-flex xs12>
                                        {{ part.brief }}
                                      </v-flex>
                                    </v-layout>

                                    <v-layout row wrap class="mt-5" v-if="part.category_parts.length!=0">
                                      <v-flex xs12 md3>
                                        <b>Category:</b>
                                      </v-flex>
                                      <v-flex xs12 md9>
                                        <template v-for="(category_part, index) in part.category_parts">
                                          {{ category_part.category.name }}({{ category_part.sub_category.name }}), 
                                        </template>
                                      </v-flex>
                                    </v-layout>
                                    <v-layout row wrap class="mt-5" v-if="part.level_parts.length!=0">
                                      <v-flex xs12 md3>
                                        <b>Level:</b>
                                      </v-flex>
                                      <v-flex xs12 md9>
                                        <template v-for="(level_part, index) in part.level_parts">
                                          {{ level_part.level.name }}({{ level_part.sub_level.name }}), 
                                        </template>
                                      </v-flex>
                                    </v-layout>
                                    <v-layout row wrap class="mt-5" v-if="part.level_parts.length!=0">
                                      <v-flex xs12 md3>
                                        <b>Learning Area:</b>
                                      </v-flex>
                                      <v-flex xs12 md9>
                                        <template v-for="(learning_area_part, index) in part.learning_area_parts">
                                          {{ learning_area_part.learning_area.name }}, 
                                        </template>
                                      </v-flex>
                                    </v-layout>
                                  </div>
                                </v-flex>
                                <v-flex xs12>
                                  <div align="center" class="mt-1 red--text" v-if="part.quantity==0">
                                      Out of Stock
                                  </div>
                                </v-flex>
                                <v-flex xs12>
                                  <div class="mr-8 mb-5 ml-9 mt-5">
                                    <v-layout row wrap>
                                      <v-flex xs6>
                                        <div align="left">

                                          <router-link :to="'/product/' + part.slug
                                            " style="
                                                text-decoration: none !important;
                                            ">
                                            <v-btn text large rounded block class="accent--text text-none mb-1">
                                              View Details
                                            </v-btn>
                                          </router-link>
                                        </div>
                                      </v-flex>
                                      <v-flex xs6>
                                        <div align="right">
                                          <v-btn v-if="part.quantity!=0" large rounded block text class="secondary--text text-none"
                                                @click="addToCart(part)" :loading="loading && cartIndex == part.id
                                                    ">
                                                Add to Cart
                                                <v-icon small right>
                                                    mdi-cart
                                                </v-icon>
                                            </v-btn>
                                            <v-btn v-if="part.quantity==0" large disabled rounded block text class="secondary--text text-none">
                                                Add to Cart
                                                <v-icon small right>
                                                    mdi-cart
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </div>
                                </v-flex>

                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card-text>

                      </v-card>

                    </v-flex>
                  </div>
                </template>
              </div>
              <div v-else>
                <v-flex xs12 class="mb-10">
                  <a style="text-decoration: none">
                    <v-card elevation="0" style="border-radius: 25px">
                      <v-layout row wrap>
                        <v-flex xs12 md4>
                          <div align="center" class="my-10">
                            <v-avatar color="primary" size="96">
                              <v-icon class="white--text">
                                mdi-file-document-alert
                              </v-icon>
                            </v-avatar>
                          </div>
                        </v-flex>
                        <v-flex xs12 md8>
                          <v-layout column>
                            <v-flex xs12 class="mt-1">
                              <div class="mt-15 ml-5 title">
                                No Product Found..
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </a>
                </v-flex>
              </div>
            </v-layout>
          </div>
          <div class="mx-5 my-5">
            <div align="center">
              <v-pagination v-if="searchPartLength != 0" :length="searchPartLength" total-visible="10"
                v-model="searchPartPagination.current_page" @input="changePartPage()" circle>
              </v-pagination>
            </div>
          </div>
        </v-flex>
        <v-flex xs12 md3>
          <div v-if="formData!=null">
            <v-layout column>
              <v-flex xs12>
                  <div class="mx-3 mt-3">
                      <div>Learning Area</div>
                      <v-select :items="formData.learningAreas" v-model="filter.learning_area_id"
                          class="text_field background my-2" item-value="id" item-text="name"
                          outlined dense :rules="inputRules"></v-select>
                  </div>
              </v-flex>
              <v-flex xs12>
                <div class="mx-3">
                <div>Category</div>
                <v-select
                    :items="formData.categories"
                    v-model="filter.category_id"
                    class="text_field background my-2"
                    item-value="id"
                    item-text="name"
                    outlined
                    dense
                    :rules="inputRules"
                    @input="populateSubCategories"
                ></v-select>
                </div>
            </v-flex>
            <v-flex xs12>
                <div class="mx-3">
                <div>Sub-Category</div>
                <v-select
                    :items="subCategories"
                    v-model="filter.sub_category_id"
                    class="text_field background my-2"
                    item-value="id"
                    item-text="name"
                    outlined
                    dense
                    :rules="inputRules"
                ></v-select>
                </div>
            </v-flex>
            <v-flex xs12>
                <div class="mx-3">
                <div>Level</div>
                <v-select
                    :items="formData.levels"
                    v-model="filter.level_id"
                    class="text_field background my-2"
                    item-value="id"
                    item-text="name"
                    outlined
                    dense
                    :rules="inputRules"
                    @input="populateSubLevels"
                ></v-select>
                </div>
            </v-flex>
            <v-flex xs12>
                <div class="mx-3">
                <div>Sub-Level</div>
                <v-select
                    :items="subLevels"
                    v-model="filter.sub_level_id"
                    class="text_field background my-2"
                    item-value="id"
                    item-text="name"
                    outlined
                    dense
                    :rules="inputRules"
                ></v-select>
                </div>
            </v-flex>
            <v-flex xs12>
              <div class="mx-3">
                <v-btn block depressed class="primary white--text text-none mt-5" @click="filterSearch()" :loading="filterLoader">
                  Filter
                  <v-icon right>mdi-filter-variant</v-icon>
                </v-btn>
              </div>
            </v-flex>
            <v-flex xs12>
              <div class="mx-3">
                <v-btn block depressed class="secondary white--text text-none mt-2" @click="resetSearch()" :loading="resetSearchLoader">
                  Reset
                  <v-icon right>mdi-undo</v-icon>
                </v-btn>
              </div>
            </v-flex>
            </v-layout>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import VClamp from "vue-clamp";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    VClamp,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      loading: false,
      filterLoader: false,
      dialog: false,
      resetSearchLoader: false,
      cartIndex: null,

      inputRules: [(v) => !!v || "Input is required"],

      filter: {
        term: null,
        learning_area_id: null,
        category_id: null,
        sub_category_id: null,
        level_id: null,
        sub_level_id: null,
      },

      formData: null,

      searchData: {
        term: null
      },

      subCategories: [],
      subLevels: [],
    };
  },
  watch: {
    searchParts() {

    },
  },
  mounted() { },
  created() {
    window.scrollTo(0, 0);
    this.getFormData();
  },
  methods: {
    ...mapActions([
      "startSearchPartLoader",
      "stopSearchPartLoader",
      "filterSearchParts",
      "fetchCarts",
    ]),
    populateSubCategories(){
      this.subCategories = []
      for(var x=0; x<this.formData.categories.length; x++){
        if(this.formData.categories[x].id==this.filter.category_id){
          this.subCategories = this.formData.categories[x].sub_categories
        }
      }
    },
    populateSubLevels(){
      this.subLevels = []
      for(var x=0; x<this.formData.levels.length; x++){
        if(this.formData.levels[x].id==this.filter.level_id){
          this.subLevels = this.formData.levels[x].sub_levels
        }
      }
    },

    getFormData(){
      apiCall({
        url: "/api/part?type=publicFormData",
        method: "GET",
      })
        .then((resp) => {
            this.formData = resp;
        })
        .catch((error) => {
            this.message = "anErrorOccurredCouldntGetFormDataMsg";
            this.color = "error";
        });
    },
    changePartPage() {
      this.startSearchPartLoader();
      this.searchData.term = this.searchPartPagination.search

      apiCall({
        url:
          "/api/part?type=publicSearch&page=" +
          this.searchPartPagination.current_page,
        data: this.searchData,
        method: "POST",
      })
        .then((resp) => {
          this.filterSearchParts(resp);
          this.stopSearchPartLoader();
          this.resetSearchLoader = false
        })
        .catch((error) => {
          this.stopSearchPartLoader();
          this.resetSearchLoader = false
          this.message = "anErrorOccurredMsg";
          this.color = "error";
          this.snackbar = true;
        });
    },
    addToCart(item) {
      this.loading = true;
      this.cartIndex = item.id;
      apiCall({
        url: "/api/cart",
        data: item,
        method: "POST",
      })
        .then((resp) => {
          if (resp.status == 200) {
            this.message = resp.message;
            this.color = "success";
            this.loading = false;
            this.snackbar = true;

            this.changePartPage();
            this.fetchCarts(1)
          } else if (resp.status == 409) {
            this.message = resp.message;
            this.color = "orange";
            this.loading = false;
            this.snackbar = true;
          }
        })
        .catch((error) => {
          this.message = "An Error Occurred";
          this.color = "error";
          this.loading = false;
          this.snackbar = true;
        });
    },
    goToPart(part) {
      this.$router.push("/product/" + part.slug);
    },
    filterSearch(){
      this.filterLoader = true
      this.filter.term = this.searchPartPagination.search

      apiCall({
        url:
          "/api/part?type=filterPublicSearch&page=" +
          this.searchPartPagination.current_page,
        data: this.filter,
        method: "POST",
      })
        .then((resp) => {
          this.filterSearchParts(resp);
          this.filterLoader = false
        })
        .catch((error) => {
          console.log(error.response);
          this.filterLoader = false
          this.message = "anErrorOccurredMsg";
          this.color = "error";
          this.snackbar = true;
        });
    },
    resetSearch(){
      this.resetSearchLoader = true
      this.filter.term = null,
      this.filter.learning_area_id = null,
      this.filter.category_id = null,
      this.filter.sub_category_id = null,
      this.filter.level_id = null,
      this.filter.sub_level_id = null,

      this.changePartPage()
    }

  },
  computed: {
    ...mapGetters([
      "searchParts",
      "searchPartLoader",
      "searchPartPagination",
    ]),

    searchPartLength: function () {
      return Math.ceil(
        this.searchPartPagination.total /
        this.searchPartPagination.per_page
      );
    },
  },
};
</script>
