import apiCall from '@/utils/api'

const state = {
	roles: [],
	publicRoles: [],
    rolePagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    roleLoader: false
};

const getters = {
    roleLoader: (state) => state.roleLoader,
	roles: (state) => state.roles,
	publicRoles: (state) => state.publicRoles,
    rolePagination: (state) => state.rolePagination
}

const actions = {
    async fetchRoles({commit}, page) {
		const response = await apiCall({url: `/api/role?page=${page}`, method: 'GET' });
		commit('setRoles', response)
        commit('stopLoader', response)
	},
	async fetchPublicRoles({commit}, page) {
		const response = await apiCall({url: `/api/role?type=public`, method: 'GET' });
		commit('setPublicRoles', response)
        commit('stopLoader', response)
	},
    async filterRoles({commit, state},resp){
		commit('setRoles', resp)
	},
	async startRoleLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopRoleLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setRoles: (state, roles) => {
		state.roles = roles.data
		state.rolePagination.current_page = roles.current_page
		state.rolePagination.total = roles.total
		state.rolePagination.per_page = roles.per_page
	},
	setPublicRoles: (state, roles) => { state.publicRoles = roles },
    startLoader: (state) => state.roleLoader = true,
	stopLoader: (state) => state.roleLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
