import apiCall from '@/utils/api'

const state = {
	logs: [],
    logPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    logLoader: false
};

const getters = {
    logLoader: (state) => state.logLoader,
	logs: (state) => state.logs,
    logPagination: (state) => state.logPagination
}

const actions = {
    async fetchLogs({commit}, page) {
		const response = await apiCall({url: `/api/log?page=${page}`, method: 'GET' });
		commit('setLogs', response)
        commit('stopLoader', response)
	},
    async filterLogs({commit, state},resp){
		commit('setLogs', resp)
	},
	async startLogLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopLogLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setLogs: (state, logs) => {
		state.logs = logs.data
		state.logPagination.current_page = logs.current_page
		state.logPagination.total = logs.total
		state.logPagination.per_page = logs.per_page
	},
    startLoader: (state) => state.logLoader = true,
	stopLoader: (state) => state.logLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
