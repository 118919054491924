<template>
  <div class="roles" v-if="$can('role_view')">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <div class="pa-5">
      <v-layout column>
        <div v-if="view == 'default'">
          <v-card elevation="0" class="mt-5">
            <v-flex xs12 class="mt-10 mb-2">
              <div class="mx-5">
                <v-layout row wrap>
                  <v-flex xs12 md6> 
                    <div v-if="length != 0" align="left" class="mt-1">
                      <b class="display-1 primary--text">Total: </b>{{ rolePagination.total | formatNumber }}
                    </div>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-text-field
                        class="text_field background"
                        outlined
                        dense
                        rounded
                        label="Search"
                        append-icon="mdi-undo-variant"
                        @click:append="resetSearch()"
                        v-on:keyup.enter="search"
                        v-model="searchTerm"
                        @input="enableSearch()"
                      >
                      </v-text-field>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2>
                    <div class="pa-1" align="right">
                      <v-btn
                        class="button mx-1"
                        icon
                        @click="search"
                        :loading="searchLoader"
                        :disabled="searchButtonDisabled"
                      >
                        <v-icon class="secondary--text">mdi-magnify</v-icon>
                      </v-btn>

                      <v-btn
                        icon
                        class="button mx-1"
                        @click="changeView('create')"
                        v-if="$can('role_create')"
                      >
                        <v-icon class="primary--text"> mdi-plus-circle-outline </v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
            <v-divider class="mt-9 mx-5"></v-divider>
            <v-progress-linear
              v-if="roleLoader"
              height="1"
              indeterminate
              color="primary"
            >
            </v-progress-linear>
            <v-flex xs12 class="mt-5">
              <div v-if="roles.length == 0">
                <v-card elevation="0">
                  <v-layout row wrap>
                    <v-flex xs12 md1>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            <v-icon large class="primary--text">
                              mdi-alert-circle-outline
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                    <v-flex xs12 md11>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center"> No Role Found </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                  </v-layout>
                </v-card>
              </div>
              <div v-else>
                <div class="hidden-sm-and-down">
                  <v-card elevation="0" class="pa-2">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr class="accent">
                            <th class="text-left text--text">Role Name</th>
                            <th class="text-left text--text">Role Guard</th>
                            <th class="text-left text--text">Permissions</th>
                            <th class="text-left text--text">Public</th>
                            <th class="text-right text--text">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in roles" :key="item.id">
                            <td>
                              <div
                                v-if="highLightId == item.id"
                                class="green--text"
                              >
                                {{ item.name }}
                              </div>
                              <div v-else>
                                {{ item.name }}
                              </div>
                            </td>
                            <td>
                              <div
                                v-if="highLightId == item.id"
                                class="green--text"
                              >
                                {{ item.guard_name }}
                              </div>
                              <div v-else>
                                {{ item.guard_name }}
                              </div>
                            </td>
                            <td>
                              <div
                                v-if="highLightId == item.id"
                                class="green--text"
                              >
                                {{ item.permissions.length }}
                              </div>
                              <div v-else>
                                {{ item.permissions.length }}
                              </div>
                            </td>
                            <td>
                              <div
                                v-if="highLightId == item.id"
                                class="green--text"
                              >
                                <div v-if="item.public">Yes</div>
                                <div v-else>No</div>
                              </div>
                              <div v-else>
                                <div v-if="item.public">Yes</div>
                                <div v-else>No</div>
                              </div>
                            </td>
                            <td>
                              <div align="right">
                                <v-tooltip top v-if="$can('role_view')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      @click="showRole(item)"
                                      class="button mr-1 blue--text"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small> mdi-eye </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>view</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('role_edit')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      @click="editRole(item)"
                                      class="button mr-1 success--text"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small> mdi-fountain-pen-tip </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>edit</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('role_archive')  && (item.name != 'System Admin')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      @click="deleteRole(item)"
                                      :loading="loading && roleIndex == item.id"
                                      class="button error--text"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small> mdi-trash-can </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>delete</span>
                                </v-tooltip>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </div>
                <div class="hidden-md-and-up">
                  <v-layout column>
                    <template v-for="(role, index) in roles">
                      <div :key="index">
                        <v-flex xs12 class="mb-2">
                          <v-card elevation="0" style="border-radius: 25px;" outlined>
                            <div class="pa-5">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout row wrap>
                                    <v-flex xs11>
                                      <div class="title primary--text ml-3">
                                        <b>{{ role.name }}</b>
                                      </div>
                                    </v-flex>
                                    <v-flex xs1>
                                      <v-btn
                                      v-if="$can('role_archive')  && (role.name != 'System Admin') && (role.name != 'Institution Admin') && (role.name != 'Organisation Admin') && (role.name != 'Investment Firm Admin') && (role.name != 'Innovation Admin') && (role.name != 'Innovator') && (role.name != 'Investor')"
                                        depressed
                                        class="red--text"
                                        :loading="
                                          loading && roleIndex == role.id
                                        "
                                        icon
                                        @click="deleteRole(role)"
                                      >
                                        <v-icon> mdi-delete </v-icon>
                                      </v-btn>
                                      <v-btn
                                      v-if="$can('role_archive') && ((role.name == 'System Admin') || (role.name == 'Institution Admin') || (role.name == 'Organisation Admin') || (role.name == 'Investment Firm Admin') || (role.name == 'Innovation Admin') || (role.name == 'Innovator') || (role.name == 'Investor'))"
                                        
                                        class="red--text"
                                        disabled
                                        icon
                                        @click="deleteRole(role)"
                                      >
                                        <v-icon> mdi-delete </v-icon>
                                      </v-btn>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-divider class="mt-2"></v-divider>
                                <v-flex xs12 class="mt-1">
                                  <v-layout column>
                                    <v-flex xs12 class="mt-1">
                                      <div><b>Role Guard</b></div>
                                      <div>{{ role.guard_name }}</div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div><b>Permissions</b></div>
                                      <div>{{ role.permissions.length }}</div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-5">
                                      <v-layout row wrap>
                                        <v-flex xs12 v-if="$can('role_view')">
                                          <div class="pa-1">
                                            <v-btn
                                              rounded
                                              depressed
                                              class="primary text-none white--text"
                                              block
                                              @click="showRole(role)"
                                              >View
                                              <v-icon right> mdi-eye </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12 v-if="$can('role_edit')">
                                          <div class="pa-1">
                                            <v-btn
                                              rounded
                                              depressed
                                              class="blue darken-1 text-none white--text"
                                              block
                                              @click="editRole(role)"
                                              >Edit
                                              <v-icon right>
                                                mdi-border-color
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-card>
                        </v-flex>
                      </div>
                    </template>
                  </v-layout>
                </div>
              </div>
            </v-flex>
          </v-card>

          <v-layout column class="mt-5">
            <v-flex xs12>
              <div align="center">
                <v-pagination
                  v-if="length != 0"
                  :length="length"
                  total-visible="10"
                  v-model="rolePagination.current_page"
                  @input="changePage()"
                  circle
                >
                </v-pagination>
              </div>
            </v-flex>
            
          </v-layout>
        </div>
        <div v-if="view == 'create'">
          <v-flex class="mt-5">
            <v-card outlined>
              <v-card-title class="accent text--text">
                <v-spacer></v-spacer>
                <div v-if="newRole.id == null">Add Role</div>
                <div v-else>Edit Role</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon class="text--text"> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-layout row wrap class="mt-5">
                    <v-flex xs12 md5>
                      <div class="pa-3">
                        <div>Role Name</div>
                        <div v-if="newRole.name != 'System Admin' && newRole.name != 'Institution Admin' && newRole.name != 'Organisation Admin' && newRole.name != 'Investment Firm Admin' && newRole.name != 'Innovation Admin' && newRole.name != 'Investor' && newRole.name != 'Innovator'">
                          <v-text-field
                            class="text_field background"
                            outlined
                            dense
                            v-model="newRole.name"
                            :rules="inputRules"
                          ></v-text-field>
                        </div>
                        <div v-else>
                          <v-text-field
                            class="text_field background"
                            outlined
                            dense
                            v-model="newRole.name"
                            :rules="inputRules"
                            disabled
                          ></v-text-field>
                        </div>
                      </div>
                    </v-flex>
                    <v-flex xs12 md2>
                      
                    </v-flex>
                    <v-flex xs12 md5>
                      <div class="pa-3">
                        <div>Guard Name</div>
                        <div v-if="newRole.name != 'System Admin' && newRole.name != 'Institution Admin' && newRole.name != 'Organisation Admin' && newRole.name != 'Innovation Admin'">
                          <v-select
                            :items="uniqueGuards"
                            v-model="newRole.guard_name"
                            class="text_field background"
                            item-value="name"
                            item-text="name"
                            outlined
                            dense
                            :rules="inputRules"
                          ></v-select>
                        </div>
                        <div v-else>
                          <v-select
                            :items="uniqueGuards"
                            v-model="newRole.guard_name"
                            class="text_field background"
                            item-value="name"
                            item-text="name"
                            outlined
                            dense
                            :rules="inputRules"
                            disabled
                          ></v-select>
                        </div>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <div class="pa-5">
                        <v-layout row wrap>
                          <v-flex xs12 md5>
                            <v-layout column>
                              <v-flex xs12 class="my-2">
                                <v-layout row wrap>
                                  <v-flex xs12>
                                    <div align="center" class="mt-5">
                                      <b> Available Permissions</b>
                                    </div>
                                  </v-flex>

                                  <v-flex xs12>
                                    <div class="pa-1 mx-5">
                                      <v-text-field
                                        class="text_field header"
                                        outlined
                                        dense
                                        label="Search"
                                        v-on:keyup.enter="
                                          searchAvailablePermissions
                                        "
                                        v-model="searchAvailablePermissionTerm"
                                        append-icon="mdi-undo-variant"
                                        @click:append="resetAvailable()"
                                        @input="
                                          enableAvailablePermissionSearch()
                                        "
                                      >
                                      </v-text-field>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12>
                                    <div class="pa-1 mx-5" align="center">
                                      <v-btn
                                        class="accent text-none primary--text"
                                        depressed
                                        block
                                        @click="searchAvailablePermissions"
                                        :loading="searchPermissionLoader"
                                        :disabled="
                                          availablePermissionSearchButtonDisabled
                                        "
                                      >
                                        Search
                                        <v-icon right>mdi-magnify</v-icon>
                                      </v-btn>
                                    </div>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 class="mt-5">
                                <!-- <div><b>selected: </b>{{assignedPermissions}}</div> -->
                                <!-- <v-progress-linear class="mb-1" v-if="permissionLoader"
                                                                  height="1" indeterminate color="primary">
                                                              </v-progress-linear> -->
                                <v-layout column>
                                  <div
                                    class="mx-2"
                                    style="
                                      margin: 1px, 1px;
                                      padding: 1px;
                                      width: auto;
                                      height: 86vh;
                                      overflow-x: hidden;
                                      overflow-y: scroll;
                                      text-align: justify;
                                      border: 1px solid grey;
                                      border-radius: 2px;
                                    "
                                  >
                                    <v-list dense class="background">
                                      <template
                                        v-for="(
                                          item, index
                                        ) in availablePermissions"
                                      >
                                        <div :key="index">
                                          <v-list-item
                                            v-if="
                                              item == activeAvailablePermission
                                            "
                                            @click="
                                              assignAvailablePermission(item)
                                            "
                                            class="primary white--text"
                                          >
                                            {{ item }}
                                          </v-list-item>
                                          <v-list-item
                                            v-else
                                            @click="
                                              assignAvailablePermission(item)
                                            "
                                          >
                                            {{ item }}
                                          </v-list-item>

                                          <!-- <v-checkbox v-model="assignedPermissions"
                                                                                      :value="item"
                                                                                      :label="item">
                                                                                  </v-checkbox> -->
                                        </div>
                                      </template>
                                    </v-list>
                                  </div>
                                </v-layout>
                              </v-flex>
                              <!-- <v-flex xs12>
                                                              <div align="center">
                                                                  <v-pagination :length="permissionLength"
                                                                      total-visible="10"
                                                                      v-model="permissionPagination.current_page"
                                                                      @input="changePermissionPage()" circle>
                                                                  </v-pagination>
                                                              </div>
                                                          </v-flex> -->
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 md2>
                          
                            <v-container fill-height fluid>
                              <v-row align="center">
                                <v-col class="text-center">
                                  <div class="hidden-sm-and-down">
                                    <v-layout column>
                                      <v-flex xs12>
                                        <v-btn
                                          icon
                                          class="my-1 primary white--text"
                                          @click="assignPermission()"
                                          :disabled="assignButton"
                                          ><v-icon
                                            >mdi-chevron-right</v-icon
                                          ></v-btn
                                        >
                                      </v-flex>
                                      <v-flex xs12>
                                        <v-btn
                                          icon
                                          class="my-1 mb-3 primary white--text"
                                          @click="assignAllPermissions()"
                                          :disabled="assignAllButton"
                                          ><v-icon
                                            >mdi-chevron-double-right</v-icon
                                          ></v-btn
                                        >
                                      </v-flex>
                                      <v-flex xs12>
                                        <v-btn
                                          icon
                                          class="my-1 mt-3 primary white--text"
                                          @click="removePermission()"
                                          :disabled="removeButton"
                                          ><v-icon
                                            >mdi-chevron-left</v-icon
                                          ></v-btn
                                        >
                                      </v-flex>
                                      <v-flex xs12>
                                        <v-btn
                                          icon
                                          class="my-1 primary white--text"
                                          @click="removeAllPermissions()"
                                          :disabled="removeAllButton"
                                          ><v-icon
                                            >mdi-chevron-double-left</v-icon
                                          ></v-btn
                                        >
                                      </v-flex>
                                    </v-layout>
                                  </div>
                                  <div class="hidden-md-and-up mb-15">
                                    <v-layout row wrap>
                                      <v-flex xs3>
                                        <v-btn
                                          icon
                                          class="my-1 primary white--text"
                                          @click="assignPermission()"
                                          :disabled="assignButton"
                                          ><v-icon
                                            >mdi-chevron-down</v-icon
                                          ></v-btn
                                        >
                                      </v-flex>
                                      <v-flex xs3>
                                        <v-btn
                                          icon
                                          class="my-1 mr-3 primary white--text"
                                          @click="assignAllPermissions()"
                                          :disabled="assignAllButton"
                                          ><v-icon
                                            >mdi-chevron-double-down</v-icon
                                          ></v-btn
                                        >
                                      </v-flex>
                                      <v-flex xs3>
                                        <v-btn
                                          icon
                                          class="my-1 ml-3 primary white--text"
                                          @click="removePermission()"
                                          :disabled="removeButton"
                                          ><v-icon
                                            >mdi-chevron-up</v-icon
                                          ></v-btn
                                        >
                                      </v-flex>
                                      <v-flex xs3>
                                        <v-btn
                                          icon
                                          class="my-1 primary white--text"
                                          @click="removeAllPermissions()"
                                          :disabled="removeAllButton"
                                          ><v-icon
                                            >mdi-chevron-double-up</v-icon
                                          ></v-btn
                                        >
                                      </v-flex>
                                    </v-layout>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-flex>
                          <v-flex xs12 md5>
                            <v-layout column>
                              <v-flex xs12 class="my-2">
                                <v-layout row wrap>
                                  <v-flex xs12>
                                    <div align="center" class="mt-5">
                                      <b>Assigned Permissions</b>
                                    </div>
                                  </v-flex>

                                  <v-flex xs12>
                                    <div class="pa-1 mx-5">
                                      <v-text-field
                                        class="text_field header"
                                        outlined
                                        dense
                                        label="Search"
                                        v-on:keyup.enter="
                                          searchAssignedPermissions
                                        "
                                        v-model="searchAssignedPermissionTerm"
                                        append-icon="mdi-undo-variant"
                                        @click:append="resetAssigned()"
                                        @input="
                                          enableAssignedPermissionSearch()
                                        "
                                      >
                                      </v-text-field>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12>
                                    <div class="pa-1 mx-5" align="center">
                                      <v-btn
                                        class="accent text-none primary--text"
                                        depressed
                                        block
                                        @click="searchAssignedPermissions"
                                        :loading="searchPermissionLoader"
                                        :disabled="
                                          assignedPermissionSearchButtonDisabled
                                        "
                                      >
                                        Search
                                        <v-icon right>mdi-magnify</v-icon>
                                      </v-btn>
                                    </div>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 class="mt-5">
                                <!-- <div><b>Selected: </b>{{assignedPermissions}}</div> -->
                                <!-- <v-progress-linear class="mb-1" v-if="permissionLoader"
                                                                  height="1" indeterminate color="primary">
                                                              </v-progress-linear> -->
                                <v-layout column>
                                  <div
                                    class="mx-2"
                                    style="
                                      margin: 1px, 1px;
                                      padding: 1px;
                                      width: auto;
                                      height: 86vh;
                                      overflow-x: hidden;
                                      overflow-y: scroll;
                                      text-align: justify;
                                      border: 1px solid grey;
                                      border-radius: 2px;
                                    "
                                  >
                                    <v-list dense class="background">
                                      <template
                                        v-for="(
                                          item, index
                                        ) in assignedPermissions"
                                      >
                                        <div :key="index">
                                          <v-list-item
                                            v-if="
                                              item == activeAssignedPermission
                                            "
                                            @click="
                                              assignAssignedPermission(item)
                                            "
                                            class="primary white--text"
                                          >
                                            {{ item }}
                                          </v-list-item>
                                          <v-list-item
                                            v-else
                                            @click="
                                              assignAssignedPermission(item)
                                            "
                                            
                                          >
                                            {{ item }}
                                          </v-list-item>

                                          <!-- <v-checkbox v-model="assignedPermissions"
                                                                                      :value="item.name"
                                                                                      :label="item.name">
                                                                                  </v-checkbox> -->
                                        </div>
                                      </template>
                                    </v-list>
                                  </div>
                                </v-layout>
                              </v-flex>
                              <!-- <v-flex xs12>
                                                              <div align="center">
                                                                  <v-pagination :length="permissionLength"
                                                                      total-visible="10"
                                                                      v-model="permissionPagination.current_page"
                                                                      @input="changePermissionPage()" circle>
                                                                  </v-pagination>
                                                              </div>
                                                          </v-flex> -->
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  class="primary text-none mt-3"
                  @click="save"
                  :disabled="!valid"
                  :loading="loading"
                >
                  Save
                  <v-icon right> mdi-content-save </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </div>
        <div v-if="view == 'show'">
          <v-card outlined class="mt-5">
            <v-card-title class="accent text--text">
              {{ assignedRole.name }}
              <v-spacer></v-spacer>
              <v-btn icon @click="changeView('default')">
                <v-icon class="text--text"> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="pa-3">
                <v-layout row wrap>
                  <template v-for="(item, index) in assignedRole.permissions">
                    <v-flex xs12 md3 :key="index">
                      <v-checkbox
                        disabled
                        v-model="assignedRole.permissions"
                        :value="item"
                        :label="item"
                      ></v-checkbox>
                    </v-flex>
                  </template>
                </v-layout>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-layout>
    </div>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    
  },
  data() {
    return {
      dashBreadcrumb: true,
      showBreadcrumb: false,

      view: "default",
      page: 1,
      loading: false,
      valid: true,
      message: "",
      color: "",
      delete: false,
      roleIndex: null,
      snackbar: false,
      searchFilter: null,
      inputRules: [
        (v) => !!v || "Input is required",
      ],
      searchTerm: null,
      searchLoader: false,
      searchButtonDisabled: false,
      filteredRoles: false,
      searchAvailablePermissionTerm: null,
      searchAssignedPermissionTerm: null,
      availablePermissionSearchButtonDisabled: false,
      assignedPermissionSearchButtonDisabled: false,
      searchButtonDisabled: false,
      searchPermissionLoader: false,
      filteredPermissions: false,
      assignedPermissions: [],
      availablePermissions: [],
      assignedPermissionsCopy: [],
      availablePermissionsCopy: [],
      uniqueGuards: [],
      newRole: {
        id: null,
        name: null,
        guard_name: null
      },
      assignedRole: {
        name: null,
        guard_name: null,
        permissions: [],
      },
      highLightId: null,
      activeAssignedPermission: null,
      activeAvailablePermission: null,
      assignButton: false,
      assignAllButton: false,
      removeButton: false,
      removeAllButton: false,
    };
  },
  created() {
    if (this.$can("role_view")) {
      this.fetchRoles(this.rolePagination.current_page);
      this.startpermissionLoader();
      this.fetchPermissions(1);
    }
  },
  methods: {
    ...mapActions([
      "fetchRoles",
      "fetchPermissions",
      "filterPermissions",
      "startpermissionLoader",
      "stoppermissionLoader",
      "startRoleLoader",
      "stopRoleLoader",
      "filterRoles",
    ]),
    assignPermission() {
      if (this.activeAvailablePermission == null) {
        this.assignButton = true;
        this.message = "Please pick a permission to assign";
        this.color = "error";
        this.snackbar = true;
      } else {
        this.assignedPermissions.push(this.activeAvailablePermission);
        const index = this.availablePermissions.indexOf(
          this.activeAvailablePermission
        );
        if (index > -1) {
          this.availablePermissions.splice(index, 1);
        }

        const indexCopy = this.availablePermissionsCopy.indexOf(
          this.activeAvailablePermission
        );
        if (indexCopy > -1) {
          this.availablePermissionsCopy.splice(indexCopy, 1);
        }

        this.activeAssignedPermission = this.activeAvailablePermission;

        this.removeButton = false;
        this.removeAllButton = false;
      }
    },
    assignAllPermissions() {
      if (this.availablePermissions.length == 0) {
        this.assignAllButton = true;
        this.message = "No available permission";
        this.color = "error";
        this.snackbar = true;
      } else {
        confirm("Are You Sure You Want to Assign All Permissions?") &&
          (this.delete = true);
        if (this.delete) {
          var l = this.availablePermissions.length,
            i;
          for (i = 0; i < l; i++) {
            this.assignedPermissions.push(this.availablePermissions[i]);

            const index = this.availablePermissionsCopy.indexOf(
              this.availablePermissions[i]
            );
            if (index > -1) {
              this.availablePermissionsCopy.splice(index, 1);
            }
          }
          this.availablePermissions = [];
          this.delete = false;

          this.removeButton = false;
          this.removeAllButton = false;
        }
      }
    },
    removePermission() {
      if (this.activeAssignedPermission == null) {
        this.removeButton = true;
        this.message = "Please pick a permission to remove";
        this.color = "error";
        this.snackbar = true;
      } else {
        this.availablePermissions.push(this.activeAssignedPermission);
        const index = this.assignedPermissions.indexOf(
          this.activeAssignedPermission
        );
        if (index > -1) {
          this.assignedPermissions.splice(index, 1);
        }

        const indexCopy = this.assignedPermissionsCopy.indexOf(
          this.activeAssignedPermission
        );
        if (indexCopy > -1) {
          this.assignedPermissionsCopy.splice(indexCopy, 1);
        }

        this.activeAvailablePermission = this.activeAssignedPermission;

        this.assignButton = false;
        this.assignAllButton = false;
      }
    },
    removeAllPermissions() {
      if (this.assignedPermissions.length == 0) {
        this.removeAllButton = true;
        this.message = "No assigned permission";
        this.color = "error";
        this.snackbar = true;
      } else {
        confirm("Are You Sure You Want to Remove All Permissions?") &&
          (this.delete = true);
        if (this.delete) {
          var l = this.assignedPermissions.length,
            i;
          for (i = 0; i < l; i++) {
            this.availablePermissions.push(this.assignedPermissions[i]);

            const index = this.assignedPermissionsCopy.indexOf(
              this.assignedPermissions[i]
            );
            if (index > -1) {
              this.assignedPermissionsCopy.splice(index, 1);
            }
          }
          this.assignedPermissions = [];
          this.delete = false;

          this.assignButton = false;
          this.assignAllButton = false;
        }
      }
    },

    assignAvailablePermission(permission) {
      this.activeAvailablePermission = permission;
      this.assignButton = false;
    },
    assignAssignedPermission(permission) {
      this.activeAssignedPermission = permission;
      this.removeButton = false;
    },
    enableAvailablePermissionSearch() {
      this.availablePermissionSearchButtonDisabled = false;
    },
    enableAssignedPermissionSearch() {
      this.assignedPermissionSearchButtonDisabled = false;
    },
    enableSearch() {
      this.searchButtonDisabled = false;
    },
    resetSearch() {
      (this.searchTerm = null), this.startRoleLoader();
      this.fetchRoles(1);
    },
    showRole(role) {
      this.highLightId = role.id;
      var flags = [],
        l = role.permissions.length,
        i;
      for (i = 0; i < l; i++) {
        if (flags[role.permissions[i].name]) continue;
        flags[role.permissions[i].name] = true;
        this.assignedRole.permissions.push(role.permissions[i].name);
      }

      this.assignedRole.name = role.name;
      this.assignedRole.guard_name = role.guard_name;
      this.changeView("show");
    },
    editRole(role) {
      this.highLightId = role.id;
      this.availablePermissions = this.permissions.map((a) => a.name);
      var flags = [],
        l = role.permissions.length,
        i;
      for (i = 0; i < l; i++) {
        if (flags[role.permissions[i].name]) continue;
        flags[role.permissions[i].name] = true;
        this.assignedPermissions.push(role.permissions[i].name);

        const index = this.availablePermissions.indexOf(
          role.permissions[i].name
        );
        if (index > -1) {
          this.availablePermissions.splice(index, 1);
        }
      }

      this.newRole.id = role.id;
      this.newRole.name = role.name;
      this.newRole.default_page = role.default_page;      
      this.newRole.guard_name = role.guard_name;
      this.newRole.public = role.public;

      this.changeView("edit");
    },
    save() {
      if (this.assignedPermissions.length == 0) {
        this.message = "Please Pick at Least One Permission";
        this.color = "error";
        this.loading = false;
        this.snackbar = true;
      } else {
        if (this.$refs.form.validate()) {
          this.loading = true;
          this.newRole.permissions = this.assignedPermissions;
          if (this.newRole.id != null) {
            apiCall({
              url: "/api/role/" + this.newRole.id,
              data: this.newRole,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Role Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchRoles(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          } else {
            apiCall({
              url: "/api/role",
              data: this.newRole,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Role Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchRoles(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        }
      }
    },
    resetAvailable() {
      if (this.availablePermissionsCopy.length == 0) {
        //this.availablePermissionsCopy = this.availablePermissions
      } else {
        this.searchAvailablePermissionTerm = null;
        this.availablePermissions = this.availablePermissionsCopy;
      }
    },
    searchAvailablePermissions() {
      if (this.searchAvailablePermissionTerm == null) {
        this.availablePermissionSearchButtonDisabled = true;
      } else {
        if (this.availablePermissionsCopy.length == 0) {
          this.availablePermissionsCopy = this.availablePermissions;
        } else {
          this.availablePermissions = this.availablePermissionsCopy;
        }

        var newArray = this.availablePermissions.filter((term) =>
          term.includes(this.searchAvailablePermissionTerm)
        );
        this.availablePermissions = newArray;
      }
    },
    resetAssigned() {
      if (this.assignedPermissionsCopy.length == 0) {
        //this.assignedPermissionsCopy = this.assignedPermissions
      } else {
        this.searchAssignedPermissionTerm = null;
        this.assignedPermissions = this.assignedPermissionsCopy;
      }
    },
    searchAssignedPermissions() {
      if (this.searchAssignedPermissionTerm == null) {
        this.assignedPermissionSearchButtonDisabled = true;
      } else {
        if (this.assignedPermissionsCopy.length == 0) {
          this.assignedPermissionsCopy = this.assignedPermissions;
        } else {
          this.assignedPermissions = this.assignedPermissionsCopy;
        }

        var newArray = this.assignedPermissions.filter((term) =>
          term.includes(this.searchAssignedPermissionTerm)
        );
        this.assignedPermissions = newArray;
      }
    },
    // searchAvailablePermissions() {
    //     if (this.searchAvailablePermissionTerm == null) {
    //         this.availablePermissionSearchButtonDisabled = true
    //     } else {
    //         this.startpermissionLoader()
    //         this.totalSelected = this.assignedPermissions
    //         this.assignedPermissions = this.assignedPermissions.slice(0)
    //         this.searchPermissionLoader = true
    //         this.availablePermissionSearchButtonDisabled = false
    //         apiCall({ url: '/api/user/permission?type=search&search=' + this.searchAvailablePermissionTerm, method: 'GET' })
    //             .then(resp => {
    //                 this.stoppermissionLoader()
    //                 this.filterPermissions(resp)
    //                 this.assignedPermissions = this.totalSelected
    //                 this.searchPermissionLoader = false
    //                 this.filteredPermissions = true
    //             })
    //             .catch(error => {
    //                 console.log(error.response)
    //                 this.searchPermissionLoader = false
    //                 this.message = 'anErrorOccurredMsg'
    //                 this.color = 'error'
    //                 this.snackbar = true
    //             })
    //     }

    // },
    changePermissionPage() {
      this.startpermissionLoader();
      this.availablePermissionSearchButtonDisabled = false;
      this.totalSelected = this.assignedPermissions;
      this.assignedPermissions = this.assignedPermissions.slice(0);
      if (this.filteredPermissions == true) {
        apiCall({
          url:
            "/api/user/permission?page=" +
            this.permissionPagination.current_page +
            "&type=search&search=" +
            this.searchAvailablePermissionTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterPermissions(resp);
            this.stoppermissionLoader();
            this.assignedPermissions = this.totalSelected;
          })
          .catch((error) => {
            console.log(error.response);
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url:
            "/api/user/permission?page=" +
            this.permissionPagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.filterPermissions(resp);
            this.stoppermissionLoader();
            this.assignedPermissions = this.totalSelected;
          })
          .catch((error) => {
            console.log(error.response);
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    search() {
      if (this.searchTerm == null) {
        this.searchButtonDisabled = true;
      } else {
        this.searchLoader = true;
        this.searchButtonDisabled = false;
        this.startRoleLoader();
        apiCall({
          url: "/api/role?type=search&search=" + this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterRoles(resp);
            this.searchLoader = false;
            this.stopRoleLoader();
            this.filteredRoles = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.searchLoader = false;
            this.stopRoleLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changeView(view) {
      if (view == "default") {
        this.newRole.id = null;
        this.newRole.name = null;
        this.newRole.default_page = null;
        this.newRole.guard_name = null;
        this.assignedPermissions = [];
        this.dashBreadcrumb = true;
        this.showBreadcrumb = false;
        this.assignedRole.permissions = [];
        this.availablePermissions = [];
      } else if (view == "create") {
        var flags = [],
          l = this.permissions.length,
          i;
        for (i = 0; i < l; i++) {
          if (flags[this.permissions[i].guard_name]) continue;
          flags[this.permissions[i].guard_name] = true;
          this.uniqueGuards.push(this.permissions[i].guard_name);
        }
        this.availablePermissions = this.permissions.map((a) => a.name);
      } else if (view == "edit") {
        var flags = [],
          l = this.permissions.length,
          i;
        for (i = 0; i < l; i++) {
          if (flags[this.permissions[i].guard_name]) continue;
          flags[this.permissions[i].guard_name] = true;
          this.uniqueGuards.push(this.permissions[i].guard_name);
        }
        view = "create";
      } else if (view == "show") {
        this.dashBreadcrumb = false;
        this.showBreadcrumb = true;
      }
      this.view = view;
    },
    changePage() {
      this.startRoleLoader();
      if (this.filteredRoles == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/user/role?page=" +
            this.rolePagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterRoles(resp);
            this.stopRoleLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopRoleLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchRoles(this.rolePagination.current_page);
      }
    },
    deleteRole(item) {
      confirm("Are You Sure You Want to Delete Role") && (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.roleIndex = item.id;
        apiCall({ url: "/api/role/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Role Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.roleIndex = null;
            this.fetchRoles(this.rolePagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.roleIndex = null;
            console.log(error.response);
          });
      }
    },
  },
  computed: {
    ...mapGetters([
      "roles",
      "rolePagination",
      "roleLoader",
      "permissions",
      "permissionPagination",
      "permissionLoader",
    ]),
    length: function () {
      return Math.ceil(
        this.rolePagination.total / this.rolePagination.per_page
      );
    },
    permissionLength: function () {
      return Math.ceil(
        this.permissionPagination.total / this.permissionPagination.per_page
      );
    },
  },
};
</script>
