import apiCall from '@/utils/api'

const state = {
	partCategories: [],
	allPartCategories: [],
    partCategoryPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    partCategoryLoader: false
};

const getters = {
	partCategories: (state) => state.partCategories,
	allPartCategories: (state) => state.allPartCategories,
    partCategoryLoader: (state) => state.partCategoryLoader,
    partCategoryPagination: (state) => state.partCategoryPagination
}

const actions = {
	async fetchAllPartCategories({commit}, page) {
		const response = await apiCall({url: `/api/partCategory?type=all`, method: 'GET' });
		commit('setAllPartCategories', response)
        commit('stopLoader', response)
	},
    async fetchPartCategories({commit}, page) {
		const response = await apiCall({url: `/api/partCategory?page=${page}`, method: 'GET' });
		commit('setPartCategories', response)
        commit('stopLoader', response)
	},
    async filterPartCategories({commit, state},resp){
		commit('setPartCategories', resp)
	},
	async startPartCategoryLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopPartCategoryLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setPartCategories: (state, partCategories) => {
		state.partCategories = partCategories.data
		state.partCategoryPagination.current_page = partCategories.current_page
		state.partCategoryPagination.total = partCategories.total
		state.partCategoryPagination.per_page = partCategories.per_page
	},
	setAllPartCategories: (state, partCategories) => {
		state.allPartCategories = partCategories
	},
    startLoader: (state) => state.partCategoryLoader = true,
	stopLoader: (state) => state.partCategoryLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
