<template>
	<div>
		<v-snackbar
			v-model="snackbar"
			:color="color"
			:timeout="6000"
			top
		>
			{{ message }}
		</v-snackbar>
        <div class="pa-5">
            <v-layout column>
                
                <v-flex xs12 class="mt-5">
                   
                    <v-card elevation="0" class="mt-5">
                        <v-flex xs12 class="mt-10 mb-2">
                            <div class="mx-5">
                                <v-layout row wrap>
                                <v-flex xs12 md6>
                                    <div v-if="length != 0" align="left" class="mt-1">
                                    <b class="display-1 primary--text">Notifications </b>
                                    </div>
                                </v-flex>
                                </v-layout>
                            </div>
                            </v-flex>
                            <v-divider class="mt-9 mx-5"></v-divider>
                            <v-progress-linear
                            v-if="loading"
                            height="1"
                            indeterminate
                            color="primary"
                            >
                            </v-progress-linear>
                            <v-flex xs12 class="mt-10 mb-2">
                                <div class="mx-5">
                                    <template v-for="(notification, index) in paginatedNotifications">
                                        <div :key="index">
                                            <v-flex xs12 class="mt-2">
                                                <v-card
                                                    outlined
                                                    style="border-radius: 25px;"
                                                    v-if="notification.read_at == null"
                                                >
                                                    <div class="pa-2">
                                                        <v-layout row>
                                                            <v-flex xs1>
                                                                <div align="center">
                                                                    <v-checkbox
                                                                        class="ml-3"
                                                                        v-model="checked"
                                                                        :value="notification.id"
                                                                    ></v-checkbox>
                                                                </div>
                                                            </v-flex>
                                                            <v-flex xs11>
                                                                <v-layout column>
                                                                    <v-flex xs12>
                                                                        <div class="mt-3" v-if="notification.type == 'App\\Notifications\\RefundDeniedInternal'" @click="openAuthNotification(notification)"><a><b>Refund Request Denied</b></a></div>
                                                                        <div class="mt-3" v-if="notification.type == 'App\\Notifications\\RefundApprovedInternal'" @click="openAuthNotification(notification)"><a><b>Refund Request Approved</b></a></div>
                                                                        <div class="mt-3" v-if="notification.type == 'App\\Notifications\\RequestRefund'" @click="openAuthNotification(notification)"><a><b>Refund Request</b></a></div>
                                                                        <div class="mt-3" v-if="notification.type == 'App\\Notifications\\RequestRefundInternal'" @click="openAuthNotification(notification)"><a><b>Refund Request</b></a></div>
                                                                        <div class="mt-3" v-if="notification.type == 'App\\Notifications\\RequestRefundExternal'" @click="openAuthNotification(notification)"><a><b>Refund Request</b></a></div>
                                                                        <div class="mt-3" v-if="notification.type == 'App\\Notifications\\ItemIssued'" @click="openAuthNotification(notification)"><a><b>Item Issued</b></a></div>
                                                                        <div class="mt-3" v-if="notification.type == 'App\\Notifications\\ConfirmSaleInternal'" @click="openAuthNotification(notification)"><a><b>Online Sale</b></a></div>
                                                                    </v-flex>
                                                                    <v-flex xs12>
                                                                        <div class="grey--text">{{notification.created_at | moment("MMMM Do YYYY, h:mm a")}}</div>
                                                                    </v-flex>
                                                                    <v-flex xs12>
                                                                        <div class="mb-3" v-if="notification.type == 'App\\Notifications\\RefundDeniedInternal'">{{ notification.data.sale.sale_no }} - {{ notification.data.part.name }}</div>
                                                                        <div class="mb-3" v-if="notification.type == 'App\\Notifications\\RefundApprovedInternal'">{{ notification.data.sale.sale_no }} - {{ notification.data.part.name }}</div>       
                                                                        <div class="mb-3" v-if="notification.type == 'App\\Notifications\\RequestRefund'">{{ notification.data.sale.sale_no }} - {{ notification.data.part.name }}</div>
                                                                        <div class="mb-3" v-if="notification.type == 'App\\Notifications\\RequestRefundInternal'">{{ notification.data.sale.sale_no }} - {{ notification.data.part.name }}</div>
                                                                        <div class="mb-3" v-if="notification.type == 'App\\Notifications\\RequestRefundExternal'">{{ notification.data.sale.sale_no }} - {{ notification.data.part.name }}</div>
                                                                        <div class="mb-3" v-if="notification.type == 'App\\Notifications\\ItemIssued'">{{ notification.data.sale.sale_no }} - {{ notification.data.part.name }}</div>
                                                                        <div class="mb-3" v-if="notification.type == 'App\\Notifications\\ConfirmSaleInternal'">{{ notification.data.sale.sale_no }} - {{ notification.data.user.first_name }} {{ notification.data.user.middle_name }} {{ notification.data.user.last_name }}</div>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </v-flex>
                                                        </v-layout>
                                                    </div>
                                                </v-card>
                                                
                                                <v-card
                                                    class="background"
                                                    v-else
                                                    outlined
                                                    style="border-radius: 25px;"
                                                >
                                                    <div class="pa-2">
                                                        <v-layout row>
                                                            <v-flex xs1>
                                                                <div align="center">
                                                                    <v-checkbox
                                                                        class="ml-3"
                                                                        v-model="checked"
                                                                        :value="notification.id"
                                                                    ></v-checkbox>
                                                                </div>
                                                            </v-flex>
                                                            <v-flex xs11>
                                                                <v-layout column>
                                                                    <v-flex xs12>                                                            
                                                                        <div class="mt-3" v-if="notification.type == 'App\\Notifications\\RefundDeniedInternal'" @click="openAuthNotification(notification)"><a><b>Refund Request Denied</b></a></div>
                                                                        <div class="mt-3" v-if="notification.type == 'App\\Notifications\\RefundApprovedInternal'" @click="openAuthNotification(notification)"><a><b>Refund Request Approved</b></a></div>
                                                                        <div class="mt-3" v-if="notification.type == 'App\\Notifications\\RequestRefund'" @click="openAuthNotification(notification)"><a><b>Refund Request</b></a></div>
                                                                        <div class="mt-3" v-if="notification.type == 'App\\Notifications\\RequestRefundInternal'" @click="openAuthNotification(notification)"><a><b>Refund Request</b></a></div>
                                                                        <div class="mt-3" v-if="notification.type == 'App\\Notifications\\RequestRefundExternal'" @click="openAuthNotification(notification)"><a><b>Refund Request</b></a></div>
                                                                        <div class="mt-3" v-if="notification.type == 'App\\Notifications\\ItemIssued'" @click="openAuthNotification(notification)"><a><b>Item Issued</b></a></div>
                                                                        <div class="mt-3" v-if="notification.type == 'App\\Notifications\\ConfirmSaleInternal'" @click="openAuthNotification(notification)"><a><b>Online Sale</b></a></div>
                                                                    </v-flex>
                                                                    <v-flex xs12>
                                                                        <div class="grey--text">{{notification.created_at | moment("MMMM Do YYYY, h:mm a")}}</div>
                                                                    </v-flex>
                                                                    <v-flex xs12>
                                                                        <div class="mb-3" v-if="notification.type == 'App\\Notifications\\RefundDeniedInternal'">{{ notification.data.sale.sale_no }} - {{ notification.data.part.name }}</div>
                                                                        <div class="mb-3" v-if="notification.type == 'App\\Notifications\\RefundApprovedInternal'">{{ notification.data.sale.sale_no }} - {{ notification.data.part.name }}</div>       
                                                                        <div class="mb-3" v-if="notification.type == 'App\\Notifications\\RequestRefund'">{{ notification.data.sale.sale_no }} - {{ notification.data.part.name }}</div>
                                                                        <div class="mb-3" v-if="notification.type == 'App\\Notifications\\RequestRefundInternal'">{{ notification.data.sale.sale_no }} - {{ notification.data.part.name }}</div>
                                                                        <div class="mb-3" v-if="notification.type == 'App\\Notifications\\RequestRefundExternal'">{{ notification.data.sale.sale_no }} - {{ notification.data.part.name }}</div>
                                                                        <div class="mb-3" v-if="notification.type == 'App\\Notifications\\ItemIssued'">{{ notification.data.sale.sale_no }} - {{ notification.data.part.name }}</div>
                                                                        <div class="mb-3" v-if="notification.type == 'App\\Notifications\\ConfirmSaleInternal'">{{ notification.data.sale.sale_no }} - {{ notification.data.user.first_name }} {{ notification.data.user.middle_name }} {{ notification.data.user.last_name }}</div>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </v-flex>
                                                        </v-layout>
                                                    </div>
                                                </v-card>
                                            </v-flex>
                                        </div>
                                    </template>
                                </div>
                            </v-flex>
                    </v-card>
                </v-flex>
                <v-flex xs12 class="mt-5 mx-5">
                    <v-btn depressed dark class="primary text-none mx-1" @click="markAll" v-if="allMarked == true">Select all <v-icon dark right>mdi-check-all</v-icon></v-btn>
                    <v-btn depressed dark class="orange text-none mx-1" @click="unMarkAll" v-if="allMarked == false">Unselect all <v-icon dark right>mdi-undo</v-icon></v-btn>
                    <v-btn depressed v-if="checked.length != 0" dark class="green text-none mx-1" @click="markNotificationsRead" :loading="readLoading">
                        Mark as Read
                        <v-icon dark right>mdi-email-mark-as-unread</v-icon>
                    </v-btn>
                    <v-btn depressed v-if="checked.length != 0" dark class="red text-none mx-1" @click="deleteNotifications" :loading="deleteLoading">
                            Delete
                            <v-icon dark right>mdi-delete</v-icon>
                        </v-btn>
                </v-flex>
            </v-layout>
            <div v-if="length" class="text-xs-center">
                <v-pagination
                    :length="length"
                    total-visible="5"
                    v-model="notificationPagination.current_page"
                    @input="fetchnotifications"
                    circle>
                </v-pagination>
            </div>
        </div>
    </div>
</template>

<script>
  import apiCall from "@/utils/api";
  import Vue from 'vue'
  import { mapGetters, mapState, mapActions } from 'vuex'

  Vue.use(require('vue-moment'));
  export default {
	components: {
		
	},
	data(){
		return{
            allMarked: true,
            readLoading: false,
            deleteLoading: false,
            snackbar: false,

            loading: false,

            message:'',
            y: 'top',
            color: '',

            checked: [],
            emptyArray: [],

            notificationData: {
                notifications: null
            }
        }
    },
    created(){
        this.fetchnotifications()
    },
	methods: {
        ...mapActions([
            'commitPaginatedNotifications',
        ]),
        markAll(){
            this.allMarked = false
            var i = 0
            for (i; i<= (this.paginatedNotifications.length-1); i++){
                this.checked.push(this.paginatedNotifications[i].id)
            }
        },
        unMarkAll(){
            this.allMarked = true
            var i = 0
            for (i; i<= (this.paginatedNotifications.length-1); i++){
                this.checked.splice(i)
            }
        },
        markNotificationsRead(){
            if(this.checked.length != 0){
                this.notificationData.notifications = this.checked
                
                this.readLoading = true
                apiCall({url: '/api/markAsRead', data: this.notificationData, method: 'POST' })
                .then(resp => {
                    this.color = 'success'
                    this.message = 'Notifications Marked as Read Successfully'
                    this.readLoading = false;
                    this.snackbar = true;
                    this.fetchnotifications()
                })
                .catch(error => {
                    this.color = 'error'
                    this.message = 'An error Occurred'
                    this.readLoading = false;
                    this.snackbar = true;
                })
            } else {
                this.color = 'error'
                this.message = 'Nothing is selected'
                this.snackbar = true;
            }
            
        },
        deleteNotifications(){
            if(this.checked.length != 0){
                this.notificationData.notifications = this.checked
                    
                this.deleteLoading = true
                apiCall({url: '/api/deleteNotification', data: this.notificationData, method: 'POST' })
                .then(resp => {
                    this.color = 'success'
                    this.message = 'Notifications Deleted Successfully'
                    this.deleteLoading = false;
                    this.snackbar = true;
                    this.fetchnotifications()
                })
                .catch(error => {
                    this.color = 'error'
                    this.message = 'An error Occurred'
                    this.deleteLoading = false;
                    this.snackbar = true;
                })
            } else {
                this.color = 'error'
                this.message = 'Nothing is selected'
                this.snackbar = true;
            }
        },
        fetchnotifications(){
            apiCall({url: '/api/notificationsPagination?page='+this.notificationPagination.current_page, method: 'GET' })
            .then(resp => {
                this.commitPaginatedNotifications(resp)
                this.unMarkAll()
            })
            .catch(error => {

            })
        },
        openAuthNotification(item){
            if(item.read_at != null){
                if (item.type == "App\\Notifications\\RefundDeniedInternal") {
                        this.$router.push("/sales-admin");
                    }
                    if (item.type == "App\\Notifications\\RefundApprovedInternal") {
                        this.$router.push("/sales-admin");
                    }
                    if (item.type == "App\\Notifications\\RequestRefund") {
                        this.$router.push("/sales");
                    }
                    if (item.type == "App\\Notifications\\RequestRefundInternal") {
                        this.$router.push("/refunds-admin");
                    }
                    if (item.type == "App\\Notifications\\RequestRefundExternal") {
                        this.$router.push("/sales");
                    }
                    if (item.type == "App\\Notifications\\ItemIssued") {
                        this.$router.push("/sales");
                    }
                    if (item.type == "App\\Notifications\\ConfirmSaleInternal") {
                        this.$router.push("/sales-admin");
                    }
            }else{
                this.loading = true
                apiCall({url: '/api/notificationRead/'+item.id, method: 'GET' })
                .then(resp => {
                    if (item.type == "App\\Notifications\\RefundDeniedInternal") {
                        this.$router.push("/sales-admin");
                    }
                    if (item.type == "App\\Notifications\\RefundApprovedInternal") {
                        this.$router.push("/sales-admin");
                    }
                    if (item.type == "App\\Notifications\\RequestRefund") {
                        this.$router.push("/sales");
                    }
                    if (item.type == "App\\Notifications\\RequestRefundInternal") {
                        this.$router.push("/refunds-admin");
                    }
                    if (item.type == "App\\Notifications\\RequestRefundExternal") {
                        this.$router.push("/sales");
                    }
                    if (item.type == "App\\Notifications\\ItemIssued") {
                        this.$router.push("/sales");
                    }
                    if (item.type == "App\\Notifications\\ConfirmSaleInternal") {
                        this.$router.push("/sales-admin");
                    }
                    this.loading = false
                })
                .catch(error => {
                    console.log(error.response)
                })
            }
            
        },
        removeTags(str) {
            if ((str===null) || (str===''))
            return false;
            else
            str = str.toString();
            return str.replace( /(<([^>]+)>)/ig, '');
        },
        text_truncate (str) {
            var length = 200;
            var ending = '...';
            
            if (str.length > length) {
                return str.substring(0, length - ending.length) + ending;
            } else {
                return str;
            }
        }
    },
	computed: {
		...mapGetters([
            'paginatedNotifications',
            'notificationPagination',
            'isAuthenticated'
        ]),
        length: function() {
            return Math.ceil(this.notificationPagination.total / this.notificationPagination.per_page);
        }
        
    },
    watch: {

  },

}
</script>