<template>
    <div class="dashboard">
      <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
        <span>{{ message }}</span>
      </v-snackbar>
      <v-progress-linear
        v-if="productLoader"
        height="2"
        indeterminate
        color="primary"
      ></v-progress-linear>
  
      <div class="pa-5 mt-8">
        <v-layout row wrap>
        <v-flex xs12 md8 class="mt-1">
            <v-layout column>
                <v-flex xs12>
                    <div class="mx-2 mt-3">
                        <v-img v-if="partCategory.image == null" class="background" contain style="border-radius: 25px;" max-height="450" src="img/book-silhouette-vector-14.png"/>
                        <v-img v-else class="background" contain style="border-radius: 25px;" max-height="450" :src="path + '/storage/part_pictures/' + partCategory.image"/>
                    </div>
                </v-flex>
                <v-flex xs12>
                    <div class="mx-3 mt-10"><b>Brief</b></div>
                    <div class="mx-3 mt-2">{{ partCategory.brief }}</div>
                </v-flex>
                <v-flex xs12>
                    <div class="mx-3 mt-10"><b>Description</b></div>
                    <div class="mx-3 mt-2" v-html="partCategory.description"></div>
                </v-flex>
            </v-layout>
            
            
        </v-flex>
        <v-flex xs12 md4>
          <div class="mt-1 mx-1 pa-5 white">
            <v-layout column>
              <v-flex xs12>
                <v-layout row wrap>
                  <v-flex xs11>
                    <div class="subtitle font-weight-bold">
                      {{ partCategory.name }}
                    </div>
                  </v-flex>
                  <v-flex xs1>
                      <div align="right" class="mr-1">
                        <v-btn v-if="partCategory.favourite == false" icon @click="addToWishlist()" :loading="
                          wishlistLoading
                        ">
                          <v-icon>mdi-heart</v-icon>
                        </v-btn>
                      </div>
                      <div align="right" class="mr-1">
                        <v-btn v-if="partCategory.favourite == true" icon @click="removeFromWishlist()" :loading="
                          wishlistLoading
                        ">
                          <v-icon class="red--text">mdi-heart</v-icon>
                        </v-btn>
                      </div>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 class="mt-6">
                <v-layout row wrap>
                  <v-flex xs3>
                    <div align="left">
                      Price:
                    </div>
                  </v-flex>
                  <v-flex xs9>
                    <div align="right" class="green--text">
                      Kshs {{ partCategory.price }}
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 class="mt-10">
                <v-btn depressed block large color="primary" @click="addToCart"
                  :loading="cartLoading" class="text-none mb-5">
                  Add to Cart
                  <v-icon right small>mdi-cart</v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs12 class="mt-10">
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-layout column>
                      <v-flex xs12 v-if="partCategory.category_parts.length!=0">
                        <div>
                          <b>Category</b>
                        </div>
                        <div>
                          <template v-for="(category_part, index) in partCategory.category_parts">
                             {{ category_part.category.name }}({{ category_part.sub_category.name }}), 
                          </template>
                        </div>
                      </v-flex>
                      <v-flex xs12 v-if="partCategory.level_parts.length!=0" class="mt-5">
                        <div>
                          <b>Level</b>
                        </div>
                        <div>
                          <template v-for="(level_part, index) in partCategory.level_parts">
                            {{ level_part.level.name }}({{ level_part.sub_level.name }}), 
                          </template>
                        </div>
                      </v-flex>
                      <v-flex xs12 v-if="partCategory.learning_area_parts.length!=0" class="mt-5">
                        <div>
                          <b>Learning Area</b>
                        </div>
                        <div>
                          <template v-for="(learning_area_part, index) in partCategory.learning_area_parts">
                            {{ learning_area_part.learning_area.name }}, 
                          </template>
                        </div>
                      </v-flex>
                    </v-layout>  
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </div>
        </v-flex>

      </v-layout>
      </div>
    </div>
  </template>
  <script>
  import apiCall from "@/utils/api";
  import { mapGetters, mapActions } from "vuex";
  export default {
    components: {
        
    },
    data() {
      return {
        path: process.env.VUE_APP_API_URL,
        loading: false,
        valid: true,
        message: "",
        color: "",
        delete: false,
        confirm: false,
        dialog: false,
        snackbar: false,
        notFound: false,
        productLoader: false,
  
        wishlistLoading: false,
        cartLoading: false,
  
        searchTerm: null,
        flowerIndex: null,
      };
    },
    watch: {
        notFound() {
            this.findPart();
        },
    },
    mounted() {
      window.onscroll = () => {
        this.changeColor();
      };
    },
    created() {
      window.scrollTo(0, 0);
      console.log(this.partCategory)
    },
    methods: {
      ...mapActions([
        "fetchWishlists",
        "filterPartCategories",
        'fetchRecentParts',
        "fetchCarts",

        "filterSearchParts"
      ]),
      fetchSubCategory(){
            apiCall({
                url:
                    "/api/part?page=1&type=subcategory&subcategory=" +
                    this.$route.params.subcategory,
                method: "GET",
            })
                .then((resp) => {
                    this.filterPartCategories(resp);
                })
                .catch((error) => {
                    this.message = "anErrorOccurredMsg";
                    this.color = "error";
                    this.snackbar = true;
                });            
        },

      findPart() {
        this.productLoader = true
        apiCall({
            url:
            "/api/part?type=find&page=1&slug=" +
            this.$route.params.product,
            method: "GET",
        })
            .then((resp) => {
              this.filterSearchParts(resp);
              this.productLoader = false
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.productLoader = false
            });
        },

        addToCart() {
            this.cartLoading = true;
            apiCall({
                url: "/api/cart",
                data: this.partCategory,
                method: "POST",
            })
                .then((resp) => {
                if (resp.status == 200) {
                    this.message = resp.message;
                    this.color = "success";
                    this.cartLoading = false;
                    this.snackbar = true;

                    this.findPart(1);
                    this.fetchCarts(1)
                } else if (resp.status == 409) {
                    this.message = resp.message;
                    this.color = "error";
                    this.cartLoading = false;
                    this.snackbar = true;
                }
                })
                .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.cartLoading = false;
                this.snackbar = true;
                });
            },
     
      addToWishlist(item) {
        this.wishlistLoading = true;
        apiCall({
          url: "/api/wishlist",
          data: this.partCategory,
          method: "POST",
        })
          .then((resp) => {
            if (resp.status == 200) {
              this.message = resp.message;
              this.color = "success";
              this.wishlistLoading = false;
              this.snackbar = true;
  
              this.findPart();
              this.fetchWishlists(1)
            } else if (resp.status == 409) {
              this.message = resp.message;
              this.color = "error";
              this.wishlistLoading = false;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.wishlistLoading = false;
            this.snackbar = true;
          });
      },
      removeFromWishlist(item){
        this.wishlistLoading = true;
        this.flowerIndex = item.id;
        apiCall({ url: "/api/wishlist/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Item removed from wishlist successfully";
            this.color = "success";
            this.snackbar = true;
            this.wishlistLoading = false;
            this.delete = false;
            this.flowerIndex = null;
            this.fetchFlowers(this.flowerPagination.current_page);
            this.fetchWishlists(1)
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.wishlistLoading = false;
            this.delete = false;
            this.flowerIndex = null;
            console.log(error.response);
          });
      },
    },
    computed: {
      ...mapGetters([
        "isAuthenticated",
      ]),

      partCategory() {
      if (
        this.$store.getters.partCategories.find(
          (partCategory) => partCategory.slug == this.$route.params.product
        )
      ) {
        return this.$store.getters.partCategories.find(
          (partCategory) => partCategory.slug == this.$route.params.product
        );
      } else if (
        this.$store.getters.partLevels.find(
          (partLevel) => partLevel.slug == this.$route.params.product
        )
      ) {
        return this.$store.getters.partLevels.find(
          (partLevel) => partLevel.slug == this.$route.params.product
        );
      } else if (
        this.$store.getters.searchParts.find(
          (searchPart) => searchPart.slug == this.$route.params.product
        )
      ) {
        return this.$store.getters.searchParts.find(
          (searchPart) => searchPart.slug == this.$route.params.product
        );
      } else if (
        this.$store.getters.partLearningAreas.find(
          (partLearningArea) => partLearningArea.slug == this.$route.params.product
        )
      ) {
        return this.$store.getters.partLearningAreas.find(
          (partLearningArea) => partLearningArea.slug == this.$route.params.product
        );
      } else {
        this.notFound = true;
      }
    },
    },
  };
  </script>