import apiCall from '@/utils/api'

const state = {
    openSalePartStocks: [],
    searchSalePartStocks: [],
    closedSalePartStocks: [],
    publicSalePartStocks: [],
    openSalePartStockPagination: {
        search: ' ',
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10
    },
    searchSalePartStockPagination: {
        search: ' ',
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10
    },
    closedSalePartStockPagination: {
        search: ' ',
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10
    },
    publicSalePartStockPagination: {
        search: ' ',
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10
    },

    openSalePartStockLoader: false,
    closedSalePartStockLoader: false,
    publicSalePartStockLoader: false,
    searchSalePartStockLoader: false,
};

const getters = {
    openSalePartStocks: (state) => state.openSalePartStocks,
    closedSalePartStocks: (state) => state.closedSalePartStocks,
    publicSalePartStocks: (state) => state.publicSalePartStocks,

    searchSalePartStocks: (state) => state.searchSalePartStocks,
    searchSalePartStockLoader: (state) => state.searchSalePartStockLoader,
    searchSalePartStockPagination: (state) => state.searchSalePartStockPagination,

    openSalePartStockLoader: (state) => state.openSalePartStockLoader,
    closedSalePartStockLoader: (state) => state.closedSalePartStockLoader,
    publicSalePartStockLoader: (state) => state.publicSalePartStockLoader,


    openSalePartStockPagination: (state) => state.openSalePartStockPagination,
    closedSalePartStockPagination: (state) => state.closedSalePartStockPagination,
    publicSalePartStockPagination: (state) => state.publicSalePartStockPagination,
}

const actions = {
    async fetchOpenSalePartStocks({commit}, page) {
        const response = await apiCall({url: `/api/sale-part-stock-refunds?type=open&page=${page}`, method: 'GET'});
        commit('setOpenSalePartStocks', response)
        commit('stopOpenSalePartStockLoader', response)
    },
    async fetchClosedSalePartStocks({commit}, page) {
        const response = await apiCall({url: `/api/sale-part-stock-refunds?type=closed&page=${page}`, method: 'GET'});
        commit('setClosedSalePartStocks', response)
        commit('stopClosedSalePartStockLoader', response)
    },
    async fetchSearchSalePartStocks({commit}, page) {
        const response = await apiCall({url: `/api/sale-part-stocks?page=${page}`, method: 'GET'});
        commit('setSearchSalePartStocks', response)
        commit('stopSearchSalePartStockLoader', response)
    },
    async fetchPublicSalePartStocks({commit}, page) {
        const response = await apiCall({url: `/api/sale-part-stocks?type=public&page=${page}`, method: 'GET'});
        commit('setPublicSalePartStocks', response)
        commit('stopPublicSalePartStockLoader', response)
    },
    async filterOpenSalePartStocks({commit, state}, resp) {
        commit('setOpenSalePartStocks', resp)
    },
    async filterClosedSalePartStocks({commit, state}, resp) {
        commit('setClosedSalePartStocks', resp)
    },
    async filterPublicSalePartStocks({commit, state}, resp) {
        commit('setPublicSalePartStocks', resp)
    },
    async filterSearchSalePartStocks({commit, state}, resp) {
        commit('setSearchSalePartStocks', resp)
    },
    async startOpenSalePartStockLoader({commit, state}, resp) {
        commit('startOpenSalePartStockLoader', resp)
    },
    async startClosedSalePartStockLoader({commit, state}, resp) {
        commit('startClosedSalePartStockLoader', resp)
    },
    async startPublicSalePartStockLoader({commit, state}, resp) {
        commit('startPublicSalePartStockLoader', resp)
    },
    async stopOpenSalePartStockLoader({commit, state}, resp) {
        commit('stopOpenSalePartStockLoader', resp)
    },
    async stopClosedSalePartStockLoader({commit, state}, resp) {
        commit('stopClosedSalePartStockLoader', resp)
    },
    async stopPublicSalePartStockLoader({commit, state}, resp) {
        commit('stopPublicSalePartStockLoader', resp)
    },
    async startSearchSalePartStockLoader({commit, state}, resp) {
        commit('startSearchSalePartStockLoader', resp)
    },
    async stopSearchSalePartStockLoader({commit, state}, resp) {
        commit('stopSearchSalePartStockLoader', resp)
    },
};

const mutations = {
    setOpenSalePartStocks: (state, openSalePartStocks) => {
        state.openSalePartStocks = openSalePartStocks.data
        state.openSalePartStockPagination.current_page = openSalePartStocks.current_page
        state.openSalePartStockPagination.total = openSalePartStocks.total
        state.openSalePartStockPagination.per_page = openSalePartStocks.per_page
    },
    setClosedSalePartStocks: (state, closedSalePartStocks) => {
        state.closedSalePartStocks = closedSalePartStocks.data
        state.closedSalePartStockPagination.current_page = closedSalePartStocks.current_page
        state.closedSalePartStockPagination.total = closedSalePartStocks.total
        state.closedSalePartStockPagination.per_page = closedSalePartStocks.per_page
    },
    setPublicSalePartStocks: (state, publicSalePartStocks) => {
        state.publicSalePartStocks = publicSalePartStocks.data
        state.publicSalePartStockPagination.current_page = publicSalePartStocks.current_page
        state.publicSalePartStockPagination.total = publicSalePartStocks.total
        state.publicSalePartStockPagination.per_page = publicSalePartStocks.per_page
    },
    setSearchSalePartStocks: (state, sales) => {
        state.searchSalePartStocks = sales.data
        state.searchSalePartStockPagination.current_page = sales.current_page
        state.searchSalePartStockPagination.total = sales.total
        state.searchSalePartStockPagination.per_page = sales.per_page
    },
    startSearchSalePartStockLoader: (state) => state.searchSalePartStockLoader = true,
    stopSearchSalePartStockLoader: (state) => state.searchSalePartStockLoader = false,

    startOpenSalePartStockLoader: (state) => state.openSalePartStockLoader = true,
    stopOpenSalePartStockLoader: (state) => state.openSalePartStockLoader = false,
    startClosedSalePartStockLoader: (state) => state.closedSalePartStockLoader = true,
    stopClosedSalePartStockLoader: (state) => state.closedSalePartStockLoader = false,
    startPublicSalePartStockLoader: (state) => state.publicSalePartStockLoader = true,
    stopPublicSalePartStockLoader: (state) => state.publicSalePartStockLoader = false,
};

export default {
    state,
    getters,
    actions,
    mutations
}
