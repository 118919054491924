import apiCall from '../../utils/api'

const state = {
	loginCardShow: false
};

const getters = {
	loginCardState: (state) => state.loginCardShow,
};

const actions = {
	async changeLoginCardState({commit}) {
		commit('updateCard')
	}
};

const mutations = {
	updateCard: (state) => (state.loginCardShow = !state.loginCardShow)
};

export default {
	state,
	getters,
	actions,
	mutations
}