<template>
	<div class="suppliers" v-if="$can('supplier_view')">
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<div class="pa-5">
			<v-layout column>
				<div v-if="view == 'default'">
					<v-card elevation="0" class="mt-5">

						<v-flex xs12 class="mt-10 mb-2">
							<div class="mx-5">
								<v-layout row wrap>
									<v-flex xs12 md6>
										<div v-if="length != 0" align="left" class="mt-1">
											<b class="display-1 primary--text">Total: </b
											>{{ walletPagination.total | formatNumber }}
										</div>
									</v-flex>
									<v-flex xs12 md4>
										<div class="pa-1">
											<v-text-field
													class="text_field background"
													outlined
													dense
													rounded
													label="Search"
													append-icon="mdi-undo-variant"
													@click:append="resetSearch()"
													v-on:keyup.enter="search"
													v-model="searchTerm"
													@input="enableSearch()"
											></v-text-field>
										</div>
									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1" align="right">
											<v-btn
													class="button mx-1"
													icon
													@click="search"
													:loading="searchLoader"
													:disabled="searchButtonDisabled"
											>
												<v-icon class="secondary--text">mdi-magnify</v-icon>
											</v-btn>
											<v-btn
													icon
													class="button mx-1"
													@click="changeView('create')"
													v-if="$can('supplier_create')"
											>

												<v-icon class="primary--text">mdi-plus-circle-outline</v-icon>
											</v-btn>
										</div>
									</v-flex>
								</v-layout>
							</div>
						</v-flex>
						<v-divider class="mt-9 mx-5"></v-divider>
						<v-progress-linear
								v-if="supplierLoader"
								height="1"
								indeterminate
								color="primary"
						>
						</v-progress-linear>
						<v-flex xs12 class="mt-5">
							<div v-if="wallets.length == 0">
								<v-card elevation="0">
									<v-layout row wrap>
										<v-flex xs12 md1>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														<v-icon large class="primary--text">
															mdi-alert-circle-outline
														</v-icon>
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
										<v-flex xs12 md11>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														No Wallet Found
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
									</v-layout>
								</v-card>
							</div>
							<div v-else>
								<div class="hidden-sm-and-down">
									<v-card elevation="0" class="pa-2">
										<v-simple-table>
											<template v-slot:default>
												<thead class="header">
												<tr class="accent">
													<th class="text-left text--text">User</th>
													<th class="text-left text--text">Balance</th>
													<th class="text-right text--text">Actions</th>
												</tr>
												</thead>
												<tbody>
												<tr v-for="item in wallets" :key="item.id">
													<td>{{ item.user.full_name }}</td>
													<td>{{ item.amount }}</td>
													<td>
														<div align="right">
															<v-btn
																	icon
																	v-if="$can('supplier_view')"
																	@click="showWallet(item)"
																	class="button mr-1 universal--text"
															>
																<v-icon small> mdi-eye</v-icon>
															</v-btn>
															<v-btn
																	v-if="$can('supplier_archive')"
																	icon
																	class="button mr-1 error--text"
																	@click="deleteWallet(item)"
																	:loading="
                                      loading && supplierIndex == item.id
                                    "
															>
																<v-icon small> mdi-delete</v-icon>
															</v-btn>
														</div>
													</td>
												</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>
								</div>
							</div>
						</v-flex>
					</v-card>
					<v-layout column class="mt-5">
						<v-flex xs12>
							<div align="left">
								<v-pagination
										v-if="length != 0"
										:length="length"
										total-visible="10"
										v-model="walletPagination.current_page"
										@input="changePage()"
										circle
								>
								</v-pagination>
							</div>
						</v-flex>
					</v-layout>
				</div>
				<div v-if="view == 'show'">
					<v-card outlined class="mt-5">
						<v-progress-linear
								v-if="supplierStockLoader"
								height="1"
								indeterminate
								color="primary"
						>
						</v-progress-linear>
						<v-card-title class="accent text--text">
							<v-spacer></v-spacer>
							<div>View Wallet</div>
							<v-spacer></v-spacer>
							<v-btn icon @click="changeView('default')">
								<v-icon> mdi-close</v-icon>
							</v-btn>
						</v-card-title>
						<v-card-text>
							<div class="pa-1">
								<v-tabs show-arrows v-model="supplierTab">
									<v-tabs-slider color="primary"></v-tabs-slider>

									<v-tab @click="activeTab = 'details'">
										<div class="text-none primary--text">Details</div>
									</v-tab>
									<v-tab v-if="$can('stock_view')" @click="activeTab = 'supplierStocks'">
										<div class="text-none primary--text">
											Transactions ({{ assignedWallet.wallet_logs.length }})
										</div>
									</v-tab>
								</v-tabs>
								<div v-if="activeTab === 'details'">
									<v-card-text>
										<div class="pa-3">
											<v-layout row wrap>
												<v-flex xs12 md10>
													<div class="mx-8">
														<v-layout column>
															<v-flex xs12>
																<v-layout row wrap>
																	<v-flex xs12 md6>
																		<v-layout column>
																			<v-flex xs12 class="mt-6">
																				<v-layout row wrap>
																					<v-flex xs12 md4>
																						<b>User:</b>
																					</v-flex>
																					<v-flex xs12 md8>
																						<div>
																							{{ assignedWallet.user.full_name }}
																						</div>
																					</v-flex>
																				</v-layout>
																			</v-flex>
																			<v-flex xs12 class="mt-6">
																				<v-layout row wrap>
																					<v-flex xs12 md4>
																						<b>Balance: </b>
																					</v-flex>
																					<v-flex xs12 md8>
																						{{ assignedWallet.amount }}
																					</v-flex>
																				</v-layout>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																</v-layout>
															</v-flex>
														</v-layout>
													</div>
												</v-flex>
											</v-layout>
										</div>
									</v-card-text>
								</div>

								<div v-if="activeTab == 'supplierStocks'">
									<div v-if="supplierStockView == 'default'">
										<v-card elevation="0" class="mt-5">
											<v-flex xs12 class="mt-10 mb-2">
												<div class="mx-5">
													<v-layout row wrap>
														<v-flex xs12 md6>
															<div
																	v-if="supplierAdministratorLength != 0"
																	align="left"
																	class="mt-1"
															>
																<b class="display-1">Wallet Transaction</b>
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="pa-1">
																<v-text-field
																		class="text_field background"
																		outlined
																		dense
																		rounded
																		label="Search"
																		append-icon="mdi-undo-variant"
																		@click:append="
                                      resetSupplierStockSearch()
                                    "
																		v-on:keyup.enter="
                                      supplierStockSearch
                                    "
																		v-model="supplierStockSearchTerm"
																		@input="
                                      enableSupplierStockSearch()
                                    "
																></v-text-field>
															</div>
														</v-flex>
													</v-layout>
												</div>
											</v-flex>
											<v-divider class="mt-9 mx-5"></v-divider>
											<v-flex xs12 class="mt-5">
												<div v-if="assignedWallet.wallet_logs.length == 0">
													<v-card elevation="0">
														<v-layout row wrap>
															<v-flex xs12 md1>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			<v-icon large class="text--text">
																				mdi-alert-circle-outline
																			</v-icon>
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
															<v-flex xs12 md11>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			No Wallet Transactions Found
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
														</v-layout>
													</v-card>
												</div>
												<div v-else>
													<div class="hidden-sm-and-down">
														<v-card elevation="0" class="pa-2">
															<v-simple-table>
																<template v-slot:default>
																	<thead class="header">
																	<tr class="accent">
																		<th class="text-left text--text">Transaction No</th>
																		<th class="text-left text--text">Transaction Code</th>
																		<th class="text-left text--text">Amount</th>
																		<th class="text-left text--text">Date</th>
																	</tr>
																	</thead>
																	<tbody>
																	<tr v-for="log in assignedWallet.wallet_logs" :key="log.id">
																		<td>{{ log.transaction_number }}</td>
																		<td>{{ log.transaction_code }}</td>
																		<td>{{ log.amount }}</td>
																		<td>
																			<div>{{ log.created_at | moment("DD/MM/YYYY") }}</div>
																			<small>{{ log.created_at | moment("hh:mm A") }}</small>
																		</td>
																	</tr>
																	</tbody>
																</template>
															</v-simple-table>
														</v-card>
													</div>
													<div class="hidden-md-and-up">
														<div>
															<v-layout column>
																<template v-for="(
                                      supplierStock, index
                                    ) in supplierStocks">
																	<div :key="index">
																		<v-flex xs12 class="mb-2">
																			<v-card elevation="0" style="border-radius: 25px;" outlined>
																				<div class="pa-7 mt-2">
																					<v-layout column>
																						<v-flex xs12>
																							<v-layout row wrap>
																								<v-flex xs12>
																									<div
																											class="title primary--text">
																										<b>{{
																												supplierStock.part.name
																											}}</b>
																									</div>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																						<v-divider class="mt-2"></v-divider>
																						<v-flex xs12 class="mt-1">
																							<v-layout column>
																								<v-flex xs12 class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Stock No</b>
																										</v-flex>
																										<v-flex xs8>
																											{{ supplierStock.stock_no }}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12 class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Invoice No</b>
																										</v-flex>
																										<v-flex xs8>
																											{{ supplierStock.invoice_no }}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12 class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Buying (Kshs)</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												supplierStock.buying_price | formatNumber
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12 class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Selling (Kshs)</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												supplierStock.selling_price | formatNumber
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12 class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Initial Stock</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												supplierStock.initial_quantity | formatNumber
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12 class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Stock Balance</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												supplierStock.current_quantity | formatNumber
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12 class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Expiry Date</b>
																										</v-flex>
																										<v-flex xs8>
																											{{ supplierStock.expiry_date }}
																										</v-flex>
																									</v-layout>
																								</v-flex>


																								<v-flex xs12 class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Status</b>
																										</v-flex>
																										<v-flex xs8>
																											<div v-if="supplierStock.status ==
                                                                                                                1
                                                                                                                ">
																												Active
																											</div>
																											<div v-else>
																												Inactive
																											</div>
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12 class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Date
																												Created</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												supplierStock.created_at | moment("DD/MM/YYYY")
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																					</v-layout>
																				</div>
																			</v-card>
																		</v-flex>
																	</div>
																</template>
															</v-layout>
														</div>
													</div>
												</div>
											</v-flex>
										</v-card>
										<v-layout column class="mt-5">
											<v-flex xs12>
												<div align="left">
													<v-pagination
															v-if="length != 0"
															:length="supplierAdministratorLength"
															total-visible="10"
															v-model="
                                supplierStockPagination.current_page
                              "
															@input="changeSupplierStockPage()"
															circle
													>
													</v-pagination>
												</div>

											</v-flex>

										</v-layout>
									</div>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</div>
			</v-layout>
		</div>
	</div>
</template>
<style>
.ck-editor__editable {
	min-height: 200px;
}
</style>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import {mapActions, mapGetters} from "vuex";
import logData from "@/views/accessControl/logData";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

Vue.use(CKEditor);

export default {
	components: {
		logData,
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,

			editor: ClassicEditor,
			editorConfig: {},

			importDialog: false,
			activeTab: "details",

			view: "default",
			supplierStockView: "default",

			contactView: "default",
			loading: false,
			supplierStockLoader: false,

			activateLoading: false,
			deactivateLoading: false,

			valid: true,
			message: "",
			color: "",
			delete: false,
			activate: false,
			confirm: false,
			tab: null,

			supplierTab: null,
			supplierStockIndex: null,
			supplierIndex: null,

			snackbar: false,
			inputRules: [(v) => !!v || "Input is required"],

			emailRules: [
				(v) =>
						!v ||
						/^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
								v
						) ||
						"E-mail must be valid",
				(v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
			],
			websiteRules: [
				(v) =>
						!v ||
						/^[a-zA-Z0-9\.!#:$%&'*+/=?^_~-]+[a-zA-Z]{2,3}$/.test(v) ||
						"URL must be valid",
				(v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
			],

			items: [],
			searchStocks: null,

			searchTerm: null,
			supplierStockSearchTerm: null,

			searchLoader: false,
			searchButtonDisabled: false,
			supplierStockSearchButtonDisabled: false,


			filteredSuppliers: false,
			filteredSupplierStocks: false,

			years: [],
			newSupplier: {
				id: null,
				name: null,
				supplier_no: null,
				county_id: null,
				year: null,
				physical_address: null,
			},

			newContact: {
				id: null,
				supplier_id: null,
				primary_email: null,
				secondary_email: null,
				primary_phone: null,
				secondary_phone: null,
				web_url: null,
			},

			newStock: {
				id: null,
				supplier_id: null,
				user_id: null,
			},

			assignedWallet: null,
			formData: null,

			coverImageDialog: false,
			imageDialog: false,
			imageLoading: false,
			fileRules: [(v) => !!v || "Kindly Select a File"],
			imageFile: null,
			imageValid: true,

			searchTerm: null,
			searchLoader: false,
			supplierStockSearchLoader: false,

			searchButtonDisabled: false,

			filteredSuppliers: [],
			supplierStocks: [],

			supplierStockPagination: {
				search: " ",
				current_page: 1,
				per_page: 0,
				total: 0,
				visible: 10,
			},


			logData: {
				message: null,
				model: null,
			},
		};
	},
	watch: {
		searchStocks(val) {
			val && val !== this.newStock.user_id && this.findStock(val);
		},
	},
	created() {
		if (this.$can("supplier_view")) {
			this.startWalletLoader();
			this.fetchWallets(this.walletPagination.current_page);
			this.populateYears();
		}
	},
	methods: {
		...mapActions([
			"fetchWallets",
			"startWalletLoader",
			"stopWalletLoader",
			"filterWallets",

			"filterLogData",
			"openLogDataModel",
		]),

		enableSearch() {
			this.searchButtonDisabled = false;
		},


		enableSupplierStockSearch() {
			this.supplierStockSearchButtonDisabled = false;
		},


		resetSearch() {
			this.searchTerm = null;
			this.filteredSuppliers = true;
			this.startWalletLoader();
			this.fetchWallets(1);
		},
		showWallet(wallet) {
			this.assignedWallet = wallet;
			this.newContact.supplier_id = wallet.id;

			this.changeView("show");
		},
		search() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startWalletLoader();
				apiCall({
					url: "/api/wallets?type=search&search=" + this.searchTerm,
					method: "GET",
				})
						.then((resp) => {
							this.filterWallets(resp);
							this.searchLoader = false;
							this.stopWalletLoader();
							this.filteredSuppliers = true;
						})
						.catch((error) => {
							console.log(error);
							this.searchLoader = false;
							this.stopWalletLoader();
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
						});
			}
		},

		changeView(view) {
			if (view == "default") {
				this.newSupplier.id = null;
				this.newSupplier.name = null;
				this.newSupplier.supplier_no = null;
				this.newSupplier.county_id = null;
				this.newSupplier.description = null;
				this.newSupplier.physical_address = null;
			}
			this.view = view;
		},

		changePage() {
			this.startWalletLoader();
			if (this.filteredSuppliers == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
							"/api/wallets?page=" +
							this.walletPagination.current_page +
							"&type=search&search=" +
							this.searchTerm,
					method: "GET",
				})
						.then((resp) => {
							this.filterWallets(resp);
							this.stopWalletLoader();
						})
						.catch((error) => {
							console.log(error);
							this.stopWalletLoader();
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
						});
			} else {
				this.fetchWallets(this.walletPagination.current_page);
			}
		},
		deleteWallet(item) {
			confirm("Are You Sure You Want to Delete Wallet?") &&
			(this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.supplierIndex = item.id;
				apiCall({url: "/api/wallets/" + item.id, method: "DELETE"})
						.then(() => {
							this.message = "Wallets Deleted Successfully";
							this.color = "success";
							this.snackbar = true;
							this.loading = false;
							this.delete = false;
							this.supplierIndex = null;
							this.fetchWallets(this.walletPagination.current_page);
						})
						.catch((error) => {
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							this.loading = false;
							this.delete = false;
							this.supplierIndex = null;
							console.log(error);
						});
			}
		},
	},
	computed: {
		...mapGetters([
			"wallets",
			"walletPagination",
			"walletLoader"
		]),
		length: function () {
			return Math.ceil(
					this.walletPagination.total / this.walletPagination.per_page
			);
		},
		supplierAdministratorLength: function () {
			return Math.ceil(
					this.supplierStockPagination.total /
					this.supplierStockPagination.per_page
			);
		},

		dateToday() {
			var today = new Date();

			var timestamp =
					today.getFullYear() +
					"-" +
					this.months[today.getMonth()] +
					"-" +
					("0" + today.getDate()).slice(-2);
			return timestamp;
		},
	},
};
</script>
  