<template>
	<div class="dashboard grey lighten-3">
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<v-progress-linear v-if="cartLoader" height="2" indeterminate color="primary"></v-progress-linear>
		<div>
			<v-layout row wrap>
				<v-flex xs12 md8>
					<div class="pa-5" style="
                        margin: 4px, 4px;
                        padding: 4px;
                        width: auto;
                        height: 86vh;
                        overflow-x: hidden;
                        overflow-y: scroll;
                        text-align: justify;"
					>
						<v-layout column>
							<v-flex xs12>
								<div v-if="carts.length == 0">
									<v-card elevation="0">
										<v-container fill-height fluid>
											<v-row align="center" justify="center">
												<v-col class="text-center"> NO ITEM FOUND</v-col>
											</v-row>
										</v-container>
									</v-card>
								</div>
								<div v-else>
									<v-layout column>
										<v-btn block large class="secondary mb-3 text-none"
												 @click="addBagToCart()" :loading="bagLoading"
												 :disabled="packagingDisabled"
										>Add Packaging Bag to Cart
											<v-icon right>mdi-basket</v-icon>
										</v-btn>
										<template v-for="(cart, index) in carts">
											<v-flex xs12 :key="index">

												<v-card elevation="0" class="mb-2" style="border-radius: 25px;" outlined>
													<v-card-text>
														<v-layout row wrap>
															<v-flex xs12 md6>
																<v-img v-if="cart.part.image == null" class="background"
																		 contain style="border-radius: 25px;" max-height="180"
																		 src="img/book-silhouette-vector-14.png">

																</v-img>
																<v-img max-height="180" contain v-else
																		 :src="path + '/storage/part_pictures/' + cart.part.image"
																		 style="border-radius: 25px;" class="background">

																</v-img>
															</v-flex>
															<!-- <v-flex xs1>
								 <v-divider vertical></v-divider>
								 </v-flex> -->
															<v-flex xs12 md6>
																<v-layout column>
																	<v-flex xs12 class="mt-5">
																		<div class="title primary--text ml-5">
																			{{ cart.part.name }}
																		</div>
																	</v-flex>
																	<v-divider class="mt-3 mr-5 ml-5"></v-divider>
																	<v-flex xs12>
																		<div class="pa-8">
																			<v-layout row wrap>
																				<v-flex xs12 md8>
																					<b>Maximum Allowed Purchases:</b>
																				</v-flex>
																				<v-flex xs12 md4>
																					<div v-if="cart.part.max_purchase == null"
																						  class="green--text" align="right">
																						No Restrictions
																					</div>
																					<div v-else align="right">
																						{{ cart.part.max_purchase }}
																					</div>
																				</v-flex>
																			</v-layout>
																			<v-layout row wrap class="mt-3">
																				<v-flex xs12 md8>
																					<b>Total Previous Purchases:</b>
																				</v-flex>
																				<v-flex xs12 md4>
																					<div align="right">
																						{{ cart.part.total_purchases }}
																					</div>
																				</v-flex>
																			</v-layout>
																		</div>
																	</v-flex>
																	<v-flex xs12>
																		<v-layout row wrap>
																			<v-flex xs6>
																				<v-select :loading="quantityLoading &&
                                                                                    cartIndex == cart.id
                                                                                    " :items="quantity"
																							 v-model="cart.quantity"
																							 class="ml-9 mr-16 mt-10"
																							 item-value="amount" item-text="amount"
																							 @input="
                                                                                        addQuantity(cart, cart.quantity)
                                                                                        " outlined dense>
																				</v-select>
																			</v-flex>
																			<v-flex xs6>
																				<div class="mt-12 mr-8" align="right">
																					Kshs {{
																						cart.part.price * cart.quantity |
																								formatNumber
																					}}
																				</div>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-flex xs12>
																		<div class="mx-6 red--text"
																			  v-if="(cart.part.max_purchase != null) && (cart.part.total_purchases >= cart.part.max_purchase)">
																			*You've Exceeded the Maximum Amount of Allowable
																			Purchases for this Item. Kindly Remove it from
																			your Cart.
																		</div>
																		<div class="mx-6 red--text"
																			  v-if="(cart.part.max_purchase != null) && (cart.quantity > cart.part.max_purchase)">
																			*You've Exceeded the Limit of the Maximum
																			Allowable Quantity for this Item. Kindly Reduce
																			the total units.
																		</div>
																	</v-flex>
																	<v-flex xs12>
																		<div class="mr-8 mb-5 ml-9 mt-5">
																			<v-layout row wrap>
																				<v-flex xs6>
																					<div align="left">

																						<router-link :to="'/product/' + cart.part.slug
                                                                                            " style="
                                                        text-decoration: none !important;
                                                    ">
																							<v-btn text large rounded block
																									 class="accent--text text-none mb-1">
																								View Details
																							</v-btn>
																						</router-link>
																					</div>
																				</v-flex>
																				<v-flex xs6>
																					<div align="right">
																						<v-btn text large
																								 @click="removeFromCart(cart)"
																								 rounded block :loading="loading && cartIndex == cart.id
                                                                                                "
																								 class="text-none mb-1 secondary--text">
																							Remove from Cart
																						</v-btn>
																					</div>
																				</v-flex>
																			</v-layout>
																		</div>
																	</v-flex>

																</v-layout>
															</v-flex>
														</v-layout>
													</v-card-text>

												</v-card>

											</v-flex>
										</template>
									</v-layout>
								</div>
							</v-flex>

						</v-layout>
					</div>
					<div class="white">
						<v-pagination v-if="length != 0" :length="length" total-visible="10" circle
										  v-model="cartPagination.current_page" @input="changePage()">
						</v-pagination>
					</div>

				</v-flex>
				<v-flex xs12 md4 class="white">
					<v-layout column class="mt-15 mx-15">
						<!-- <v-flex xs12>
							 <v-layout row wrap>
								  <v-flex xs6>
										<b>Subtotal (Kshs)</b>
								  </v-flex>
								  <v-flex xs6>
										<div align="right" class="green--text">
											 <b>{{ subtotalAmount | formatNumber }}</b>
										</div>
								  </v-flex>
							 </v-layout>
						</v-flex>
						<v-flex xs12 class="mt-8">
							 <v-layout row wrap>
								  <v-flex xs6>
										<b>VAT - 6% (Kshs)</b>
								  </v-flex>
								  <v-flex xs6>
										<div align="right" class="green--text">
											 <b>{{ subtotalAmount * (6 / 100) | formatNumber }}</b>
										</div>
								  </v-flex>
							 </v-layout>
						</v-flex>
						<v-flex xs12 class="mt-12">
							 <v-divider></v-divider>
						</v-flex> -->
						<v-flex xs12 class="mt-4">
							<v-layout row wrap>
								<v-flex xs6>
									<div class="subtitle-1"><b>Total (Kshs)</b></div>
								</v-flex>
								<v-flex xs6>
									<div align="right">
										<div class="subtitle-1 green--text">
											<b>{{ totalAmount | formatNumber }}</b>
										</div>
									</div>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 class="mt-12">
							<v-btn v-if="carts.length != 0" depressed block large color="primary" @click="checkout()"
									 :loading="cartLoading" class="text-none mb-5">
								Proceed to checkout
							</v-btn>
						</v-flex>
					</v-layout>
					<v-divider class="mt-10"></v-divider>
					<v-layout column class="mt-15 mx-15">
						<v-layout column>
							<v-flex xs12 class="mb-10 mx-4">
								<v-layout row wrap>
									<v-flex xs2>
										<v-icon large class="black--text">mdi-credit-card</v-icon>
									</v-flex>
									<v-flex xs10>
										<v-layout column>
											<v-flex xs12>
												<div><b>Wallet</b></div>
											</v-flex>
											<v-flex xs12>
												<div class="font-weight-light"></div>
											</v-flex>
										</v-layout>
									</v-flex>
								</v-layout>
							</v-flex>
							<v-flex xs12 class="mb-5 mx-4">
								<v-layout row wrap>
									<v-flex xs2>
										<v-icon large class="black--text">mdi-cellphone-text</v-icon>
									</v-flex>
									<v-flex xs10>
										<v-layout column>
											<v-flex xs12>
												<div><b>M-Pesa</b></div>
											</v-flex>
											<v-flex xs12>
												<!-- <div class="font-weight-light">M-Pesa</div> -->
											</v-flex>
										</v-layout>
									</v-flex>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-layout>
				</v-flex>
			</v-layout>
		</div>
	</div>
</template>
<script>
import apiCall from "@/utils/api";
import {mapActions, mapGetters} from "vuex";
import Vue from "vue";

var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0.00");
});

export default {
	components: {},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,
			loading: false,
			valid: true,
			phoneValid: true,
			selfieValid: true,
			idValid: true,
			message: "",
			color: "",
			delete: false,
			confirm: false,
			dialog: false,
			snackbar: false,
			cartLoading: false,
			bagLoading: false,
			quantityLoading: false,
			packagingDisabled: false,

			searchTerm: null,
			filteredCarts: false,
			cartIndex: null,

			quantity: [
				{amount: 1},
				{amount: 2},
				{amount: 3},
				{amount: 4},
				{amount: 5},
				{amount: 6},
				{amount: 7},
				{amount: 8},
				{amount: 9},
				{amount: 10},
			],

			cartData: {
				id: null,
				quantity: null,
				gift_id: null
			},

			subtotalAmount: 0,
			totalAmount: 0,
			userDetailsDialog: false,
			formData: {
				phone_number: null,
			},

			item: {
				id: 446
			}
		};
	},
	mounted() {
		this.checkPackagingBag()
	},
	created() {
		this.closeNav()
		window.scrollTo(0, 0);

		//this.startCartLoader();
		for (var i = 0; i < this.carts.length; i++) {
			this.subtotalAmount +=
					this.carts[i].quantity * parseInt(this.carts[i].part.price);
		}
		// var tax = this.subtotalAmount * (6 / 100)
		// this.totalAmount = this.subtotalAmount + tax

		this.totalAmount = this.subtotalAmount

		apiCall({
			url: "/api/cart?page=" + this.cartPagination.current_page,
			method: "GET",
		})
				.then((resp) => {
					this.filterCarts(resp);
					this.stopCartLoader();
					this.calculateTotal(resp)
				})
				.catch((error) => {
					console.log(error.response);
					this.stopCartLoader();
					this.message = "anErrorOccurredMsg";
					this.color = "error";
					this.snackbar = true;
				});
	},
	methods: {
		...mapActions([
			"closeNav",

			"startCartLoader",
			"stopCartLoader",
			"filterCarts",
			"fetchCarts",
			"fetchCarts",
		]),
		checkPackagingBag() {
			for (var x = 0; x < this.carts.length; x++) {
				if (this.carts[x].part_id == 446) {
					this.packagingDisabled = true
					break;
				}
			}
		},
		addBagToCart() {
			this.bagLoading = true;
			apiCall({
				url: "/api/cart",
				data: this.item,
				method: "POST",
			})
					.then((resp) => {
						if (resp.status == 200) {
							this.message = resp.message;
							this.color = "success";
							this.bagLoading = false;
							this.snackbar = true;

							this.packagingDisabled = true
							this.fetchCarts(1)
						} else if (resp.status == 409) {
							this.message = resp.message;
							this.color = "error";
							this.bagLoading = false;
							this.snackbar = true;
							this.packagingDisabled = true
						}
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.cartLoading = false;
						this.snackbar = true;
					});
		},
		checkout() {
			this.cartLoading = true
			apiCall({
				url: "/api/cart?type=checkCart",
				method: "GET",
			})
					.then((resp) => {
						this.cartLoading = false
						if (resp.status == 200) {
							this.$router.push("/checkout");
						} else {
							this.message = resp.message;
							this.color = "error";
							this.snackbar = true;
						}

					})
					.catch((error) => {
						this.cartLoading = false
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;

						console.log(error.response);
					});
		},
		calculateTotal(resp) {
			this.subtotalAmount = 0
			this.totalAmount = 0
			for (var i = 0; i < resp.data.length; i++) {
				this.subtotalAmount += resp.data[i].quantity * parseInt(resp.data[i].part.price);
			}
			// var tax = this.subtotalAmount * (6 / 100)
			// this.totalAmount = this.subtotalAmount + tax

			this.totalAmount = this.subtotalAmount
		},

		changePage() {
			this.startCartLoader();
			if (this.filteredCarts == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
							"/api/cart?page=" +
							this.cartPagination.current_page +
							"&type=search&search=" +
							this.searchTerm,
					method: "GET",
				})
						.then((resp) => {
							this.filterCarts(resp);
							this.stopCartLoader();
						})
						.catch((error) => {
							console.log(error.response);
							this.stopCartLoader();
							this.message = "anErrorOccurredMsg";
							this.color = "error";
							this.snackbar = true;
						});
			} else {
				this.fetchCarts(this.cartPagination.current_page);
			}
		},
		removeFromCart(item) {
			this.loading = true;
			this.cartIndex = item.id;
			apiCall({url: "/api/cart/" + item.id, method: "DELETE"})
					.then((resp) => {
						this.message = "Item removed from cart successfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.cartIndex = null;
						apiCall({
							url: "/api/cart?page=" + this.cartPagination.current_page,
							method: "GET",
						})
								.then((resp) => {
									this.subtotalAmount = 0;
									this.totalAmount = 0
									for (var i = 0; i < resp.data.length; i++) {
										this.subtotalAmount +=
												resp.data[i].quantity * parseInt(resp.data[i].part.price);
									}
									var tax = this.subtotalAmount * (6 / 100)
									this.totalAmount = this.subtotalAmount + tax

									this.filterCarts(resp);
									this.stopCartLoader();
									this.checkPackagingBag()
								})
								.catch((error) => {
									console.log(error.response);
									this.stopCartLoader();
									this.message = "anErrorOccurredMsg";
									this.color = "error";
									this.snackbar = true;
								});
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.cartIndex = null;
						console.log(error.response);
					});
		},
		addQuantity(item, quantity) {
			this.quantityLoading = true;
			this.cartIndex = item.id;
			this.cartData.id = item.id;
			this.cartData.quantity = quantity;

			apiCall({
				url: "/api/cart/" + item.id,
				data: this.cartData,
				method: "PUT",
			})
					.then((resp) => {
						this.message = "Quantity updated successfully";
						this.color = "success";
						this.snackbar = true;
						this.quantityLoading = false;
						this.cartIndex = null;
						apiCall({
							url: "/api/cart?page=" + this.cartPagination.current_page,
							method: "GET",
						})
								.then((resp) => {
									this.subtotalAmount = 0;
									this.totalAmount = 0
									for (var i = 0; i < resp.data.length; i++) {
										this.subtotalAmount +=
												resp.data[i].quantity * parseInt(resp.data[i].part.price);
									}
									var tax = this.subtotalAmount * (6 / 100)
									this.totalAmount = this.subtotalAmount + tax

									this.filterCarts(resp);
									this.stopCartLoader();
								})
								.catch((error) => {
									console.log(error.response);
									this.stopCartLoader();
									this.message = "anErrorOccurredMsg";
									this.color = "error";
									this.snackbar = true;
								});
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.quantityLoading = false;
						this.delete = false;
						this.cartIndex = null;
						console.log(error.response);
					});
		},
	},
	computed: {
		...mapGetters(["carts", "cartPagination", "cartLoader", "getProfile"]),
		length: function () {
			return Math.ceil(
					this.cartPagination.total / this.cartPagination.per_page
			);
		},
	},
};
</script>