import apiCall from '@/utils/api'

const state = {
	users: [],
	searchUsers: [],
    userPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	searchUserPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    userLoader: false
};

const getters = {
    userLoader: (state) => state.userLoader,
	searchUsers: (state) => state.searchUsers,
	users: (state) => state.users,
    userPagination: (state) => state.userPagination,
	searchUserPagination: (state) => state.searchUserPagination,
}

const actions = {
    async fetchUsers({commit}, page) {
		const response = await apiCall({url: `/api/user?page=${page}`, method: 'GET' });
		commit('setUsers', response)
        commit('stopUserLoader', response)
	},
    async filterUsers({commit, state},resp){
		commit('setUsers', resp)
	},
	async filterSearchUsers({commit, state},resp){
		commit('setSearchUsers', resp)
	},
	async startUserLoader({commit, state},resp){
		commit('startUserLoader', resp)
	},
	async stopUserLoader({commit, state},resp){
		commit('stopUserLoader', resp)
	},
};

const mutations = {
	setUsers: (state, users) => {
		state.users = users.data
		state.userPagination.current_page = users.current_page
		state.userPagination.total = users.total
		state.userPagination.per_page = users.per_page
	},
	setSearchUsers: (state, searchUser) => {
		state.searchUsers = searchUser.data
		state.searchUserPagination.current_page = searchUser.current_page
		state.searchUserPagination.total = searchUser.total
		state.searchUserPagination.per_page = searchUser.per_page
	},
    startUserLoader: (state) => state.userLoader = true,
	stopUserLoader: (state) => state.userLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
