import apiCall from '@/utils/api'

const state = {
	ads: [],
	allAds: [],
    adPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    adLoader: false
};

const getters = {
	ads: (state) => state.ads,
	allAds: (state) => state.allAds,
    adLoader: (state) => state.adLoader,
    adPagination: (state) => state.adPagination
}

const actions = {
	async fetchAllAds({commit}, page) {
		const response = await apiCall({url: `/api/ad?type=all`, method: 'GET' });
		commit('setAllAds', response)
        commit('stopLoader', response)
	},
    async fetchAds({commit}, page) {
		const response = await apiCall({url: `/api/ad?page=${page}`, method: 'GET' });
		commit('setAds', response)
        commit('stopLoader', response)
	},
    async filterAds({commit, state},resp){
		commit('setAds', resp)
	},
	async startAdLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopAdLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setAds: (state, ads) => {
		state.ads = ads.data
		state.adPagination.current_page = ads.current_page
		state.adPagination.total = ads.total
		state.adPagination.per_page = ads.per_page
	},
	setAllAds: (state, ads) => {
		state.allAds = ads
	},
    startLoader: (state) => state.adLoader = true,
	stopLoader: (state) => state.adLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
