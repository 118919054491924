import apiCall from '@/utils/api'

const state = {
	learningAreas: [],
	allLearningAreas: [],
    learningAreaPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    learningAreaLoader: false
};

const getters = {
	learningAreas: (state) => state.learningAreas,
	allLearningAreas: (state) => state.allLearningAreas,
    learningAreaLoader: (state) => state.learningAreaLoader,
    learningAreaPagination: (state) => state.learningAreaPagination
}

const actions = {
	async fetchAllLearningAreas({commit}, page) {
		const response = await apiCall({url: `/api/learning-area?type=all`, method: 'GET' });
		commit('setAllLearningAreas', response)
        commit('stopLoader', response)
	},
    async fetchLearningAreas({commit}, page) {
		const response = await apiCall({url: `/api/learning-area?page=${page}`, method: 'GET' });
		commit('setLearningAreas', response)
        commit('stopLoader', response)
	},
    async filterLearningAreas({commit, state},resp){
		commit('setLearningAreas', resp)
	},
	async startLearningAreaLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopLearningAreaLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setLearningAreas: (state, learningAreas) => {
		state.learningAreas = learningAreas.data
		state.learningAreaPagination.current_page = learningAreas.current_page
		state.learningAreaPagination.total = learningAreas.total
		state.learningAreaPagination.per_page = learningAreas.per_page
	},
	setAllLearningAreas: (state, learningAreas) => {
		state.allLearningAreas = learningAreas
	},
    startLoader: (state) => state.learningAreaLoader = true,
	stopLoader: (state) => state.learningAreaLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
